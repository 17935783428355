// LICENSE_CODE TLM
import Antd_icon, {UploadOutlined, DownloadOutlined, SearchOutlined,
  ClockCircleOutlined, DownOutlined, CaretDownOutlined, LoadingOutlined,
  DeleteOutlined, CloseOutlined, HomeOutlined,
  FolderFilled} from '@ant-design/icons';
import {Button, message, Upload, Row, Space, Rate, Modal, ConfigProvider, Tree,
  Typography, Col, Image, Divider, Tooltip, Dropdown as Antd_dropdown, Table,
  Checkbox, Input, Form, Select, Spin, InputNumber, Tabs,
  theme as antd_theme} from 'antd';
import {purple, red, orange, blue, green, gray} from '@ant-design/colors';
import React, {useState, useRef, useEffect, useCallback, useMemo,
  createContext, useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDropzone} from 'react-dropzone';
import _ from 'lodash';
import assert from 'assert';
import {parseKeybinding as key_bind_parse, tinykeys} from 'tinykeys';
import deep_diff from 'deep-diff';
import {closestCenter as closest_center, DndContext, DragOverlay, PointerSensor,
  useSensor, useSensors} from '@dnd-kit/core';
import {restrictToHorizontalAxis as restrict_to_horizontal_axis}
  from '@dnd-kit/modifiers';
import {arrayMove,
  horizontalListSortingStrategy as horizontal_list_sorting_strategy,
  SortableContext, useSortable} from '@dnd-kit/sortable';
import xurl from '../../../util/xurl.js';
import xutil from '../../../util/util.js';
import ereq from '../../../util/ereq.js';
import eserf from '../../../util/eserf.js';
import xdate from '../../../util/date.js';
import svg from './svg.js';
import auth from './auth.js';
import Contact from './contact.js';
import config_ext from './config_ext.js';
import {Clickable, Loading, use_je, download, use_qs, use_qs_clear,
  Desktop_required_modal, use_es_root, use_effect_eserf} from './comp.js';
import back_app from './back_app.js';
import lbin_demo from './lbin_demo.js';
import metric from './metric.js';
import player from './player.js';
import {file_id2filename, Add_dir_modal,
  Rename_file_modal} from './workspace.js';
import tc from '../../../util/tc.js';
import str from '../../../util/str.js';
import je from '../../../util/je.js';
import Mark_in_icon from './assets/mark_in_icon.svg?react';
import Mark_in_seg_icon from './assets/mark_in_seg_icon.svg?react';
import Mark_out_seg_icon from './assets/mark_out_seg_icon.svg?react';
import Mark_out_icon from './assets/mark_out_icon.svg?react';
import Mark_clip_icon from './assets/mark_clip_icon.svg?react';
import Trim_mode_icon from './assets/trim_mode_icon.svg?react';
import Remove_effect_icon from './assets/remove_effect_icon.svg?react';
import fast_menu_icon from './assets/fast_menu_icon.svg';
import Effect_mode_icon from './assets/effect_mode_icon.svg?react';
import Clear_both_marks_icon from './assets/clear_both_marks_icon.svg?react';
import purple_icon from './assets/purple_icon.svg';
import motion_icon from './assets/motion_icon.svg';
import gray_icon from './assets/gray_icon.svg';
import tick_icon from './assets/tick_icon.svg';
import monitor_icon from './assets/monitor_icon.svg';
import Synclock_icon from './assets/synclock_icon.svg?react';
import Power_icon from './assets/power_icon.svg?react';
import Waveform_icon from './assets/waveform_icon.svg?react';
import left_arrow_icon from './assets/left_arrow_icon.svg';
import right_arrow_icon from './assets/right_arrow_icon.svg';
import up_arrow_icon from './assets/up_arrow_icon.svg';
import down_arrow_icon from './assets/down_arrow_icon.svg';
import Meter_menu_icon from './assets/meter_menu_icon.svg?react';
import Quick_transition_icon from './assets/quick_transition_icon.svg?react';
import Play_icon from './assets/play_icon.svg?react';
import Step_forward_1_frame_icon
  from './assets/step_forward_1_frame_icon.svg?react';
import Step_backward_1_frame_icon
  from './assets/step_backward_1_frame_icon.svg?react';
import Match_frame_icon from './assets/match_frame_icon.svg?react';
import Marker_icon from './assets/marker_icon.svg?react';
import Marker_seg_icon from './assets/marker_seg_icon.svg?react';
import Go_to_prev_event_icon from './assets/go_to_prev_event_icon.svg?react';
import Go_to_next_event_icon from './assets/go_to_next_event_icon.svg?react';
import Monitor_volume_icon from './assets/monitor_volume_icon.svg?react';
import selection_cursor from './assets/selection_cursor.svg';
import insertion_cursor from './assets/insertion_cursor.svg';
import Tool_palette_icon from './assets/tool_palette_icon.svg?react';
import Add_edit_icon from './assets/add_edit_icon.svg?react';
import Head_fade_icon from './assets/head_fade_icon.svg?react';
import Tall_fade_icon from './assets/tall_fade_icon.svg?react';
import To_the_left_icon from './assets/to_the_left_icon.svg?react';
import To_the_right_icon from './assets/to_the_right_icon.svg?react';
import Select_in_out_icon from './assets/select_in_out_icon.svg?react';
import Collapse_icon from './assets/collapse_icon.svg?react';
import Render_effect_icon from './assets/render_effect_icon.svg?react';
import Step_in_icon from './assets/step_in_icon.svg?react';
import Step_out_icon from './assets/step_out_icon.svg?react';
import Titler_pro_icon from './assets/titler_pro_icon.svg?react';
import Hw_sw_icon from './assets/hw_sw_icon.svg?react';
import Link_selection_toggle_icon
  from './assets/link_selection_toggle_icon.svg?react';
import Extract_icon from './assets/extract_icon.svg?react';
import Lift_icon from './assets/lift_icon.svg?react';
import zoom_out_icon from './assets/zoom_out_icon.svg';
import zoom_in_icon from './assets/zoom_in_icon.svg';
import Find_bin_icon from './assets/find_bin_icon.svg?react';
import Gang_icon from './assets/gang_icon.svg?react';
import Step_backward_10_frames_icon
  from './assets/step_backward_10_frames_icon.svg?react';
import Step_forward_10_frames_icon
  from './assets/step_forward_10_frames_icon.svg?react';
import Go_to_in_icon from './assets/go_to_in_icon.svg?react';
import Go_to_out_icon from './assets/go_to_out_icon.svg?react';
import Play_length_toggle_icon
  from './assets/play_length_toggle_icon.svg?react';
import Video_quality_menu_icon
  from './assets/video_quality_menu_icon.svg?react';
import Quad_split_icon from './assets/quad_split_icon.svg?react';
import Reverse_match_frame_icon
  from './assets/reverse_match_frame_icon.svg?react';
import Nine_split_icon from './assets/nine_split_icon.svg?react';
import Swap_cam_bank_icon from './assets/swap_cam_bank_icon.svg?react';
import Brush_icon from './assets/brush_icon.svg?react';
import Arrow_icon from './assets/arrow_icon.svg?react';
import Rect_icon from './assets/rect_icon.svg?react';
import Select_icon from './assets/select_icon.svg?react';
import Select_insert_icon from './assets/select_insert_icon.svg?react';
import Toggle_src_rec_icon from './assets/toggle_src_rec_icon.svg?react';
import Focus_icon from './assets/focus_icon.svg?react';
import Toggle_client_monitor_icon
  from './assets/toggle_client_monitor_icon.svg?react';
import Video_quality_menu_colorful_icon
  from './assets/video_quality_menu_colorful_icon.svg?react';
import Last_frame_icon from './assets/last_frame_icon.svg?react';
import First_frame_icon from './assets/first_frame_icon.svg?react';
import Clip_first_frame_left_icon
  from './assets/clip_first_frame_left_icon.svg?react';
import Clip_first_frame_right_icon
  from './assets/clip_first_frame_right_icon.svg?react';
import Sequence_icon from './assets/sequence_icon.svg?react';
import Audio_seq_icon from './assets/audio_seq_icon.svg?react';
import Masterclip_icon from './assets/masterclip_icon.svg?react';
import Subclip_icon from './assets/subclip_icon.svg?react';
import Titleclip_icon from './assets/titleclip_icon.svg?react';
import Groupclip_selector_icon
  from './assets/groupclip_selector_icon.svg?react';
import Alphaclip_icon from './assets/alphaclip_icon.svg?react';
import Frame_view_mode_icon from './assets/frame_view_mode_icon.svg?react';
import Text_view_mode_icon from './assets/text_view_mode_icon.svg?react';
import Bin_icon from './assets/bin_icon.svg?react';

let {Title} = Typography;
let prefix = config_ext.back.app.url;

export let theme = {red: '#D65645', orange: '#D57939', purple: '#5D2EAE',
  light_blue: '#60D1FA', blue: '#1d9bc9', dark_blue: '#5987B6',
  green: '#2EDB92', gray1: '#373737', gray2: '#505050', gray3: '#555555',
  gray4: '#5F5F5F', gray6: '#646464', gray7: '#666666', gray8: '#686868',
  gray9: '#717171', gray10: '#7d7d7d', gray11: '#878787'};

let obj_path_find = (obj, value, path=[])=>{
  if (_.isEqual(obj, value))
    return path;
  if (!_.isObject(obj) && !_.isArray(obj))
    return;
  for (let key in obj)
  {
    if (Object.prototype.hasOwnProperty.call(obj, key))
    {
      let result = obj_path_find(obj[key], value, path.concat(key));
      if (result)
        return result;
    }
  }
  return null;
};
let nested_tracks_get = (monitor, track_id, nested_track_paths)=>{
  let path = nested_track_paths[track_id];
  if (!path)
    return [];
  let track;
  if (track_id.includes('.'))
  {
    let parent_track_id = track_id.split('.').slice(0, -1).join('.');
    let nested_tracks = nested_tracks_get(monitor, parent_track_id,
      nested_track_paths);
    track = nested_tracks.find(_track=>_track.id == track_id);
  }
  else
    track = monitor.tracks.find(_track=>_track.id == track_id);
  if (!track)
    assert(0, 'track is not found: ' + track_id);
  let seg = _.get(track, path);
  if (!seg)
    assert(0, 'seg is not found on path: ' + path);
  return seg.arr.map(child_seg=>({type: 'timeline_track', is_nested: true,
    id: child_seg.track_lbl, lbl: child_seg.track_lbl,
    len: player.seg_len(child_seg), power: {v: true, color: '#4b88ba'},
    start: child_seg.abs_start, is_synclock: false,
    ctrl: {has_power: true, has_synclock: true}, is_lock: false,
    height: 'medium_no_fill', arr: [child_seg]})).reverse();
};
let visible_tracks_get = (monitor, nested_track_paths, tracks)=>{
  if (!tracks)
    tracks = monitor.tracks;
  if (!Array.isArray(tracks))
    tracks = [tracks];
  if (!tracks.length)
    return [];
  let visible_tracks = [];
  for (let track of tracks)
  {
    let nested_tracks = nested_tracks_get(monitor, track.id,
      nested_track_paths);
    nested_tracks = nested_tracks.map(nested_track=>{
      return visible_tracks_get(monitor, nested_track_paths, nested_track);
    }).flat();
    visible_tracks = [...visible_tracks, ...nested_tracks, track];
  }
  return visible_tracks;
};
let nested_track_close = (nested_track_paths, track_id)=>{
  let _nested_track_paths = {...nested_track_paths};
  delete _nested_track_paths[track_id];
  for (let _track_id of Object.keys(_nested_track_paths))
  {
    if (_track_id.startsWith(track_id + '.'))
      delete _nested_track_paths[_track_id];
  }
  return _nested_track_paths;
};
let time_diff_get = (a, b)=>{
  let diff = a - b;
  let formatter = new Intl.RelativeTimeFormat('en', {numeric: 'auto'});
  return formatter.format(Math.round(diff / xdate.MS_DAY), 'day');
};
export let editor = {};
// XXX vladimir: move to comp.js
let key_binding_map_get = action2func=>{
  return Object.entries(action2func)
    .map(([action, func])=>{
      let key_bind = action2key_bind[action];
      if (Array.isArray(key_bind))
      {
        return key_bind
          .map(key=>({
            [key]: e=>{
              if (['INPUT', 'TEXTAREA'].includes(e.target.tagName))
                return;
              e.preventDefault();
              if (typeof func == 'function')
                func(e);
            }
          }))
          .reduce((accum, curr)=>({...accum, ...curr}));
      }
      return {
        [action2key_bind[action]]: e=>{
          if (['INPUT', 'TEXTAREA'].includes(e.target.tagName))
            return;
          e.preventDefault();
          if (typeof func == 'function')
            func(e);
        },
      };
    })
    .reduce((accum, cur)=>({...accum, ...cur}));
};
let coords_get = elem=>{
  let box = elem.getBoundingClientRect();
  return {top: box.top + window.scrollY, right: box.right + window.scrollX,
    bottom: box.bottom + window.scrollY, left: box.left + window.scrollX};
};
editor.cuts_get = (segs, playback_rate=1)=>{
  if (!segs)
    return [];
  if (!Array.isArray(segs))
    segs = [segs];
  if (!segs.length)
    return [];
  let cuts = [
    ...segs
      .filter(seg=>{
        if (['cmt', 'marker'].includes(seg.type))
          return false;
        if (seg.icon?.is_icon_out && seg.icon?.icon_out == 'transition')
          return false;
        return true;
      })
      .map(seg=>{
        let len = Math.round(seg.len / playback_rate);
        return [seg.abs_start, seg.abs_start + len];
      })
      .flat(),
    ...segs
      .filter(seg=>!seg.is_no_border)
      .map(seg=>{
        if (seg.type == 'selector')
          return editor.cuts_get(seg.arr[seg.select_idx], playback_rate);
        if (seg.type == 'operation_motion' && seg.playrate !== undefined)
          return editor.cuts_get(seg.arr, playback_rate * seg.playrate);
        return editor.cuts_get(seg.arr, playback_rate);
      }).flat(),
  ];
  cuts = [...new Set(cuts)];
  cuts.sort((a, b)=>a - b);
  return cuts;
};
editor.markers_pos_get = segs=>{
  if (!segs)
    return [];
  if (!Array.isArray(segs))
    segs = [segs];
  if (!segs.length)
    return [];
  let markers = [
    ...segs
      .filter(seg=>seg.type == 'marker')
      .map(seg=>seg.abs_start),
    ...segs.map(seg=>{
      if (seg.is_no_border)
        return [];
      if (seg.type == 'selector')
        return editor.markers_pos_get([seg.arr[seg.select_idx]]);
      return editor.markers_pos_get(seg.arr);
    }).flat(),
  ];
  markers = [...new Set(markers)];
  markers.sort((a, b)=>a - b);
  return markers;
};
editor.markers_get = segs=>{
  if (!segs)
    return [];
  if (!Array.isArray(segs))
    segs = [segs];
  if (!segs.length)
    return [];
  let markers = [
    ...segs.filter(seg=>seg.type == 'marker'),
    ...segs.map(seg=>editor.markers_get(seg.arr)).flat(),
  ];
  return markers;
};
editor.nearest_cut_get = (cuts, frame)=>{
  return cuts.reduce((nearest_cut, cut)=>{
    return Math.abs(cut - frame) < Math.abs(nearest_cut - frame)
      ? cut : nearest_cut;
  });
};
let monitor_sync = (prev_monitor, next_monitor)=>{
  let _next_monitor = _.cloneDeep(next_monitor);
  let cmts_track = prev_monitor.tracks
    .find(track=>track.type == 'cmts_track');
  if (cmts_track)
    _next_monitor.tracks.push(cmts_track);
  return _next_monitor;
};
editor.aaf_download = (token, aaf_in)=>eserf(function* editor_aaf_download(){
  if (!token || !aaf_in)
    return;
  let blob = yield back_app.editor_aaf_get(token, aaf_in);
  if (blob.err)
    return {err: blob.err};
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = aaf_in;
  document.body.appendChild(link);
  link.click();
  return new File([blob], aaf_in, {type: 'application/CDFV2', lastModified:
    new Date()});
});
let new_track_num_get = tracks=>{
  if (!tracks.length)
    return 1;
  tracks = tracks.sort((a, b)=>b.lbl.slice(1) - a.lbl.slice(1));
  let last_track = tracks[0];
  return parseInt(last_track.lbl.slice(1), 10) + 1;
};
let track_height2px = height=>{
  switch (height)
  {
  case 'tall':
    return 38;
  case 'tall_no_fill':
    return 32;
  case 'medium':
    return 32;
  case 'medium_no_fill':
    return 26;
  case 'small':
    return 26;
  case 'small_no_fill':
    return 20;
  default:
    return 32;
  }
};
editor.tracks_total_height_get = tracks=>{
  if (!tracks || !tracks.length)
    return 0;
  return tracks
    .map(track=>track_height2px(track.height))
    .reduce((accum, cur)=>accum + cur);
};
let play_btn_content_get = (playback_rate, fps)=>{
  if (playback_rate < 0)
    return -playback_rate * fps;
  if (playback_rate > 1)
    return playback_rate * fps;
  return <Play_icon />;
};
let play_btn_colors_get = playback_rate=>{
  if (!playback_rate)
    return {bg: gray[4], hover_bg: gray[3], active_bg: gray[5]};
  if (playback_rate != 1)
    return {bg: green[2], hover_bg: green[3], active_bg: green[1]};
  return {bg: '#2C5170', hover_bg: '#336084', active_bg: '#25455f'};
};
let cmt_track_id = 'C1';
let action2key_bind = {
  zoom_in: '$mod+]',
  zoom_out: '$mod+[',
  change_selector_prev: 'ArrowUp',
  change_selector_next: 'ArrowDown',
  move_one_frame_left: 'ArrowLeft',
  move_one_frame_right: 'ArrowRight',
  move_ten_frames_left: 'Shift+ArrowLeft',
  move_ten_frames_right: 'Shift+ArrowRight',
  play_stop: 'Space',
  play_backwards: 'KeyJ',
  stop: 'KeyK',
  play: 'KeyL',
  go_to_prev_cut: 'KeyA',
  go_to_next_cut: 'KeyS',
  go_to_prev_marker: 'Shift+KeyA',
  go_to_next_marker: 'Shift+KeyS',
  go_to_mark_in: 'KeyQ',
  go_to_mark_out: 'KeyW',
  tc_numbers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Numpad0',
    'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7',
    'Numpad8', 'Numpad9'],
  tc_add: 'NumpadAdd',
  tc_sub: 'NumpadSubtract',
  tc_cancel_focused_monitor_toggle: 'Escape',
  tc_apply: 'Enter',
  tc_backspace_marker_remove: ['Delete', 'Backspace'],
  toggle_selection_tool: '\'',
  toggle_insertion_tool: ';',
  info_modal_open: '$mod+KeyI',
  mark_in: 'KeyE',
  mark_out: 'KeyR',
  mark_clip: 'KeyT',
  clear_both_marks: 'KeyG',
  cut: 'KeyH',
  lift: 'KeyZ',
  extract: 'KeyX',
  marker_add: '=',
  marker_add_green: 'F7',
  marker_add_red: 'F8',
  play_in_to_out: 'Alt+Space',
  trim_mode: '[',
  trim_move_one_frame_left: ',',
  trim_move_one_frame_right: '.',
  trim_move_ten_frames_left: 'm',
  trim_move_ten_frames_right: '/',
  video_track_add: '$mod+y',
  mono_audio_track_add: '$mod+u',
  stereo_audio_track_add: ['$mod+Alt+u', '$mod+Control+u'],
  track_add: ['$mod+Shift+y', '$mod+Shift+u'],
  undo: '$mod+z',
  redo: '$mod+r',
  mark_all_tracks: '$mod+a',
  unmark_all_tracks: '$mod+Shift+a',
  quad_split: 'F6',
  nine_split: 'F5',
  insert: 'KeyV',
};
let key_bind2lbl = key_bind=>{
  let parsed_key_bind = key_bind_parse(key_bind)[0];
  let lbl = parsed_key_bind.flat().map(key=>{
    return key.replace('Key', '').replace('Meta', '⌘');
  }).join(' + ');
  return lbl;
};
export let Editor_panel = React.memo(({children, style={}, ...rest})=>{
  return (
    <div {...rest} style={{background: gray[5], width: '100%', height: '100%',
      border: '1px solid black', display: 'flex', ...style}}>
      {children}
    </div>
  );
});
let Icon = React.memo(({src, style={}, ...rest})=>{
  return (
    <Image src={src} preview={false} draggable={false}
      style={{userSelect: 'none', ...style}} {...rest} />
  );
});
export let Marker_seg = React.memo(({color, left, zidx, opacity})=>{
  return (
    <div style={{height: '100%', top: 0, display: 'flex', zIndex: zidx,
      flexDirection: 'column', color, position: 'absolute', left: `${left}px`,
      justifyContent: 'center', alignItems: 'center',
      transform: 'translateX(-50%)', opacity, pointerEvents: 'none'}}>
      <Marker_seg_icon />
    </div>
  );
});
export let Mark_in_seg = React.memo(({left, zidx, opacity})=>{
  return (
    <div style={{height: '100%', top: 0, display: 'flex', zIndex: zidx,
      flexDirection: 'column', position: 'absolute', left: `${left}px`,
      justifyContent: 'center', alignItems: 'center', pointerEvents: 'none',
      transform: 'translateX(-100%)', opacity}}>
      <Mark_in_seg_icon />
    </div>
  );
});
export let Mark_out_seg = React.memo(({left, zidx, opacity})=>{
  return (
    <div style={{height: '100%', top: 0, display: 'flex', zIndex: zidx,
      flexDirection: 'column', position: 'absolute', left: `${left}px`,
      justifyContent: 'center', alignItems: 'center', opacity,
      pointerEvents: 'none'}}>
      <Mark_out_seg_icon />
    </div>
  );
});
let Avatar = React.memo(({src, children, style, ...rest})=>{
  return (
    <div style={{width: '32px', height: '32px', backgroundImage: `url(${src})`,
      borderRadius: '50%', backgroundSize: 'cover', cursor: 'pointer',
      ...style}} {...rest}>
      {children}
    </div>
  );
});
let Editor_btn_group = React.memo(({style, ...rest})=>{
  return (
    <div style={{background: gray[4], minWidth: '30px', minHeight: '30px',
      borderRadius: '1px', display: 'flex', ...style}} {...rest} />
  );
});
editor.Loading_overlay = React.memo(()=>{
  return (
    <div style={{position: 'fixed', width: '100%', height: '100%',
      zIndex: 9999, left: 0, top: 0, display: 'flex', flexDirection: 'column',
      justifyContent: 'center', alignItems: 'center', background: '#ffffff50'}}>
      <Spin indicator={<LoadingOutlined spin style={{fontSize: '50vh'}} />} />
    </div>
  );
});
export let Tbin_col_choose_modal = React.memo(({is_open, on_close, on_submit,
  options, col_keys})=>{
  let {t} = useTranslation();
  let [value, value_set] = useState(col_keys.filter(key=>{
    return options.find(opt=>opt.value == key);
  }));
  let change_handle = useCallback(_value=>{
    value_set(_value);
  }, []);
  let all_none_click_handle = useCallback(()=>{
    if (value.length == options.length)
      return value_set([]);
    value_set(options.map(opt=>opt.value));
  }, [options, value.length]);
  let footer_get = useCallback((origin_node, {OkBtn, CancelBtn})=><div
    style={{display: 'flex', justifyContent: 'space-between'}}>
    <Button onClick={all_none_click_handle}>
      {t('All / None')}
    </Button>
    <Space>
      <CancelBtn />
      <OkBtn />
    </Space>
  </div>, [all_none_click_handle, t]);
  let submit_handle = useCallback(()=>{
    on_submit(value);
  }, [on_submit, value]);
  useEffect(()=>{
    value_set(col_keys.filter(key=>{
      return options.find(opt=>opt.value == key);
    }));
  }, [col_keys, options]);
  return (
    <Modal title={t('Bin Column Selection')} open={is_open} onCancel={on_close}
      footer={footer_get} onOk={submit_handle}>
      <Space direction="vertical" size="middle" style={{display: 'flex'}}>
        <Checkbox.Group options={options} value={value} onChange={change_handle}
          style={{flexDirection: 'column', gap: '4px'}} />
        <span>{value.length} {t('column are selected.')}</span>
      </Space>
    </Modal>
  );
});
export let Tbin_color_icon = React.memo(({color})=>{
  let border_color = useMemo(()=>{
    if (!color)
      return gray[6];
    return gray[9];
  }, [color]);
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{width: '13px', height: '10px', background: color,
        border: `1px solid ${border_color}`, marginTop: '-1px'}} />
    </div>
  );
});
export let Tbin_item_icon = React.memo(({type, is_audio_only})=>{
  let icon = useMemo(()=>{
    if (!type)
      return null;
    if (is_audio_only)
      return <Audio_seq_icon />;
    if (type == 'sequence')
      return <Sequence_icon />;
    if (type == 'masterclip')
      return <Masterclip_icon />;
    if (type == 'subclip')
      return <Subclip_icon />;
    if (type == 'titleclip')
      return <Titleclip_icon />;
    if (type == 'groupclip_selector')
      return <Groupclip_selector_icon />;
    if (type == 'alphaclip')
      return <Alphaclip_icon />;
    return null;
  }, [is_audio_only, type]);
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      {icon}
    </div>
  );
});
export let Drag_idx_ctx = createContext({active: null, over: null});
let drag_active_style_get = (drag_state, id)=>{
  let {active, over, direction} = drag_state;
  if (active && active == id)
    return {backgroundColor: 'gray', opacity: 0.5};
  if (over && id === over && active !== over)
  {
    return direction == 'right' ? {borderRight: '1px dashed gray'}
      : {borderLeft: '1px dashed gray'};
  }
  return {};
};
export let Tbin_table_body_cell = props=>{
  let drag_state = useContext(Drag_idx_ctx);
  return <td {...props} style={{...props.style,
    ...drag_active_style_get(drag_state, props.id)}} />;
};
export let Tbin_table_header_cell = props=>{
  let drag_state = useContext(Drag_idx_ctx);
  let {attributes, listeners, setNodeRef: node_ref_set,
    isDragging: is_dragging} = useSortable({id: props.id});
  let style = {
    ...props.style,
    cursor: 'move',
    ...is_dragging ? {position: 'relative', zIndex: 9999,
      userSelect: 'none'} : {},
    ...drag_active_style_get(drag_state, props.id),
  };
  return <th {...props} ref={node_ref_set} style={style} {...attributes}
    {...listeners} />;
};
export let multi_sort = (collection, criteria)=>{
  return collection.sort((a, b)=>{
    for (let {sorter, dir} of criteria)
    {
      let comparison = sorter(a, b);
      if (comparison != 0)
        return dir == 'asc' ? -comparison : comparison;
    }
    return 0;
  });
};
export let Premium_modal = React.memo(({is_open, on_close})=>{
  let {t} = useTranslation();
  return (
    <Modal
      title={t('This is a premium feature, contact us at support@toolium.org')}
      open={is_open} onOk={on_close} onCancel={on_close} />
  );
});
let default_col_keys = ['color', 'icon', 'name', 'start_tc', 'end_tc', 'fps'];
let Tbin_tab = React.memo(({lbin, cmd_rec_monitor_load_clip, user_full,
  cmd_src_monitor_load_clip, user, token, cmd_clip_duplicate})=>{
  let {t} = useTranslation();
  let [selected_objs, selected_objs_set] = useState([]);
  let [selected_cols, selected_cols_set] = useState([]);
  let [is_row_ctx_open, is_row_ctx_open_set] = useState(false);
  let [ctx_record, ctx_record_set] = useState(null);
  let [is_header_ctx_open, is_header_ctx_open_set] = useState(false);
  let [is_col_choose_modal_open,
    is_col_choose_modal_open_set] = useState(false);
  let [ctx_col, ctx_col_set] = useState(null);
  let [sorters, sorters_set] = useState([]);
  let [view_mode, view_mode_set] = useState('text');
  let [query, query_set] = useState('');
  let [col_keys, col_keys_set] = useState(user_full.tbin_cols
    || default_col_keys);
  let [drag_idx, drag_idx_set] = useState({active: null, over: null});
  let [is_premium_modal_open, is_premium_modal_open_set] = useState(false);
  let sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {distance: 1}}));
  let drop_files_handle = useCallback(()=>{
    is_premium_modal_open_set(true);
  }, []);
  let premium_modal_close_handle = useCallback(()=>{
    is_premium_modal_open_set(false);
  }, []);
  let {getRootProps: root_props_get, getInputProps: input_props_get,
    isDragActive: is_drag_active} = useDropzone({noClick: true,
    noKeyboard: true, onDrop: drop_files_handle});
  let header_cell_handle = useCallback(col=>{
    return {
      id: col.key,
      onMouseDown: e=>{
        if ((e.ctrlKey || e.metaKey) && selected_cols.includes(col.key))
        {
          let _selected_cols = selected_cols.filter(id=>id != col.key);
          selected_cols_set(_selected_cols);
          selected_objs_set([]);
          return;
        }
        if ((e.ctrlKey || e.metaKey) && !selected_cols.includes(col.key))
        {
          selected_cols_set([...selected_cols, col.key]);
          selected_objs_set([]);
          return;
        }
        selected_cols_set([col.key]);
        selected_objs_set([]);
      },
      onContextMenu: ()=>{
        is_header_ctx_open_set(true);
        ctx_col_set(col.key);
      },
    };
  }, [selected_cols]);
  let cell_handle = useCallback(col=>{
    return {id: col.key};
  }, []);
  let str_sorter_get = useCallback(key=>{
    return (a, b)=>str.cmp(b.tbin[key]||'', a.tbin[key]||'');
  }, []);
  let tcs_sorter_get = useCallback(key=>{
    return (a, b)=>{
      if (!a.key)
        return -1;
      if (!b.key)
        return 1;
      let a_frame = tc.tc_o2frame(tc.str2tc_o(a.tbin[key]), null, a.fps);
      let b_frame = tc.tc_o2frame(tc.str2tc_o(b.tbin[key]), null, b.fps);
      return b_frame - a_frame;
    };
  }, []);
  let cols = useMemo(()=>[
    {key: 'audio_sr', dataIndex: 'audio_sr', title: t('Audio SR'),
      _sorter: (a, b)=>b.audio_sr - a.audio_sr},
    {key: 'cmts', dataIndex: 'cmts', title: t('Comments'),
      _sorter: str_sorter_get('cmts')},
    {key: 'color', dataIndex: 'color', title: t('Color'),
      _sorter: str_sorter_get('color')},
    {key: 'creation_date', dataIndex: 'creation_date',
      title: t('Creation Date'),
      _sorter: (b, a)=>{
        return new Date(b.tbin.creation_date).getTime()
          - new Date(a.tbin.creation_date).getTime();
      }},
    {key: 'drive', dataIndex: 'drive', title: t('Drive'),
      _sorter: str_sorter_get('drive')},
    {key: 'dur', dataIndex: 'dur', title: t('Duration'),
      _sorter: tcs_sorter_get('dur')},
    {key: 'end_tc', dataIndex: 'end_tc', title: t('End'),
      _sorter: tcs_sorter_get('end_tc')},
    {key: 'fps', dataIndex: 'fps', title: t('FPS'),
      _sorter: (a, b)=>a.fps - b.fps},
    {key: 'icon', dataIndex: 'icon', title: '',
      _sorter: (a, b)=>str.cmp(b.tbin.type, a.tbin.type)},
    {key: 'in_out', dataIndex: 'in_out', title: t('IN-OUT'),
      _sorter: tcs_sorter_get('in_out')},
    {key: 'mark_in', dataIndex: 'mark_in', title: t('Mark IN'),
      _sorter: tcs_sorter_get('mark_in')},
    {key: 'mark_out', dataIndex: 'mark_out', title: t('Mark OUT'),
      _sorter: tcs_sorter_get('mark_out')},
    {key: 'name', dataIndex: 'name', title: t('Name'),
      _sorter: (a, b)=>str.cmp(b.name, a.name)},
    {key: 'start_tc', dataIndex: 'start_tc', title: t('Start'),
      _sorter: tcs_sorter_get('start_tc')},
    {key: 'tracks', dataIndex: 'tracks', title: t('Tracks'),
      _sorter: str_sorter_get('tracks')},
    {key: 'tape', dataIndex: 'tape', title: t('Tape'),
      _sorter: str_sorter_get('tape')},
    {key: 'tape_id', dataIndex: 'tape_id', title: t('TapeID'),
      _sorter: str_sorter_get('tape_id')},
    {key: 'video', dataIndex: 'video', title: t('Video'),
      _sorter: str_sorter_get('video')},
  ], [str_sorter_get, t, tcs_sorter_get]);
  let col_options = useMemo(()=>{
    return cols
      .map(col=>{
        if (col.key == 'icon')
          return {label: 'Icon', value: col.key};
        return {label: col.title, value: col.key};
      });
  }, [cols]);
  let cols_in_use = useMemo(()=>{
    return col_keys
      .map(key=>{
        let _col = cols.find(col=>col.key == key);
        return {..._col, className: selected_cols.includes(key)
          ? 'bin-table-row-selected' : null, onHeaderCell: header_cell_handle,
        onCell: cell_handle};
      });
  }, [cell_handle, col_keys, cols, header_cell_handle, selected_cols]);
  let tbins = useMemo(()=>{
    if (!lbin?.tbin)
      return [];
    let _tbins = Object.values(lbin.tbin);
    let _query = query.toLowerCase().trim();
    if (!query)
      return _tbins;
    return _tbins
      .filter(tbin=>{
        if (tbin.is_hide)
          return false;
        let filter_keys = selected_cols.length ? selected_cols
          : Object.keys(tbin);
        return Object.entries(tbin).some(([key, value])=>{
          return filter_keys.includes(key)
            && String(value).toLowerCase().includes(_query);
        });
      });
  }, [lbin?.tbin, query, selected_cols]);
  let data_src = useMemo(()=>{
    let _data_src = tbins
      .map(tbin=>({color: <Tbin_color_icon color={tbin.color} />, tbin,
        icon: <Tbin_item_icon type={tbin.type}
          is_audio_only={tbin.is_audio_only} />, name: tbin.name,
        start_tc: tbin.start_tc, end_tc: tbin.end_tc, fps: tbin.fps,
        mob_id: tbin.mob_id}));
    let sort_criteria = sorters.map(({key, dir})=>({dir,
      sorter: cols.find(col=>col.key == key)._sorter}));
    return multi_sort(_data_src, sort_criteria);
  }, [cols, sorters, tbins]);
  let row_dropdown_items = useMemo(()=>{
    return [
      {label: t('Source settings...'), key: 'src_settings', disabled: true},
      {label: t('Get Info'), key: 'get_info', disabled: true},
      {label: t('Bulk Edit...'), key: 'bulk_edit', disabled: true},
      {label: t('Find And Replace'), key: 'find_and_replace', disabled: true},
      {label: t('Duplicate'), key: 'duplicate'},
    ];
  }, [t]);
  let row_dropdown_click_handle = useCallback(({key})=>{
    if (key == 'duplicate')
      cmd_clip_duplicate(ctx_record.tbin.mob_id);
  }, [cmd_clip_duplicate, ctx_record]);
  let header_dropdown_items = useMemo(()=>{
    return [
      {label: t('Choose columns...'), key: 'choose_cols'},
      {label: t('Add Custom Column'), key: 'add_custom_col', disabled: true},
      {label: t('Rename Column'), key: 'rename_col', disabled: true},
      {label: t('Hide Column'), key: 'hide_col', disabled: true},
      {type: 'divider'},
      {label: t('Sort on Column, Ascending'), key: 'sort_asc'},
      {label: t('Sort on Column, Descending'), key: 'sort_desc'},
      {label: t('Sift Bin Contents...'), key: 'sift_bin_contents',
        disabled: true},
      {type: 'divider'},
      {label: t('Bulk Edit...'), key: 'bulk_edit', disabled: true},
      {label: t('Find And Replace'), key: 'find_and_replace', disabled: true},
      {type: 'divider'},
      {label: t('What\' This?'), key: 'what_is_this', disabled: true},
    ];
  }, [t]);
  let col_preset_options = useMemo(()=>{
    return [
      {label: t('Basic'), value: 'basic', col_keys: ['icon', 'name',
        'start_tc', 'end_tc', 'dur', 'tracks', 'video', 'cmts']},
      {label: t('Media Tool'), value: 'media_tool', col_keys: ['color', 'icon',
        'name', 'creation_date', 'dur', 'drive', 'in_out', 'mark_in',
        'mark_out', 'audio_sr', 'tracks', 'start_tc', 'tape', 'video',
        'tape_id']},
    ];
  }, [t]);
  let col_keys_change = useCallback(_col_keys=>eserf(function*
  _col_keys_change(){
    col_keys_set(_col_keys);
    sorters_set([]);
    let res = yield back_app.user_set_tbin_cols(token, user.email, _col_keys);
    if (res.err)
      return metric.error('col_keys_change_err', res.err);
  }), [token, user.email]);
  let row_class_name_handle = useCallback(record=>{
    if (selected_objs.includes(record.tbin.mob_id))
      return 'bin-table-row bin-table-row-selected';
    return 'bin-table-row';
  }, [selected_objs]);
  let row_handle = useCallback(record=>{
    let mob_id = record.tbin.mob_id;
    return {
      onMouseDown: e=>{
        if ((e.ctrlKey || e.metaKey) && selected_objs.includes(mob_id))
        {
          let _selected_objs = selected_objs.filter(id=>id != mob_id);
          selected_objs_set(_selected_objs);
          selected_cols_set([]);
          return;
        }
        if ((e.ctrlKey || e.metaKey) && !selected_objs.includes(mob_id))
        {
          selected_objs_set([...selected_objs, mob_id]);
          selected_cols_set([]);
          return;
        }
        selected_objs_set([mob_id]);
        selected_cols_set([]);
      },
      onContextMenu: ()=>{
        is_row_ctx_open_set(true);
        ctx_record_set(record);
      },
      onDoubleClick: ()=>{
        if (record.tbin.type == 'sequence')
          return cmd_rec_monitor_load_clip(record.tbin.mob_id);
        cmd_src_monitor_load_clip(record.tbin.mob_id);
      },
    };
  }, [cmd_rec_monitor_load_clip, cmd_src_monitor_load_clip, selected_objs]);
  let header_dropdown_click_handle = useCallback(({key})=>{
    if (key == 'choose_cols')
      is_col_choose_modal_open_set(true);
    if (key == 'sort_asc')
    {
      let _sorters = [...sorters];
      let idx = _sorters.findIndex(sorter=>sorter.key == ctx_col);
      if (idx != -1)
        _sorters.splice(idx, 1);
      _sorters.unshift({key: ctx_col, dir: 'asc'});
      sorters_set(_sorters);
    }
    if (key == 'sort_desc')
    {
      let _sorters = [...sorters];
      let idx = _sorters.findIndex(sorter=>sorter.key == ctx_col);
      if (idx != -1)
        _sorters.splice(idx, 1);
      _sorters.unshift({key: ctx_col, dir: 'desc'});
      sorters_set(_sorters);
    }
  }, [ctx_col, sorters]);
  let row_open_change_handle = useCallback(is_open=>{
    if (!is_open)
      is_row_ctx_open_set(false);
  }, []);
  let header_open_change_handle = useCallback(is_open=>{
    if (!is_open)
      is_header_ctx_open_set(false);
  }, []);
  let col_choose_modal_close_handle = useCallback(()=>{
    is_col_choose_modal_open_set(false);
  }, []);
  let col_choose_modal_submit_handle = useCallback(_col_keys=>{
    is_col_choose_modal_open_set(false);
    col_keys_change(_col_keys);
    selected_cols_set([]);
  }, [col_keys_change]);
  let text_view_mode_click_handle = useCallback(()=>{
    view_mode_set('text');
  }, []);
  let frame_view_mode_click_handle = useCallback(()=>{
    view_mode_set('frame');
    selected_cols_set([]);
  }, []);
  let card_click_handler_get = useCallback(mob_id=>{
    return e=>{
      if ((e.ctrlKey || e.metaKey) && selected_objs.includes(mob_id))
      {
        let _selected_objs = selected_objs.filter(id=>id != mob_id);
        selected_objs_set(_selected_objs);
        selected_cols_set([]);
        return;
      }
      if ((e.ctrlKey || e.metaKey) && !selected_objs.includes(mob_id))
      {
        selected_objs_set([...selected_objs, mob_id]);
        selected_cols_set([]);
        return;
      }
      selected_objs_set([mob_id]);
      selected_cols_set([]);
    };
  }, [selected_objs]);
  let query_change_handle = useCallback(e=>{
    query_set(e.target.value);
  }, []);
  let select_value = useMemo(()=>{
    let found_preset = col_preset_options.find(preset=>{
      return preset.col_keys.length == col_keys.length
        && preset.col_keys.every((key, idx)=>col_keys[idx] == key);
    });
    if (!found_preset)
      return null;
    return found_preset.value;
  }, [col_keys, col_preset_options]);
  let select_change_handle = useCallback((value, option)=>{
    col_keys_change(option.col_keys);
    selected_cols_set([]);
  }, [col_keys_change]);
  let drag_end_handle = ({active, over})=>{
    if (active.id != over?.id)
    {
      let active_idx = col_keys.findIndex(key=>key == active?.id);
      let over_idx = col_keys.findIndex(key=>key == over?.id);
      col_keys_change(arrayMove(col_keys, active_idx, over_idx));
    }
    drag_idx_set({active: null, over: null});
  };
  let drag_over_handle = ({active, over})=>{
    let active_idx = col_keys.findIndex(col=>col.key == active.id);
    let over_idx = col_keys.findIndex(col=>col.key == over?.id);
    drag_idx_set({active: active.id, over: over?.id,
      direction: over_idx > active_idx ? 'right' : 'left'});
  };
  return (
    <div style={{display: 'flex', flexDirection: 'column', overflow: 'hidden',
      height: '100%'}}>
      <Tbin_col_choose_modal is_open={is_col_choose_modal_open}
        options={col_options} col_keys={col_keys}
        on_close={col_choose_modal_close_handle}
        on_submit={col_choose_modal_submit_handle} />
      <Premium_modal is_open={is_premium_modal_open}
        on_close={premium_modal_close_handle} />
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Select options={col_preset_options} style={{width: '200px'}}
          value={select_value} onChange={select_change_handle} />
        <Button title={t('Display bin In Text View mode')} size="small"
          onClick={text_view_mode_click_handle}>
          <Text_view_mode_icon />
        </Button>
        <Button title={t('Display bin In Frame View mode')} size="small"
          onClick={frame_view_mode_click_handle}>
          <Frame_view_mode_icon />
        </Button>
        <Input variant="borderless" size="small" allowClear
          styles={{input: {borderRadius: 0, fontSize: 11, color: '#000'}}}
          value={query} onChange={query_change_handle}
          prefix={<SearchOutlined style={{color: '#2A2A2A'}} />} />
      </div>
      {view_mode == 'text' && <div {...root_props_get({
        style: {overflowX: 'auto', height: '100%', position: 'relative'}})}>
        <input {...input_props_get()} />
        {is_drag_active && <div style={{position: 'absolute', top: 0, left: 0,
          width: '100%', height: '100%', border: `2px solid ${purple.primary}`,
          background: `${purple.primary}50`}} />}
        <Dropdown menu={{items: row_dropdown_items,
          onClick: row_dropdown_click_handle}} trigger={['contextMenu']}
        open={is_row_ctx_open} onOpenChange={row_open_change_handle}>
          <Dropdown menu={{items: header_dropdown_items,
            onClick: header_dropdown_click_handle}} trigger={['contextMenu']}
          open={is_header_ctx_open} onOpenChange={header_open_change_handle}>
            <div>
              <DndContext sensors={sensors} onDragEnd={drag_end_handle}
                modifiers={[restrict_to_horizontal_axis]}
                onDragOver={drag_over_handle}
                collisionDetection={closest_center}>
                <SortableContext items={cols_in_use.map(col=>col.key)}
                  strategy={horizontal_list_sorting_strategy}>
                  <Drag_idx_ctx.Provider value={drag_idx}>
                    <Table columns={cols_in_use} dataSource={data_src}
                      size="small" pagination={false} rowKey="mob_id"
                      rowClassName={row_class_name_handle} onRow={row_handle}
                      style={{width: '100%'}}
                      components={{header: {cell: Tbin_table_header_cell},
                        body: {cell: Tbin_table_body_cell}}} />
                  </Drag_idx_ctx.Provider>
                </SortableContext>
                <DragOverlay>
                  <th style={{backgroundColor: 'gray', padding: '4px 8px',
                    fontSize: 11, marginBottom: '1px', fontWeight: 600,
                    lineHeight: 1.5}}>
                    {cols_in_use[cols_in_use.findIndex(i=>{
                      return i.key == drag_idx.active;
                    })]?.title}
                  </th>
                </DragOverlay>
              </DndContext>
            </div>
          </Dropdown>
        </Dropdown>
      </div>}
      {view_mode == 'frame' && <div style={{display: 'flex', flexWrap: 'wrap',
        gap: '4px', padding: '4px', overflowY: 'auto'}}>
        {tbins.map(tbin=><div key={tbin.mob_id} style={{width: '80px',
          height: '60px', display: 'flex', flexDirection: 'column',
          background: selected_objs.includes(tbin.mob_id)
            ? theme.dark_blue : '#3D3D3D', cursor: 'pointer'}}
        onClick={card_click_handler_get(tbin.mob_id)}>
          <div style={{height: '40px', background: '#222'}} />
          <div style={{fontSize: 11, overflow: 'hidden', padding: '2px',
            textOverflow: 'ellipsis'}}>
            {tbin.name}
          </div>
        </div>)}
      </div>}
    </div>
  );
});
let proj_lbl = 'editor';
let Bins_tab = React.memo(({org, token, user, on_lbin_change, on_aaf_in_change,
  on_etag_change, on_tab_change, aaf_in, task_id, on_lbin_name_change})=>{
  let {t} = useTranslation();
  let {token: {borderRadiusLG: border_radius_lg}} = antd_theme.useToken();
  let es_root = use_es_root();
  let [proj, proj_set] = useState(null);
  let [files, files_set] = useState({});
  let [message_api, message_ctx_holder] = message.useMessage();
  let [expanded_keys, expanded_keys_set] = useState([]);
  let [active_drag_file_id, active_drag_file_id_set] = useState(null);
  let [is_add_dir_modal_open, is_add_dir_modal_open_set] = useState(false);
  let [is_rename_file_modal_open, is_rename_file_modal_open_set]
    = useState(false);
  let [ctx_file, ctx_file_set] = useState(null);
  let [add_dir_modal_path, add_dir_modal_path_set] = useState(null);
  let [is_aaf_init_loading, is_aaf_init_loading_set] = useState(false);
  let [last_inited_aaf_id, last_inited_aaf_id_set] = useState(null);
  let [open_dropdown_file_id, open_dropdown_file_id_set] = useState(null);
  let plain_files = useMemo(()=>{
    let queue = Object.values(files);
    let _files = [];
    while (queue.length)
    {
      let file = queue.shift();
      _files.push(file);
      if (file.children)
        queue = [...queue, ...Object.values(file.children)];
    }
    return _files;
  }, [files]);
  let proj_get = useCallback(proj_id=>eserf(function* _proj_get(){
    if (!token)
      return;
    if (!proj_id)
      proj_id = proj.id;
    let proj_res = yield back_app.proj.get(token, proj_id);
    if (proj_res.err && proj_res.err != 'not_found')
    {
      message_api.error(t('Something went wrong'));
      return metric.error('proj_get_err', proj_res.err);
    }
    let _proj = proj_res.proj;
    if (proj_res.err == 'not_found')
    {
      let insert_res = yield back_app.proj.insert(token, proj_lbl);
      if (insert_res.err)
      {
        message_api.error(t('Something went wrong'));
        return metric.error('proj_main_insert_err', insert_res.err);
      }
      _proj = insert_res.proj;
    }
    proj_set(_proj);
    expanded_keys_set([_proj.id]);
    let files_res = yield back_app.file.ls(token, proj_id);
    if (files_res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_ls_err', files_res.err);
    }
    files_set(files_res.files);
  }), [message_api, proj, t, token]);
  useEffect(()=>{
    if (!org || !token || proj)
      return;
    let proj_id = str.path2mongo(`/${org.id}/root/${proj_lbl}`);
    es_root.spawn(proj_get(proj_id));
  }, [es_root, proj_get, message_api, org, proj, t, token]);
  let file_drop_handle = useCallback((accepted_files, file_id)=>eserf(function*
  _file_drop_handle(){
    if (!accepted_files.length)
      return;
    if (!file_id)
      assert(0, 'no file_id');
    let file_ids = [];
    for (let file of accepted_files)
    {
      let _file_id = file_id + '/' + str.path2mongo(file.name);
      file_ids.push(_file_id);
    }
    let res = yield back_app.file.upload(token, file_ids, accepted_files,
      false);
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_upload_err', res.err);
    }
    proj_get();
  }), [message_api, proj_get, t, token]);
  let {getRootProps: root_props_get, getInputProps: input_props_get}
    = useDropzone({noClick: true, noKeyboard: true});
  let bin_dropdown_items = useMemo(()=>{
    return [
      {key: 'new_bin', label: t('New bin'), disabled: !aaf_in},
      {key: 'new_dir', label: t('New folder')},
      {key: 'rename', label: t('Rename')},
      {key: 'delete', label: t('Delete')},
      {key: 'Lock', label: t('Lock'), disabled: true},
    ];
  }, [aaf_in, t]);
  let root_dir_dropdown_items = useMemo(()=>{
    return [
      {key: 'new_bin', label: t('New bin')},
      {key: 'new_dir', label: t('New folder')},
    ];
  }, [t]);
  let dir_dropdown_items = useMemo(()=>{
    return [
      {key: 'new_bin', label: t('New bin')},
      {key: 'new_dir', label: t('New folder')},
      {key: 'rename', label: t('Rename')},
      {key: 'delete', label: t('Delete')},
    ];
  }, [t]);
  let aaf_init = useCallback(file_id=>eserf(function* _aaf_init(){
    if (!token || !aaf_in || !task_id || !user)
      return;
    is_aaf_init_loading_set(true);
    let res = yield back_app.editor.cmd(token, aaf_in, task_id, 'aaf_init', {});
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      metric.error('editor_cmd_err', res.err);
      is_aaf_init_loading_set(false);
      return;
    }
    let url = config_ext.back.app.url + xurl.url('/private/aaf/get.aaf',
      {file: res.file, email: user.email, token, ver: config_ext.ver});
    let file_res = yield ereq.get(url, {is_resp_blob: true});
    if (file_res.err)
    {
      message_api.error(t('Something went wrong'));
      metric.error('ereq_get_err', file_res.err);
      is_aaf_init_loading_set(false);
      return;
    }
    let filename = file_id2filename(file_id);
    let file = new File([file_res.data], filename);
    let upload_res = yield back_app.file.upload(token, [file_id], [file]);
    if (upload_res.err)
    {
      message_api.error(t('Something went wrong'));
      metric.error('ereq_get_err', upload_res.err);
      is_aaf_init_loading_set(false);
      return;
    }
    let dir_id = file_id.split('/').slice(0, -1).join('/');
    is_aaf_init_loading_set(false);
    expanded_keys_set([...expanded_keys, dir_id]);
    last_inited_aaf_id_set(file_id);
    proj_get();
  }), [aaf_in, message_api, t, token, user, proj_get, expanded_keys, task_id]);
  let new_aaf_file_id_get = useCallback(dir_id=>{
    if (!dir_id)
      assert(0, 'no dir_id');
    let file_id = dir_id + '/' + str.path2mongo('Untitled.aaf');
    let idx = 1;
    // eslint-disable-next-line no-loop-func
    while (plain_files.some(file=>file.id == file_id))
    {
      file_id = dir_id + '/' + str.path2mongo(`Untitled (${idx}).aaf`);
      idx += 1;
    }
    return file_id;
  }, [plain_files]);
  let bin_dropdown_click_handle = useCallback((opt, file)=>eserf(function*
  _bin_dropdown_click_handle(){
    ctx_file_set(file);
    if (opt.key == 'new_bin')
    {
      let dir_id = file.id.split('/').slice(0, -1).join('/');
      let file_id = new_aaf_file_id_get(dir_id);
      aaf_init(file_id);
    }
    if (opt.key == 'new_dir')
    {
      is_add_dir_modal_open_set(true);
      let dir_id = file.id.split('/').slice(0, -1).join('/');
      add_dir_modal_path_set(dir_id);
    }
    if (opt.key == 'rename')
      is_rename_file_modal_open_set(true);
    if (opt.key == 'delete')
    {
      let res = yield back_app.file.rm(token, file.id);
      if (res.err == 'forbidden')
        return message_api.error(t('Access denied'));
      if (res.err)
      {
        message_api.error(t('Something went wrong'));
        metric.error('file_delete_err', res.err);
        return;
      }
      proj_get();
    }
  }), [aaf_init, message_api, new_aaf_file_id_get, proj_get, t, token]);
  let dir_dropdown_click_handle = useCallback((opt, file)=>eserf(function*
  _dir_dropdown_click_handle(){
    ctx_file_set(file);
    if (opt.key == 'new_bin')
    {
      let file_id = new_aaf_file_id_get(file.id);
      aaf_init(file_id);
    }
    if (opt.key == 'new_dir')
    {
      is_add_dir_modal_open_set(true);
      add_dir_modal_path_set(file.id);
    }
    if (opt.key == 'rename')
      is_rename_file_modal_open_set(true);
    if (opt.key == 'delete')
    {
      let res = yield back_app.file.rm(token, file.id);
      if (res.err == 'forbidden')
        return message_api.error(t('Access denied'));
      if (res.err)
      {
        message_api.error(t('Something went wrong'));
        metric.error('file_delete_err', res.err);
        return;
      }
      proj_get();
    }
  }), [aaf_init, message_api, new_aaf_file_id_get, proj_get, t, token]);
  let root_dir_dropdown_click_handle = useCallback(opt=>{
    if (opt.key == 'new_bin')
    {
      let file_id = new_aaf_file_id_get(proj.id);
      aaf_init(file_id);
    }
    if (opt.key == 'new_dir')
    {
      if (!proj)
        assert(0, 'no proj');
      is_add_dir_modal_open_set(true);
      add_dir_modal_path_set(proj.id);
    }
  }, [aaf_init, new_aaf_file_id_get, proj]);
  let root_dir_drag_enter_handle = useCallback(()=>{
    active_drag_file_id_set(proj.id);
  }, [proj]);
  let root_dir_drag_leave_handle = useCallback(()=>{
    active_drag_file_id_set(_active_drag_file_id=>{
      if (active_drag_file_id == proj.id)
        return null;
      return _active_drag_file_id;
    });
  }, [active_drag_file_id, proj]);
  let root_dir_drop_handle = useCallback(e=>{
    if (!proj)
      return;
    let dropped_files = Array.from(e.dataTransfer.files);
    file_drop_handle(dropped_files, proj.id);
    active_drag_file_id_set(null);
  }, [file_drop_handle, proj]);
  let open_change_handle = useCallback(is_open=>{
    if (is_open)
      return;
    open_dropdown_file_id_set(null);
  }, []);
  let tree_nodes_render = useCallback(data=>{
    if (!proj)
      return [];
    if (!data)
    {
      return (
        <Tree.TreeNode key={proj.id} title={
          <Dropdown key={proj.id} menu={{items: root_dir_dropdown_items,
            onClick: root_dir_dropdown_click_handle}}
          open={open_dropdown_file_id == proj.id}
          onOpenChange={open_change_handle}>
            <div {...root_props_get({style: {display: 'flex',
              padding: '0 4px', alignItems: 'center',
              borderRadius: border_radius_lg, whiteSpace: 'nowrap',
              background: active_drag_file_id == proj.id
                ? purple.primary : null}})}
            onDragEnter={root_dir_drag_enter_handle}
            onDragLeave={root_dir_drag_leave_handle}
            onDrop={root_dir_drop_handle}>
              <input {...input_props_get()} />
              {t('Project')}
            </div>
          </Dropdown>
        } icon={<HomeOutlined />}>
          {tree_nodes_render(files)}
        </Tree.TreeNode>
      );
    }
    let _files = _.orderBy(Object.values(data), ['is_dir', 'id']);
    return _files.map(file=>{
      let drag_enter_handle = ()=>{
        active_drag_file_id_set(file.id);
      };
      let drag_leave_handle = ()=>{
        active_drag_file_id_set(_active_drag_file_id=>{
          if (active_drag_file_id == file.id)
            return null;
          return _active_drag_file_id;
        });
      };
      let drop_handle = e=>{
        let dropped_files = Array.from(e.dataTransfer.files);
        file_drop_handle(dropped_files, file.id);
        active_drag_file_id_set(null);
      };
      let filename = file_id2filename(file.id);
      if (!file.is_dir)
      {
        return (
          <Tree.TreeNode key={file.id} title={
            <Dropdown key={file.id} menu={{items: bin_dropdown_items,
              onClick: opt=>bin_dropdown_click_handle(opt, file)}}
            open={open_dropdown_file_id == file.id}
            onOpenChange={open_change_handle}>
              <div style={{whiteSpace: 'nowrap',
                color: last_inited_aaf_id==file.id ? purple.primary : null}}>
                {filename}
              </div>
            </Dropdown>
          } icon={<Antd_icon component={Bin_icon}
            style={{fontSize: '10px'}} />} />
        );
      }
      return (
        <Tree.TreeNode key={file.id} title={
          <Dropdown key={file.id} menu={{items: dir_dropdown_items,
            onClick: opt=>dir_dropdown_click_handle(opt, file)}}
          open={open_dropdown_file_id == file.id}
          onOpenChange={open_change_handle}>
            <div {...root_props_get({style: {display: 'flex', padding: '0 4px',
              background: active_drag_file_id==file.id ? purple.primary : null,
              alignItems: 'center', borderRadius: border_radius_lg,
              whiteSpace: 'nowrap'}})} onDragEnter={drag_enter_handle}
            onDragLeave={drag_leave_handle} onDrop={drop_handle}>
              {filename}
              <input {...input_props_get()} />
            </div>
          </Dropdown>
        } icon={<FolderFilled />}>
          {file.children ? tree_nodes_render(file.children) : null}
        </Tree.TreeNode>
      );
    });
  }, [dir_dropdown_items, root_props_get, active_drag_file_id, border_radius_lg,
    input_props_get, file_drop_handle, bin_dropdown_items, proj, files,
    bin_dropdown_click_handle, dir_dropdown_click_handle, last_inited_aaf_id,
    root_dir_dropdown_click_handle, t, root_dir_dropdown_items,
    root_dir_drop_handle, root_dir_drag_enter_handle, open_dropdown_file_id,
    root_dir_drag_leave_handle, open_change_handle]);
  let expand_handle = useCallback((_expanded_keys, info)=>{
    let new_expanded_keys = [...expanded_keys];
    if (info.expanded)
      new_expanded_keys.push(info.node.key);
    else
    {
      new_expanded_keys = new_expanded_keys
        .filter(file_id=>!file_id.startsWith(info.node.key));
    }
    expanded_keys_set(new_expanded_keys);
  }, [expanded_keys]);
  let drop_handle = useCallback(info=>eserf(function* _drop_handle(){
    let file_id = info.dragNode.key;
    // cannot move root directory
    if (file_id == proj.id)
      return;
    let filename = file_id2filename(file_id);
    let target_file_id;
    if (info.node.key == proj.id)
      target_file_id = info.node.key + '/' + str.path2mongo(filename);
    else
    {
      let target_file = plain_files.find(_file=>_file.id == info.node.key);
      if (!target_file)
        assert(0, 'drop file is not found');
      // if dropped on a file, then move dragged file in the directory where this
      // file is located.
      if (!target_file.is_dir)
      {
        target_file_id = info.node.key.split('/').slice(0, -1).join('/')
          + '/' + str.path2mongo(filename);
      }
      else
        target_file_id = info.node.key + '/' + str.path2mongo(filename);
    }
    if (file_id == target_file_id)
      return;
    let res = yield back_app.file.mv(token, file_id, target_file_id);
    if (res.err == 'already_exist')
      return message_api.error(t('Folder already exists'));
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      return metric.error('file_drag_mv_err', res.err);
    }
    proj_get();
  }), [proj_get, message_api, t, token, proj, plain_files]);
  let double_click_handle = useCallback((e, info)=>eserf(function*
  _double_click_handle(){
    if (!token || !user)
      return;
    let file = plain_files.find(_file=>_file.id == info.key);
    if (!file || file.is_dir)
      return;
    let res = yield back_app.editor_aaf_upload(token, user.email,
      {aaf_in: file.src.file, etag: file.src.etag, task_id: file.src.task_id});
    if (res.err)
    {
      metric.error('editor_aaf_upload_err', str.j2s(res));
      void message_api.error(`file upload failed ${res.err}`);
      return;
    }
    let filename = file_id2filename(file.id);
    on_lbin_change(res.lbin);
    on_lbin_name_change(filename.replace('.aaf', ''));
    on_aaf_in_change(res.file);
    on_etag_change(res.etag);
    on_tab_change('tbin');
  }), [message_api, on_aaf_in_change, on_etag_change, on_lbin_change,
    plain_files, token, user, on_tab_change, on_lbin_name_change]);
  let new_bin_handle = useCallback(()=>{
    if (!token || !aaf_in || !proj)
      return;
    let file_id = new_aaf_file_id_get(proj.id);
    aaf_init(file_id);
  }, [token, aaf_in, proj, new_aaf_file_id_get, aaf_init]);
  let add_dir_modal_close_handle = useCallback(()=>{
    is_add_dir_modal_open_set(false);
  }, []);
  let rename_file_modal_close_handle = useCallback(()=>{
    is_rename_file_modal_open_set(false);
  }, []);
  let right_click_handle = useCallback(({node})=>{
    open_dropdown_file_id_set(node.key);
  }, []);
  return (
    <div style={{display: 'flex', flexDirection: 'column', overflow: 'auto',
      height: '100%'}}>
      {message_ctx_holder}
      <Add_dir_modal is_open={is_add_dir_modal_open} token={token}
        path={add_dir_modal_path} on_close={add_dir_modal_close_handle}
        proj_get={proj_get} />
      <Rename_file_modal is_open={is_rename_file_modal_open} token={token}
        file_id={ctx_file?.id} is_dir={!!ctx_file?.is_dir}
        on_close={rename_file_modal_close_handle} proj_get={proj_get} />
      <Row justify="end">
        <Col>
          <Button loading={is_aaf_init_loading} onClick={new_bin_handle}
            disabled={!proj || !aaf_in}>
            {t('New Bin')}
          </Button>
        </Col>
      </Row>
      <Tree showLine switcherIcon={<DownOutlined />} autoExpandParent showIcon
        selectable={false} expandedKeys={expanded_keys}
        onExpand={expand_handle} draggable={{icon: false}} blockNode
        rootStyle={{background: 'none'}} onDrop={drop_handle}
        className="editor-tree" onDoubleClick={double_click_handle}
        onRightClick={right_click_handle}>
        {tree_nodes_render()}
      </Tree>
    </div>
  );
});
let Bin_panel = React.memo(({token, user, user_full, org, on_lbin_change,
  on_aaf_in_change, etag, etag_set, lbin, cmd_rec_monitor_load_clip, aaf_in,
  task_id, cmd_src_monitor_load_clip, cmd_clip_duplicate, lbin_name,
  on_lbin_name_change, ...rest})=>{
  let {t} = useTranslation();
  let [active_tab, active_tab_set] = useState('bins');
  let tbin_tab_name = useMemo(()=>{
    // this can happen when aaf from workspace is loading
    if (!lbin_name)
      return t('tbin');
    return lbin_name;
  }, [lbin_name, t]);
  let tab_items = useMemo(()=>[
    {key: 'bins', label: t('bins'), disalbed: !config_ext.is_local,
      children: <Bins_tab org={org} token={token} user={user} aaf_in={aaf_in}
        on_lbin_change={on_lbin_change} on_aaf_in_change={on_aaf_in_change}
        on_etag_change={etag_set} on_tab_change={active_tab_set}
        on_lbin_name_change={on_lbin_name_change} task_id={task_id} />},
    {key: 'tbin', label: tbin_tab_name, disabled: !lbin,
      children: <Tbin_tab lbin={lbin} user_full={user_full} user={user}
        token={token} cmd_rec_monitor_load_clip={cmd_rec_monitor_load_clip}
        cmd_src_monitor_load_clip={cmd_src_monitor_load_clip}
        cmd_clip_duplicate={cmd_clip_duplicate} />},
  ], [cmd_clip_duplicate, cmd_rec_monitor_load_clip, cmd_src_monitor_load_clip,
    lbin, org, t, token, user, user_full, tbin_tab_name, on_aaf_in_change,
    etag_set, on_lbin_change, aaf_in, on_lbin_name_change, task_id]);
  let tab_change_handle = useCallback(key=>{
    active_tab_set(key);
  }, []);
  return (
    <ConfigProvider theme={{components: {Dropdown: {paddingBlock: 4,
      fontSize: 11, fontSizeSM: 11}}}}>
      <Editor_panel style={{display: 'flex', flexDirection: 'column',
        overflow: 'auto', height: '100%'}} {...rest}>
        <Tabs items={tab_items} size="small" type="card"
          className="editor-tabs" activeKey={active_tab}
          onChange={tab_change_handle} />
      </Editor_panel>
    </ConfigProvider>
  );
});
let Meta_info = React.memo(({items, placement, style, on_select, children,
  disabled, ...rest})=>{
  let [is_open, is_open_set] = useState(false);
  let container_ref = useRef(null);
  if (!items)
  {
    return (
      <div ref={container_ref} style={{padding: '0 10px', background: 'black',
        color: theme.green, display: 'flex', justifyContent: 'center',
        alignItems: 'center', fontFamily: 'monospace', whiteSpace: 'nowrap',
        fontSize: '11px', ...style}} {...rest}>
        {children}
      </div>
    );
  }
  return (
    <Dropdown getPopupContainer={()=>container_ref.current}
      menu={{items, onClick: on_select}} trigger={['click']}
      placement={placement} onOpenChange={is_open_set}
      disabled={disabled}>
      <div ref={container_ref} style={{padding: '0 10px', background: 'black',
        color: theme.green, display: 'flex', justifyContent: 'space-between',
        gap: '5px', alignItems: 'center', fontFamily: 'monospace',
        whiteSpace: 'nowrap', fontSize: '11px', cursor: 'pointer', ...style}}
      {...rest}>
        <div style={{width: '100%'}}>
          {children}
        </div>
        <CaretDownOutlined style={{color: 'white'}}
          rotate={is_open ? 180 : 0} />
      </div>
    </Dropdown>
  );
});
let Editor_btn = React.memo(({key_bind, style, bg, hover_bg, active_bg,
  disabled, onClick: on_click, ...rest})=>{
  let [message_api, message_ctx_holder] = message.useMessage();
  let [is_hover, is_hover_set] = useState(false);
  let [is_active, is_active_set] = useState(false);
  let bg_style = useMemo(()=>{
    if (disabled)
      return bg || gray[4];
    if (is_active && is_hover)
      return active_bg || gray[5];
    if (is_hover)
      return hover_bg || gray[3];
    return bg || gray[4];
  }, [active_bg, bg, disabled, hover_bg, is_active, is_hover]);
  let cursor = useMemo(()=>{
    return disabled ? 'not-allowed' : 'pointer';
  }, [disabled]);
  let color = useMemo(()=>{
    return disabled ? '#404040' : 'black';
  }, [disabled]);
  let mouse_enter_handle = useCallback(()=>is_hover_set(true), []);
  let mouse_leave_handle = useCallback(()=>{
    is_hover_set(false);
    is_active_set(false);
  }, []);
  let mouse_down_handle = useCallback(()=>is_active_set(true), []);
  let mouse_up_handle = useCallback(()=>is_active_set(false), []);
  let click_handle = useCallback(e=>{
    if (key_bind)
    {
      message_api.destroy();
      message_api.open({content: key_bind2lbl(key_bind), duration: 0.5,
        style: {marginTop: '50vh'}});
    }
    if (on_click)
      on_click(e);
  }, [on_click, key_bind, message_api]);
  return (
    <>
      {message_ctx_holder}
      <button disabled={disabled} onMouseEnter={mouse_enter_handle}
        onMouseLeave={mouse_leave_handle} onMouseDown={mouse_down_handle}
        onMouseUp={mouse_up_handle} onClick={click_handle}
        style={{minWidth: '30px', minHeight: '18px', border: 'none',
          outline: 'none', cursor, background: bg_style, color,
          transition: '0.1s ease', display: 'flex', justifyContent: 'center',
          alignItems: 'center', fontSize: '11px', ...style}}
        {...rest}
      />
    </>
  );
});
let Tc_input = React.memo(({tc_input})=>{
  let tc_lbl = useMemo(()=>{
    if (!tc_input || !tc_input.n)
      return '';
    return tc_input.n.split('').reverse().join('').match(/(..?)/gu)
      .map(pair=>pair.split('').reverse().join('')).reverse().join(':');
  }, [tc_input]);
  if (!tc_input)
    return null;
  return (
    <div
      style={{position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)', background: gray[5],
        height: '16px', width: '100px', zIndex: 1, fontSize: '11px',
        display: 'flex', flexDirection: 'column', justifyContent: 'center',
        alignItems: 'flex-end'}}
    >
      {tc_input.action == 'add' && '+'}
      {tc_input.action == 'sub' && '-'}
      {tc_lbl}
    </div>
  );
});
export let Mark_in_indicator = React.memo(({style, ...rest})=>{
  let triangles = [];
  for (let i = 0; i < 7; i++)
  {
    triangles.push(<div key={i} style={{width: 0, height: 0,
      borderTop: '20px solid transparent', borderLeft: '20px solid #B2B2B3',
      borderBottom: '20px solid transparent'}} />);
  }
  return (
    <div style={{display: 'flex', flexDirection: 'column', width: '20px',
      position: 'absolute', ...style}} {...rest}>
      {triangles}
    </div>
  );
});
export let Mark_out_indicator = React.memo(({style, ...rest})=>{
  let triangles = [];
  for (let i = 0; i < 7; i++)
  {
    triangles.push(<div key={i} style={{width: 0, height: 0,
      borderTop: '20px solid transparent', borderRight: '20px solid #B2B2B3',
      borderBottom: '20px solid transparent'}} />);
  }
  return (
    <div style={{display: 'flex', flexDirection: 'column', width: '20px',
      position: 'absolute', alignItems: 'flex-end', ...style}} {...rest}>
      {triangles}
    </div>
  );
});
let stroke_width = 4;
let Draw_canvas = React.memo(({is_drawing, drawing_view_box,
  drawing_objs, drawing_objs_set, drawing_tool, drawing_color, cmt_svg})=>{
  let [is_mouse_down, is_mouse_down_set] = useState(false);
  let [last_coord, last_coord_set] = useState(null);
  let canvas_ref = useRef(null);
  let mouse_up_handle = useCallback(()=>{
    is_mouse_down_set(false);
    last_coord_set(null);
  }, []);
  let mouse_down_handle = useCallback(e=>{
    let canvas = canvas_ref.current;
    if (!canvas)
      assert(0, 'no canvas');
    let canvas_coords = coords_get(canvas);
    let x = e.clientX - canvas_coords.left + window.scrollX;
    let y = e.clientY - canvas_coords.top + window.scrollY;
    if (drawing_tool == 'arrow')
    {
      let _svg_objs = [...drawing_objs];
      _svg_objs.push({type: 'arrow', x1: x, y1: y, x2: x, y2: y,
        color: drawing_color});
      drawing_objs_set(_svg_objs);
    }
    else if (drawing_tool == 'rect')
    {
      let _svg_objs = [...drawing_objs];
      _svg_objs.push({type: 'rect', x1: x, y1: y, x2: x, y2: y,
        stroke_width, color: drawing_color});
      drawing_objs_set(_svg_objs);
    }
    last_coord_set({x, y});
    is_mouse_down_set(true);
  }, [drawing_color, drawing_objs, drawing_objs_set, drawing_tool]);
  let mouse_move_handle = useCallback(e=>{
    if (!is_mouse_down || !is_drawing)
      return;
    let canvas = canvas_ref.current;
    let canvas_coords = coords_get(canvas);
    let x = e.clientX - canvas_coords.left + window.scrollX;
    let y = e.clientY - canvas_coords.top + window.scrollY;
    if (drawing_tool == 'brush')
    {
      let _svg_objs = [...drawing_objs];
      _svg_objs.push({type: 'line', x1: last_coord.x, y1: last_coord.y,
        x2: x, y2: y, stroke_width, color: drawing_color});
      _svg_objs.push({type: 'circle', x, y, r: stroke_width / 2,
        color: drawing_color});
      last_coord_set({x, y});
      drawing_objs_set(_svg_objs);
    }
    else if (drawing_tool == 'arrow')
    {
      let _svg_objs = [...drawing_objs];
      _svg_objs = _svg_objs.slice(0, -1);
      _svg_objs.push({type: 'arrow', x1: last_coord.x, y1: last_coord.y,
        x2: x, y2: y, color: drawing_color});
      drawing_objs_set(_svg_objs);
    }
    else if (drawing_tool == 'rect')
    {
      let _svg_objs = [...drawing_objs];
      _svg_objs = _svg_objs.slice(0, -1);
      _svg_objs.push({type: 'rect', x1: last_coord.x, y1: last_coord.y,
        x2: x, y2: y, stroke_width, color: drawing_color});
      drawing_objs_set(_svg_objs);
    }
  }, [drawing_color, drawing_objs, drawing_objs_set, drawing_tool, is_drawing,
    is_mouse_down, last_coord]);
  useEffect(()=>{
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>document.removeEventListener('mouseup', mouse_up_handle);
  }, [mouse_up_handle]);
  useEffect(()=>{
    if (drawing_objs_set)
      drawing_objs_set([]);
  }, [drawing_objs_set, is_drawing]);
  let drawing_uri = useMemo(()=>{
    if (!is_drawing && cmt_svg)
      return encodeURIComponent(cmt_svg);
    if (!drawing_objs || !drawing_objs.length)
      return '';
    return encodeURIComponent(
      svg.compose(drawing_objs, drawing_view_box.x, drawing_view_box.y));
  }, [cmt_svg, drawing_objs, is_drawing, drawing_view_box]);
  if (!drawing_view_box)
    return null;
  return (
    <div style={{position: 'absolute', height: `${drawing_view_box.y}px`,
      width: `${drawing_view_box.x}px`, zIndex: 1, left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)', cursor: 'pointer'}} ref={canvas_ref}
    onMouseDown={mouse_down_handle} onMouseMove={mouse_move_handle}>
      {drawing_uri && <img src={`data:image/svg+xml;utf8,${drawing_uri}`}
        style={{position: 'absolute', pointerEvents: 'none',
          width: `${drawing_view_box.x}px`, height: `${drawing_view_box.y}px`}}
      />}
    </div>
  );
});
// XXX vladimir: move to player.js
let Scrub_bar = React.memo(({len, fps, frame, on_frame_change, arr = [],
  ptr_color=theme.light_blue, is_focused=false, trim_delta_pos, side,
  rec_frame, trim_counter_tooltip})=>{
  let [trim_mode, trim_mode_set] = use_je('editor.trim_mode', null);
  let [container_width, container_width_set] = useState(0);
  let [is_ptr_moving, is_ptr_moving_set] = useState(false);
  let [ticks_gap, ticks_gap_set] = useState();
  let container_ref = useRef(null);
  let trim_delta = useMemo(()=>{
    if (!trim_mode)
      return 0;
    return rec_frame - trim_mode.init_pos;
  }, [rec_frame, trim_mode]);
  let mouse_move_handle = useCallback(e=>{
    let container = container_ref.current;
    let container_offset = coords_get(container).left;
    let frame_rel = (e.clientX - container_offset) / container.offsetWidth;
    let _frame = len * frame_rel;
    on_frame_change(_frame);
  }, [on_frame_change, len]);
  let mouse_down_handle = useCallback(e=>{
    if (trim_mode)
      return;
    mouse_move_handle(e);
    is_ptr_moving_set(true);
  }, [trim_mode, mouse_move_handle]);
  let mouse_up_handle = useCallback(()=>is_ptr_moving_set(false), []);
  let resize_handle = useCallback(()=>{
    let _container_width = container_ref.current.offsetWidth;
    container_width_set(_container_width);
    let min_gap_px = 30;
    let t = len / fps;
    let min_gap_s = t * min_gap_px / _container_width;
    let periods_s = [0.04, 0.2, 1, 2, 5, 10, 15]; // 15 * 2 ^ n
    let min_period = periods_s.at(0);
    if (min_gap_s < periods_s.at(min_period))
    {
      let _ticks_gap = min_gap_s * _container_width / t;
      ticks_gap_set(_ticks_gap);
      return;
    }
    let sec_gap = periods_s.find((period, index)=>{
      return min_gap_s >= periods_s[index - 1] && min_gap_s <= period;
    });
    if (sec_gap)
    {
      let _ticks_gap = sec_gap * _container_width / t;
      ticks_gap_set(_ticks_gap);
      return;
    }
    let base_period = periods_s.at(-1);
    let power = Math.floor(Math.log2(2 * min_gap_s / base_period));
    sec_gap = base_period * 2 ** power;
    let _ticks_gap = sec_gap * _container_width / t;
    ticks_gap_set(_ticks_gap);
  }, [fps, len]);
  useEffect(()=>{
    resize_handle();
    window.addEventListener('resize', resize_handle);
    return ()=>window.removeEventListener('resize', resize_handle);
  }, [resize_handle]);
  useEffect(()=>{
    if (!is_ptr_moving)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [mouse_move_handle, mouse_up_handle, is_ptr_moving]);
  let f2px_k = useMemo(()=>len / container_width,
    [container_width, len]);
  let trim_delta_click_handle = useCallback(()=>{
    if (!trim_mode)
      return;
    trim_mode_set({...trim_mode, side, is_side_clicked: true});
  }, [side, trim_mode, trim_mode_set]);
  let ticks = useMemo(()=>{
    if (!ticks_gap || trim_mode)
      return [];
    let _ticks = [];
    for (let left = 0; left <= container_width; left += ticks_gap)
    {
      _ticks.push(<div key={left} style={{position: 'absolute',
        left: `${left}px`, transform: 'translateX(-50%)', bottom: 0,
        height: '8px', width: '1px', background: 'black'}} />);
    }
    return _ticks;
  }, [container_width, ticks_gap, trim_mode]);
  let left = useMemo(()=>{
    return player.f2px(frame, f2px_k, 0);
  }, [f2px_k, frame]);
  let bg = useMemo(()=>{
    if (trim_mode)
      return 'linear-gradient(#6c6c6c 0%, #a0a0a0 100%)';
    if (is_focused)
      return theme.gray4;
    return theme.gray3;
  }, [is_focused, trim_mode]);
  return (
    <div ref={container_ref} style={{height: '16px', cursor: 'pointer',
      border: `1px solid ${theme.gray1}`, background: bg, position: 'relative',
      zIndex: 0}} onMouseDown={mouse_down_handle}
    onTouchStart={mouse_down_handle}>
      {!trim_mode && <div style={{position: 'absolute',
        overflow: 'hidden', width: '100%', height: '100%'}}>
        {ticks}
        {/* XXX vladimir: make key unique */}
        {arr.map((seg, idx)=><Seg key={idx} seg={seg} fps={fps}
          zoom={0} f2px_k={f2px_k} />)}
      </div>}
      {!trim_mode && <Frame_ptr left={left} color={ptr_color} />}
      {trim_mode && <div style={{position: 'absolute',
        [trim_delta_pos == 'left' ? 'left' : 'right']: 0,
        background: '#AD62B6', fontSize: '11px', height: '100%',
        [trim_delta_pos == 'left' ? 'borderRight' : 'borderLeft']:
          `1px solid ${theme.gray1}`, zIndex: 100,
        minWidth: '60px', textAlign: 'center', cursor: 'pointer'}}
      title={trim_counter_tooltip} onMouseDown={trim_delta_click_handle}>
        {Math.round(trim_delta)}
        <div style={{position: 'absolute',
          [trim_delta_pos == 'left' ? 'left' : 'right']: 0,
          background: trim_mode.side == side ? '#3EFF32' : gray[6],
          height: '4px', bottom: '-4px', width: 'calc(100% + 2px)',
          left: '-1px', cursor: 'pointer',
          border: `1px solid ${theme.gray1}`}} />
      </div>}
    </div>
  );
});
export let Monitor = React.memo(({monitor, toolbar=[], token, split,
  ptr_color, tc_input, is_drawing, drawing_view_box_set, side,
  drawing_objs, drawing_objs_set, drawing_tool, drawing_color, cmt_svg,
  on_playback_rate_change, frame, on_frame_change, selected_monitor,
  playback_rate, drawing_view_box, is_focused=false, trim_delta_pos,
  trim_counter_tooltip, rec_frame, on_focus, cmd_selector_set})=>{
  let [video_render_segs, video_render_segs_set] = useState([]);
  let [audio_render_segs, audio_render_segs_set] = useState([]);
  let [splits_width, splits_width_set] = useState(0);
  let splits_container_ref = useRef(null);
  let fps = useMemo(()=>{
    if (!monitor?.editrate?.n || !monitor?.editrate?.d)
      return 0;
    return monitor.editrate.n / monitor.editrate.d;
  }, [monitor?.editrate?.n, monitor?.editrate?.d]);
  let resolution = useMemo(()=>{
    if (!monitor?.resolution)
      return {w: 1920, h: 1080};
    return monitor.resolution;
  }, [monitor?.resolution]);
  let is_mark_in = useMemo(()=>{
    if (!monitor?.mark_in)
      return false;
    return monitor.mark_in == frame;
  }, [monitor?.mark_in, frame]);
  let is_mark_out = useMemo(()=>{
    if (!monitor?.mark_out)
      return false;
    return monitor.mark_out == frame;
  }, [frame, monitor?.mark_out]);
  let is_first_frame = useMemo(()=>{
    if (!monitor)
      return false;
    return frame == 0;
  }, [frame, monitor]);
  let video_tracks = useMemo(()=>{
    if (!monitor)
      return [];
    return monitor.tracks.filter(track=>track.id[0] == 'V');
  }, [monitor]);
  let is_clip_first_frame_left = useMemo(()=>{
    if (!video_tracks.length)
      return false;
    let cuts = editor.cuts_get(video_tracks);
    return cuts.some(cut=>cut == frame);
  }, [frame, video_tracks]);
  let is_clip_first_frame_right = useMemo(()=>{
    if (!video_tracks.length)
      return false;
    let cuts = editor.cuts_get(video_tracks);
    let next_frame = frame + 1;
    return cuts.some(cut=>cut == next_frame);
  }, [frame, video_tracks]);
  let is_penultimate_frame = useMemo(()=>{
    if (!monitor)
      return false;
    return frame == monitor.len - 1;
  }, [frame, monitor]);
  let is_last_frame = useMemo(()=>{
    if (!monitor)
      return false;
    return frame == monitor.len;
  }, [frame, monitor]);
  let markers = useMemo(()=>{
    if (!monitor)
      return [];
    return editor.markers_get(monitor.tracks);
  }, [monitor]);
  let cur_marker = useMemo(()=>{
    return markers.find(seg=>seg.start == rec_frame);
  }, [rec_frame, markers]);
  let timeline_arr = useMemo(()=>{
    if (!monitor)
      return [];
    let arr = [...markers];
    if (monitor.mark_in)
    {
      arr.push({is_no_arr: true, len: 1, start: monitor.mark_in,
        type: 'mark_in', zidx: 200});
    }
    if (monitor.mark_out)
    {
      arr.push({is_no_arr: true, len: 1, start: monitor.mark_out,
        type: 'mark_out', zidx: 200});
    }
    return arr;
  }, [monitor, markers]);
  let len = useMemo(()=>{
    if (!monitor)
      return 0;
    return monitor.len;
  }, [monitor]);
  let frame_change_handle = useCallback(_frame=>{
    on_frame_change(_frame);
    on_playback_rate_change(0);
  }, [on_frame_change, on_playback_rate_change]);
  use_effect_eserf(()=>eserf(function* _use_effect_render_segs_load(){
    if (!token || !monitor)
    {
      video_render_segs_set([]);
      audio_render_segs_set([]);
      return;
    }
    let [_video_render_segs, _audio_render_segs] = yield this.wait_ret([
      player.video_render_segs_get(token, monitor, selected_monitor),
      player.audio_render_segs_get(token, monitor, selected_monitor),
    ]);
    video_render_segs_set(_video_render_segs);
    audio_render_segs_set(_audio_render_segs);
  }), [monitor, selected_monitor, token]);
  let aspect_ratio = useMemo(()=>{
    if (!monitor?.resolution)
      return 16 / 9;
    return monitor.resolution.w / monitor.resolution.h;
  }, [monitor]);
  let resize_handle = useCallback(()=>{
    let container = splits_container_ref.current;
    let container_rect = container.getBoundingClientRect();
    let container_height = container_rect.height;
    let _splits_width = container_height * aspect_ratio;
    splits_width_set(_splits_width);
  }, [aspect_ratio]);
  useEffect(()=>{
    let container = splits_container_ref.current;
    let resize_observer = new ResizeObserver(resize_handle);
    resize_observer.observe(container);
    return ()=>resize_observer.unobserve(container);
  }, [resize_handle]);
  let split_grid_size = useMemo(()=>{
    if (split == 'mono')
      return 1;
    if (split == 'quad')
      return 2;
    if (split == 'nine')
      return 3;
    assert(0, 'unexpected split name');
  }, [split]);
  let splits = useMemo(()=>{
    let cur_video_render_seg = video_render_segs.find(render_seg=>{
      return player.frame_start(render_seg) <= frame
        && frame <= player.frame_end(render_seg);
    });
    if (split == 'mono' || !cur_video_render_seg
      || !cur_video_render_seg.split_render_segs.length)
    {
      return [[{video_render_segs, audio_render_segs}]];
    }
    let _splits = [];
    for (let render_seg of cur_video_render_seg.split_render_segs)
      _splits.push({video_render_segs: [render_seg]});
    let rows = [];
    for (let row_idx = 0; row_idx < split_grid_size; row_idx++)
    {
      let start_idx = row_idx * split_grid_size;
      let end_idx = (row_idx + 1) * split_grid_size;
      let row = _splits.slice(start_idx, end_idx);
      let empty_splits = new Array(split_grid_size - row.length).fill(null);
      row = [...row, ...empty_splits];
      rows.push(row);
    }
    return rows;
  }, [audio_render_segs, frame, split, video_render_segs, split_grid_size]);
  let selected_split_idx = useMemo(()=>{
    let cur_video_render_seg = video_render_segs.find(render_seg=>{
      return player.frame_start(render_seg) <= frame
        && frame <= player.frame_end(render_seg);
    });
    if (!cur_video_render_seg?.split_render_segs?.length)
      return null;
    let idx = cur_video_render_seg.split_render_segs.findIndex(render_seg=>{
      return render_seg.src == cur_video_render_seg.src;
    });
    if (idx == -1)
      assert(0, 'split not found');
    return idx;
  }, [frame, video_render_segs]);
  let player_resize_handle = useCallback((w, h)=>{
    if (drawing_view_box_set)
      drawing_view_box_set({x: w, y: h});
  }, [drawing_view_box_set]);
  let split_click_handle = useCallback(idx=>{
    if (!monitor?.tracks)
      return;
    for (let track of monitor.tracks)
    {
      let abs_starts_map = player.abs_starts_get(track);
      for (let [seg] of abs_starts_map.entries())
      {
        if (seg.type != 'selector' || seg.abs_start > rec_frame
          || seg.abs_start + seg.len < rec_frame)
        {
          continue;
        }
        cmd_selector_set(monitor.mob_id, track.id, idx, seg.abs_start);
        return;
      }
    }
  }, [cmd_selector_set, monitor, rec_frame]);
  return (
    <div style={{height: '100%', width: '100%', display: 'flex',
      flexDirection: 'column'}} onMouseDown={on_focus}>
      <div style={{height: '100%', margin: '2px', display: 'flex',
        width: 'calc(100% - 4px)', background: '#232323'}}>
        <div style={{display: 'flex', flexDirection: 'column', height: '100%',
          flexGrow: 1, position: 'relative', alignItems: 'center'}}
        ref={splits_container_ref}>
          {splits.map((row, row_idx)=><div key={row_idx}
            style={{display: 'flex', height: '100%', width: splits_width,
              borderTopStyle: 'solid', borderTopWidth: '1px',
              borderTopColor: row_idx != 0 ? 'black' : 'transparent'}}>
            {row.map((col, col_idx)=>{
              if (!col)
              {
                return <div key={col_idx} style={{height: '100%',
                  aspectRatio: aspect_ratio}} />;
              }
              let idx = row_idx * split_grid_size + col_idx;
              return (
                <div key={col_idx} style={{position: 'relative',
                  height: '100%', cursor: 'pointer',
                  aspectRatio: aspect_ratio,
                  borderLeft: col_idx != 0 ? '1px solid black' : 'none'}}
                onClick={()=>split_click_handle(idx)}>
                  <player.Player resolution={resolution} is_audio_video_sync
                    video_render_segs={col.video_render_segs} frame={frame}
                    fps={fps} is_mark_in={is_mark_in} is_mark_out={is_mark_out}
                    on_resize={player_resize_handle}
                    cur_marker={cur_marker} playback_rate={playback_rate}
                    audio_render_segs={col.audio_render_segs}
                    is_penultimate_frame={is_penultimate_frame}
                    is_first_frame={is_first_frame}
                    is_clip_first_frame_left={is_clip_first_frame_left}
                    is_clip_first_frame_right={is_clip_first_frame_right}
                    is_last_frame={is_last_frame} />
                  {split != 'mono' && idx === selected_split_idx
                    && <div style={{position: 'absolute', left: '0px',
                      bottom: '0px', width: '100%', height: '2px',
                      background: 'green'}} />}
                </div>
              );
            })}
            {is_mark_in && <Mark_in_indicator style={{left: 0, top: '50%',
              transform: 'translateY(-50%)'}} />}
            {is_mark_out && <Mark_out_indicator style={{right: 0, top: '50%',
              transform: 'translateY(-50%)'}} />}
            {is_first_frame && <div style={{position: 'absolute',
              left: '5px', bottom: '2px'}}>
              <First_frame_icon />
            </div>}
            {!is_first_frame && !is_last_frame && is_clip_first_frame_left &&
              <div style={{position: 'absolute', left: '5px', bottom: '2px'}}>
                <Clip_first_frame_left_icon />
              </div>}
            {!is_penultimate_frame && is_clip_first_frame_right && <div style={{
              position: 'absolute', right: '5px', bottom: '2px'}}>
              <Clip_first_frame_right_icon />
            </div>}
            {is_penultimate_frame && <div style={{position: 'absolute',
              right: '5px', bottom: '2px'}}>
              <Last_frame_icon />
            </div>}
            {is_last_frame && <div style={{position: 'absolute',
              right: '5px', bottom: '2px'}}>
              <Last_frame_icon />
            </div>}
            {cur_marker && <div style={{position: 'absolute', display: 'flex',
              flexDirection: 'column', alignItems: 'center', bottom: '4px',
              left: '50%', transform: 'translateX(-50%)',
              maxWidth: 'calc(100% - 40px)'}}>
              <Antd_icon component={Marker_seg_icon}
                style={{color: cur_marker.color, fontSize: '24px'}} />
              {cur_marker.comment && <span style={{color: 'white',
                fontSize: '11px', maxWidth: '100%',
                textShadow: '-1px 0 black, 0 1px black, 1px 0 black,'
                  +' 0 -1px black', whiteSpace: 'nowrap', overflow: 'hidden',
                textOverflow: 'ellipsis'}}>
                {cur_marker.comment}
              </span>}
            </div>}
          </div>)}
          <Tc_input tc_input={tc_input} />
          {split == 'mono' && <Draw_canvas is_drawing={is_drawing}
            drawing_view_box={drawing_view_box} drawing_objs={drawing_objs}
            drawing_objs_set={drawing_objs_set} drawing_tool={drawing_tool}
            drawing_color={drawing_color} cmt_svg={cmt_svg} />}
        </div>
        {is_last_frame && <div style={{background: '#0E0E0E',
          borderLeft: '1px solid white', height: '100%', width: '100px'}} />}
      </div>
      <div style={{margin: '2px'}}>
        <Scrub_bar is_focused={is_focused} len={len} fps={fps} frame={frame}
          arr={timeline_arr} on_frame_change={frame_change_handle}
          ptr_color={ptr_color} trim_delta_pos={trim_delta_pos}
          rec_frame={rec_frame} side={side}
          trim_counter_tooltip={trim_counter_tooltip} />
      </div>
      {toolbar.map((row, index)=><div key={index} style={{display: 'grid',
        gridAutoFlow: 'column', gridAutoColumns: '1fr', minHeight: '20px',
        height: '20px', margin: '2px'}}>
        {row}
      </div>)}
    </div>
  );
});
let Meta_item = React.memo(({lbl, value})=>{
  return (
    <div style={{display: 'flex', justifyContent: 'space-between',
      gap: '20px'}}>
      <span>{lbl}</span>
      <span>{value}</span>
    </div>
  );
});
let Dropdown = React.memo(props=>{
  return (
    <ConfigProvider theme={{algorithm: theme.darkAlgorithm, components: {
      Dropdown: {borderRadiusLG: 0, borderRadiusSM: 0, borderRadiusXS: 0,
        colorBgElevated: '#686868', paddingBlock: 4, fontSize: 11,
        fontSizeSM: 11, paddingXS: 8}}}}>
      <Antd_dropdown {...props} />
    </ConfigProvider>
  );
});
let Tc_meta_info = React.memo(({monitor, frame, token,
  default_counter='seq_tc_tc1_mas', disabled_keys=[]})=>{
  let [counter, counter_set] = useState(default_counter);
  let {t} = useTranslation();
  let seq_tc_tc1_mas_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Mas TC1'), value: tc.frame2tc(
      monitor.start_tc + frame, monitor.editrate, true)};
  }, [frame, monitor, t]);
  let seq_tc_tc1_dur_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Dur TC1'), value: tc.frame2tc(monitor.len, monitor.editrate,
      true, true)};
  }, [monitor, t]);
  let seq_tc_tc1_io_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    let mark_in = monitor.mark_in;
    let mark_out = monitor.mark_out;
    let io_frames;
    if (!mark_in && !mark_out)
      io_frames = monitor.len - frame;
    else if (!mark_in)
      io_frames = Math.abs(mark_out - frame);
    else if (!mark_out)
      io_frames = Math.abs(mark_in - frame);
    else
      io_frames = Math.abs(mark_in - mark_out);
    return {lbl: t('I/O TC1'), value: tc.frame2tc(io_frames, monitor.editrate,
      true, true)};
  }, [frame, monitor, t]);
  let seq_tc_tc1_abs_counter_get = useCallback(()=>{
    return {lbl: t('Abs TC1'), value: frame};
  }, [frame, t]);
  let seq_tc_tc1_rem_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Rem TC1'), value: tc.frame2tc(
      monitor.len - frame, monitor.editrate, true, true)};
  }, [frame, monitor, t]);
  let seq_tc_24_mas_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    let tc_o1 = tc.frame2tc_o(monitor.start_tc, monitor.editrate);
    let tc_o2 = tc.frame2tc_o(frame, monitor.editrate);
    let tc_o = tc.add_tc_o(tc_o1, tc_o2, monitor.editrate);
    return {lbl: t('Mas 24'), value: tc.tc_o2str(tc_o,
      {is_no_frame: !monitor.editrate})};
  }, [frame, monitor, t]);
  let seq_tc_24_dur_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Dur 24'), value: tc.frame2tc(monitor.len, monitor.editrate,
      true, true)};
  }, [monitor, t]);
  let seq_tc_24_io_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    let mark_in = monitor.mark_in;
    let mark_out = monitor.mark_out;
    let io_frames;
    if (!mark_in && !mark_out)
      io_frames = monitor.len - frame;
    else if (!mark_in)
      io_frames = Math.abs(mark_out - frame);
    else if (!mark_out)
      io_frames = Math.abs(mark_in - frame);
    else
      io_frames = Math.abs(mark_in - mark_out);
    return {lbl: t('I/O 24'), value: tc.frame2tc(io_frames, monitor.editrate,
      true, true)};
  }, [frame, monitor, t]);
  let seq_tc_24_abs_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Abs 24'), value: tc.frame2tc(frame, monitor.editrate, true,
      true)};
  }, [frame, monitor, t]);
  let seq_tc_24_rem_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Rem 24'), value: tc.frame2tc(
      monitor.len - frame, monitor.editrate, true, true)};
  }, [frame, monitor, t]);
  let seq_tc_25_mas_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Mas 25'), value: tc.frame2tc(monitor.start_tc + frame,
      monitor.editrate, true)};
  }, [frame, monitor, t]);
  let seq_tc_25_dur_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Dur 25'), value: tc.frame2tc(monitor.len, monitor.editrate,
      true)};
  }, [monitor, t]);
  let seq_tc_25_io_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    let mark_in = monitor.mark_in;
    let mark_out = monitor.mark_out;
    let io_frames;
    if (!mark_in && !mark_out)
      io_frames = monitor.len - frame;
    else if (!mark_in)
      io_frames = Math.abs(mark_out - frame);
    else if (!mark_out)
      io_frames = Math.abs(mark_in - frame);
    else
      io_frames = Math.abs(mark_in - mark_out);
    return {lbl: t('I/O 25'), value: tc.frame2tc(io_frames, monitor.editrate,
      true, true)};
  }, [frame, monitor, t]);
  let seq_tc_25_abs_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Abs 25'), value: tc.frame2tc(frame, monitor.editrate, true,
      true)};
  }, [frame, monitor, t]);
  let seq_tc_25_rem_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Rem 25'), value: tc.frame2tc(
      monitor.len - frame, monitor.editrate, true, true)};
  }, [frame, monitor, t]);
  let seq_frames_mas_counter_get = useCallback(()=>{
    return {lbl: t('Mas Frm'), value: frame};
  }, [frame, t]);
  let seq_frames_dur_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Dur Frm'), value: monitor.len};
  }, [monitor, t]);
  // XXX vladimir: implement
  let seq_frames_io_counter_get = useCallback(()=>{
    return {lbl: t('I/O Frm'), value: 0};
  }, [t]);
  // XXX vladimir: implement
  let seq_frames_abs_counter_get = useCallback(()=>{
    return {lbl: t('Abs Frm'), value: 0};
  }, [t]);
  let seq_frames_rem_counter_get = useCallback(()=>{
    if (!monitor)
      return null;
    return {lbl: t('Rem Frm'), value: monitor.len - frame};
  }, [frame, monitor, t]);
  let [track_src_segs, track_src_segs_set] = useState(null);
  use_effect_eserf(()=>eserf(function* track_src_segs_get(){
    if (!monitor)
      return;
    let video_tracks = monitor.tracks
      .filter(track=>track.id[0] == 'V')
      .sort((track1, track2)=>{
        let num1 = parseInt(track1.id.slice(1), 10);
        let num2 = parseInt(track2.id.slice(1), 10);
        return num2 - num1;
      });
    let audio_tracks = monitor.tracks
      .filter(track=>track.id[0] == 'A')
      .sort((track1, track2)=>{
        let num1 = parseInt(track1.id.slice(1), 10);
        let num2 = parseInt(track2.id.slice(1), 10);
        return num2 - num1;
      });
    let _track_src_segs = {};
    for (let idx = 0; idx < video_tracks.length; idx++)
    {
      let track = video_tracks[idx];
      _track_src_segs[track.id] = yield player.src_segs_get(token,
        video_tracks.slice(idx));
    }
    for (let idx = 0; idx < audio_tracks.length; idx++)
    {
      let track = audio_tracks[idx];
      _track_src_segs[track.id] = yield player.src_segs_get(token,
        audio_tracks.slice(idx));
    }
    track_src_segs_set(_track_src_segs);
  }), [default_counter, monitor, token]);
  let track_tc_mas_counter_get = useCallback(track=>{
    if (!monitor)
      return null;
    let lbl = `${track.lbl} TC1`;
    if (!track_src_segs)
      return {lbl, value: '00:00:00:00'};
    let src_segs = track_src_segs[track.id];
    if (!src_segs)
      return {lbl, value: '00:00:00:00'};
    let src_seg = src_segs.find(seg=>{
      return player.frame_start(seg) <= frame && frame <= player.frame_end(seg);
    });
    if (!src_seg)
      return {lbl, value: '01:00:00:00'};
    let tc_str = tc.frame2tc(src_seg.start_tc + frame - src_seg.abs_start,
      monitor.editrate);
    return {lbl, value: tc_str};
  }, [frame, monitor, track_src_segs]);
  let counters = useMemo(()=>{
    if (!monitor)
      return {};
    let _counters = {
      // Root -> Sequence -> Timecode -> TC1
      seq_tc_tc1_mas: seq_tc_tc1_mas_counter_get(),
      seq_tc_tc1_dur: seq_tc_tc1_dur_counter_get(),
      seq_tc_tc1_io: seq_tc_tc1_io_counter_get(),
      seq_tc_tc1_abs: seq_tc_tc1_abs_counter_get(),
      seq_tc_tc1_rem: seq_tc_tc1_rem_counter_get(),
      // Root -> Sequence -> Timecode -> 24
      seq_tc_24_mas: seq_tc_24_mas_counter_get(),
      seq_tc_24_dur: seq_tc_24_dur_counter_get(),
      seq_tc_24_io: seq_tc_24_io_counter_get(),
      seq_tc_24_abs: seq_tc_24_abs_counter_get(),
      seq_tc_24_rem: seq_tc_24_rem_counter_get(),
      // Root -> Sequence -> Timecode -> 25
      seq_tc_25_mas: seq_tc_25_mas_counter_get(),
      seq_tc_25_dur: seq_tc_25_dur_counter_get(),
      seq_tc_25_io: seq_tc_25_io_counter_get(),
      seq_tc_25_abs: seq_tc_25_abs_counter_get(),
      seq_tc_25_rem: seq_tc_25_rem_counter_get(),
      // Root -> Sequence -> Frames
      seq_frames_mas: seq_frames_mas_counter_get(),
      seq_frames_dur: seq_frames_dur_counter_get(),
      seq_frames_io: seq_frames_io_counter_get(),
      seq_frames_abs: seq_frames_abs_counter_get(),
      seq_frames_rem: seq_frames_rem_counter_get(),
      // Root
      mas: seq_tc_tc1_mas_counter_get(),
      dur: seq_tc_tc1_dur_counter_get(),
      io: seq_tc_tc1_io_counter_get(),
      abs: seq_tc_tc1_abs_counter_get(),
      rem: seq_tc_tc1_rem_counter_get(),
    };
    for (let track of monitor.tracks)
    {
      if (track.type != 'timeline_track')
        continue;
      _counters[`${track.id.toLowerCase()}_tc1_mas`] =
        track_tc_mas_counter_get(track);
    }
    return _counters;
  }, [seq_frames_abs_counter_get, seq_frames_dur_counter_get,
    seq_frames_io_counter_get, seq_frames_mas_counter_get,
    seq_frames_rem_counter_get, seq_tc_24_dur_counter_get,
    seq_tc_24_mas_counter_get, seq_tc_tc1_abs_counter_get,
    seq_tc_tc1_dur_counter_get, seq_tc_tc1_io_counter_get,
    seq_tc_tc1_mas_counter_get, seq_tc_tc1_rem_counter_get,
    seq_tc_24_io_counter_get, seq_tc_24_abs_counter_get,
    seq_tc_24_rem_counter_get, seq_tc_25_mas_counter_get,
    seq_tc_25_dur_counter_get, seq_tc_25_io_counter_get,
    seq_tc_25_abs_counter_get, seq_tc_25_rem_counter_get,
    monitor, track_tc_mas_counter_get]);
  useEffect(()=>{
    if (!counters[counter])
      counter_set(default_counter);
  }, [counter, counters, default_counter]);
  let select_handle = useCallback(opt=>{
    if (!counters[opt.key])
      return;
    counter_set(opt.key);
  }, [counters]);
  let dropdown_items = useMemo(()=>{
    if (!monitor)
      return [];
    let _dropdown_items = [
      {key: 'seq', label: t('Sequence'),
        children: [
          {key: 'seq_tc', label: t('Timecode'),
            children: [
              {key: 'seq_tc_tc1', label: t('TC1'),
                children: [
                  {key: 'seq_tc_tc1_mas', label: <Meta_item lbl={t('Mas')}
                    value={counters.seq_tc_tc1_mas.value} />},
                  {key: 'seq_tc_tc1_dur', label: <Meta_item lbl={t('Dur')}
                    value={counters.seq_tc_tc1_dur.value} />},
                  {key: 'seq_tc_tc1_io', label: <Meta_item lbl={t('I/O')}
                    value={counters.seq_tc_tc1_io.value} />},
                  {key: 'seq_tc_tc1_abs', label: <Meta_item lbl={t('Abs')}
                    value={counters.seq_tc_tc1_abs.value} />},
                  {key: 'seq_tc_tc1_rem', label: <Meta_item lbl={t('Rem')}
                    value={counters.seq_tc_tc1_rem.value} />},
                ],
              },
              {key: 'seq_tc_24', label: 24,
                children: [
                  {key: 'seq_tc_24_mas', label: <Meta_item lbl={t('Mas')}
                    value={counters.seq_tc_24_mas.value} />},
                  {key: 'seq_tc_24_dur', label: <Meta_item lbl={t('Dur')}
                    value={counters.seq_tc_24_dur.value} />},
                  {key: 'seq_tc_24_io', label: <Meta_item lbl={t('I/O')}
                    value={counters.seq_tc_24_io.value} />},
                  {key: 'seq_tc_24_abs', label: <Meta_item lbl={t('Abs')}
                    value={counters.seq_tc_24_abs.value} />},
                  {key: 'seq_tc_24_rem', label: <Meta_item lbl={t('Rem')}
                    value={counters.seq_tc_24_rem.value} />},
                ],
              },
              {key: 'seq_tc_25', label: 25,
                children: [
                  {key: 'seq_tc_25_mas', label: <Meta_item lbl={t('Mas')}
                    value={counters.seq_tc_25_mas.value} />},
                  {key: 'seq_tc_25_dur', label: <Meta_item lbl={t('Dur')}
                    value={counters.seq_tc_25_dur.value} />},
                  {key: 'seq_tc_25_io', label: <Meta_item lbl={t('I/O')}
                    value={counters.seq_tc_25_io.value} />},
                  {key: 'seq_tc_25_abs', label: <Meta_item lbl={t('Abs')}
                    value={counters.seq_tc_25_abs.value} />},
                  {key: 'seq_tc_25_rem', label: <Meta_item lbl={t('Rem')}
                    value={counters.seq_tc_25_rem.value} />},
                ],
              },
            ],
          },
          {key: 'seq_footage', label: t('Footage')},
          {key: 'seq_frames', label: t('Frames'),
            children: [
              {key: 'seq_frames_mas', label: <Meta_item lbl={t('Mas')}
                value={counters.seq_frames_mas.value} />},
              {key: 'seq_frames_dur', label: <Meta_item lbl={t('Dur')}
                value={counters.seq_frames_dur.value} />},
              {key: 'seq_frames_io', label: <Meta_item lbl={t('I/O')}
                value={counters.seq_frames_io.value} />},
              {key: 'seq_frames_abs', label: <Meta_item lbl={t('Abs')}
                value={counters.seq_frames_abs.value} />},
              {key: 'seq_frames_rem', label: <Meta_item lbl={t('Rem')}
                value={counters.seq_frames_rem.value} />},
            ],
          },
        ],
      },
      {key: 'source', label: t('Source'),
        children: monitor.tracks.map(track=>{
          return {key: `source_${track.id}`, label: track.lbl};
        }),
      },
      {key: 'none', label: t('None')},
      {type: 'divider'},
      {key: 'mas', label: <Meta_item lbl={t('Master')}
        value={counters.seq_tc_tc1_mas.value} />},
      {key: 'dur', label: <Meta_item lbl={t('Duration')}
        value={counters.seq_tc_tc1_dur.value} />},
      {key: 'io', label: <Meta_item lbl={t('In/Out')}
        value={counters.seq_tc_tc1_io.value} />},
      {key: 'abs', label: <Meta_item lbl={t('Absolute')}
        value={counters.seq_tc_tc1_abs.value} />},
      {key: 'rem', label: <Meta_item lbl={t('Remain')}
        value={counters.seq_tc_tc1_rem.value} />},
      {type: 'divider'},
      ...monitor.tracks
        .filter(track=>track.type == 'timeline_track')
        .map(track=>{
          let key = `${track.id.toLowerCase()}_tc1_mas`;
          return {key, label: <Meta_item lbl={`${track.lbl} TC1`}
            value={counters[key].value} />};
        }),
    ];
    return _.cloneDeepWith(_dropdown_items, value=>{
      if (Array.isArray(value))
        return value.filter(item=>!disabled_keys.includes(item.key));
    });
  }, [monitor, t, counters, disabled_keys]);
  return (
    <Meta_info items={dropdown_items} on_select={select_handle}
      style={{minWidth: '200px', height: '22px', fontSize: '11px'}}
      disabled={!monitor}>
      <div style={{width: '100%', display: 'flex',
        justifyContent: 'space-between'}}>
        <span style={{color: 'white'}}>
          {counters[counter]?.lbl}
        </span>
        <span>
          {counters[counter]?.value}
        </span>
      </div>
    </Meta_info>
  );
});
let Composer_panel = React.memo(({lbin, tc_input, fps,
  src_playback_rate_set, rec_playback_rate_set, src_playing_toggle,
  rec_playing_toggle, src_step_backward_1_frame, src_step_forward_1_frame,
  src_step_backward_10_frames, src_step_forward_10_frames,
  rec_step_backward_1_frame, rec_step_forward_1_frame, cmd_selector_set,
  rec_step_backward_10_frames, rec_step_forward_10_frames, go_to_prev_cut,
  go_to_next_cut, drawing_view_box, token,
  is_drawing, drawing_view_box_set, lbin_set,
  drawing_objs, drawing_objs_set, drawing_tool, drawing_color, cmt_svg,
  cmd_mark_in, cmd_mark_out, cmd_cut, cmd_mark_clip, cmd_lift, cmd_extract,
  go_to_mark_in, go_to_mark_out, src_playback_rate, src_frame,
  on_src_frame_change, rec_playback_rate,
  rec_frame, on_rec_frame_change, ...rest})=>{
  let {t} = useTranslation();
  let [focused_monitor, focused_monitor_set] = use_je('editor.focused_monitor',
    'rec');
  let [src_monitor_split,
    src_monitor_split_set] = use_je('editor.src_monitor_split', 'mono');
  let [rec_monitor_split] = use_je('editor.rec_monitor_split', 'mono');
  let [src_selected_monitor] = use_je('editor.src_selected_monitor', null);
  let [rec_selected_monitor] = use_je('editor.rec_selected_monitor', null);
  let [center_duration_mode, center_duration_mode_set] = useState('mas');
  let src_play_btn_colors = useMemo(()=>{
    return play_btn_colors_get(src_playback_rate);
  }, [src_playback_rate]);
  let rec_play_btn_colors = useMemo(()=>{
    return play_btn_colors_get(rec_playback_rate);
  }, [rec_playback_rate]);
  let src_monitor_focus_handle = useCallback(()=>{
    focused_monitor_set('src');
  }, [focused_monitor_set]);
  let rec_monitor_focus_handle = useCallback(()=>{
    focused_monitor_set('rec');
  }, [focused_monitor_set]);
  let center_duration_mode_toggle = useCallback(()=>{
    center_duration_mode_set(center_duration_mode == 'mas' ? 'abs' : 'mas');
  }, [center_duration_mode]);
  let src_quad_split_toggle = useCallback(()=>{
    src_monitor_split_set(src_monitor_split == 'quad' ? 'mono' : 'quad');
  }, [src_monitor_split, src_monitor_split_set]);
  let src_nine_split_toggle = useCallback(()=>{
    src_monitor_split_set(src_monitor_split == 'nine' ? 'mono' : 'nine');
  }, [src_monitor_split, src_monitor_split_set]);
  let center_duration_value = useMemo(()=>{
    if (focused_monitor == 'src' && !lbin?.src_monitor)
      return null;
    if (!lbin?.rec_monitor_in)
      return null;
    let delta;
    if (focused_monitor == 'src')
      delta = src_frame - lbin.src_monitor.curser_pos;
    else if (focused_monitor == 'rec' && lbin.rec_monitor_in.mark_in
      && lbin.rec_monitor_in.mark_out)
    {
      delta = lbin.rec_monitor_in.mark_out - lbin.rec_monitor_in.mark_in;
    }
    else if (focused_monitor == 'rec' && lbin.rec_monitor_in.mark_in)
      delta = rec_frame - lbin.rec_monitor_in.mark_in;
    else if (focused_monitor == 'rec' && lbin.rec_monitor_in.mark_out)
      delta = rec_frame - lbin.rec_monitor_in.mark_out;
    else
      delta = lbin.rec_monitor_in.len - rec_frame;
    if (center_duration_mode == 'abs')
      return delta;
    return tc.frame2tc(delta, lbin.rec_monitor_in.editrate, true, true);
  }, [center_duration_mode, focused_monitor, lbin?.rec_monitor_in,
    lbin?.src_monitor, rec_frame, src_frame]);
  let src_lbin_dropdown_items = useMemo(()=>{
    if (!lbin?.src_monitor?.lbl)
      return [];
    return [
      {key: 'clear_monitor', label: t('Clear Monitor')},
      {key: 'clear_menu', label: t('Clear Menu'), disabled: true},
      {type: 'divider'},
      {key: 'lbin', label: lbin.src_monitor.lbl},
    ];
  }, [lbin?.src_monitor?.lbl, t]);
  let src_lbin_dropdown_select_handle = useCallback(e=>{
    if (!lbin)
      assert(0, 'no lbin');
    if (e.key == 'clear_monitor')
    {
      let _lbin = _.cloneDeep(lbin);
      delete _lbin.src_monitor;
      lbin_set(_lbin);
    }
  }, [lbin, lbin_set]);
  let rec_lbin_dropdown_items = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.lbl)
      return [];
    return [
      {key: 'clear_monitor', label: t('Clear Monitor')},
      {key: 'clear_menu', label: t('Clear Menu'), disabled: true},
      {type: 'divider'},
      {key: 'lbin', label: lbin.rec_monitor_in.lbl},
    ];
  }, [lbin?.rec_monitor_in?.lbl, t]);
  let rec_lbin_dropdown_select_handle = useCallback(e=>{
    if (!lbin)
      assert(0, 'no lbin');
    if (e.key == 'clear_monitor')
    {
      let _lbin = _.cloneDeep(lbin);
      delete _lbin.rec_monitor_in;
      lbin_set(_lbin);
    }
  }, [lbin, lbin_set]);
  let rec_mark_in = useCallback(()=>{
    if (!lbin)
      return;
    cmd_mark_in(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_in, lbin, rec_frame]);
  let rec_mark_out = useCallback(()=>{
    if (!lbin)
      return;
    cmd_mark_out(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_out, lbin, rec_frame]);
  let rec_cut = useCallback(()=>{
    if (!lbin)
      return;
    cmd_cut(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_cut, lbin, rec_frame]);
  let rec_lift = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_in === undefined
      || lbin?.rec_monitor_in?.mark_out === undefined)
    {
      return;
    }
    cmd_lift(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
      lbin.rec_monitor_in.mark_out);
  }, [cmd_lift, lbin]);
  let rec_extract = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_in === undefined
      || lbin?.rec_monitor_in?.mark_out === undefined)
    {
      return;
    }
    cmd_extract(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
      lbin.rec_monitor_in.mark_out);
  }, [cmd_extract, lbin]);
  return (
    <Editor_panel style={{display: 'flex', flexDirection: 'column'}} {...rest}>
      <div style={{display: 'flex'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <div style={{display: 'flex', justifyContent: 'flex-end',
            margin: '2px'}}>
            <Tc_meta_info monitor={lbin?.src_monitor} frame={src_frame}
              token={token} default_counter="seq_tc_tc1_mas"
              disabled_keys={['seq']} />
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between',
            margin: '2px'}}>
            <Meta_info items={src_lbin_dropdown_items}
              style={{minWidth: '200px', height: '22px'}}
              on_select={src_lbin_dropdown_select_handle}
              disabled={!lbin?.src_monitor}>
              {lbin?.src_monitor?.lbl}
            </Meta_info>
            <Tc_meta_info monitor={lbin?.src_monitor} frame={src_frame}
              token={token} default_counter="v1_tc1_mas"
              disabled_keys={['seq']} />
          </div>
        </div>
        <div style={{margin: '2px'}}>
          <Meta_info style={{minWidth: '100px', height: '100%',
            cursor: 'pointer'}} onClick={center_duration_mode_toggle}
          title={t('Center Duration')}>
            {center_duration_value}
          </Meta_info>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <div style={{display: 'flex', margin: '2px'}}>
            <Tc_meta_info monitor={lbin?.rec_monitor_in} frame={rec_frame}
              token={token} default_counter="seq_tc_tc1_mas" />
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between',
            margin: '2px'}}>
            <Tc_meta_info monitor={lbin?.rec_monitor_in} frame={rec_frame}
              token={token} default_counter="v1_tc1_mas" />
            <Meta_info items={rec_lbin_dropdown_items}
              style={{minWidth: '200px', height: '22px'}}
              on_select={rec_lbin_dropdown_select_handle}
              disabled={!lbin?.rec_monitor_in}>
              {lbin?.rec_monitor_in?.lbl}
            </Meta_info>
          </div>
        </div>
      </div>
      <div style={{display: 'flex', width: '100%', height: '100%'}}>
        <Monitor monitor={lbin?.src_monitor} token={token}
          selected_monitor={src_selected_monitor}
          playback_rate={src_playback_rate} split={src_monitor_split}
          on_playback_rate_change={src_playback_rate_set}
          frame={src_frame} rec_frame={rec_frame}
          on_frame_change={on_src_frame_change}
          is_focused={focused_monitor == 'src'}
          cmd_selector_set={cmd_selector_set}
          trim_delta_pos="right" on_focus={src_monitor_focus_handle}
          side="src" trim_counter_tooltip={t('A-Side Trim Counter')}
          toolbar={[
            [
              <Editor_btn key="go_to_prev_cut" disabled
                title={t('Go to Previous Event')}>
                <Go_to_prev_event_icon />
              </Editor_btn>,
              <Editor_btn key="go_to_next_cut" title={t('Go to Next Event')}
                disabled>
                <Go_to_next_event_icon />
              </Editor_btn>,
              <Editor_btn key="step_backward"
                title={t('Step Backward 1 Fram')}
                onClick={src_step_backward_1_frame}>
                <Step_backward_1_frame_icon />
              </Editor_btn>,
              <Editor_btn key="step_forward"
                title={t('Step Forward 1 Frame')}
                onClick={src_step_forward_1_frame}>
                <Step_forward_1_frame_icon />
              </Editor_btn>,
              <Editor_btn key="mark_in" title={t('Mark IN')} disabled>
                <Mark_in_icon />
              </Editor_btn>,
              <Editor_btn key="play" key_bind={action2key_bind.play_stop}
                style={{gridColumn: 'span 2', fontSize: '12px',
                  color: src_playback_rate == 1 ? green.primary : 'black'}}
                bg={src_play_btn_colors.bg}
                hover_bg={src_play_btn_colors.hover_bg}
                active_bg={src_play_btn_colors.active_bg}
                title={t('Play')} onClick={src_playing_toggle}>
                {play_btn_content_get(src_playback_rate, fps)}
              </Editor_btn>,
              <Editor_btn key="mark_out" title={t('Mark OUT')} disabled>
                <Mark_out_icon />
              </Editor_btn>,
              <Editor_btn key="mark_clip" title={t('Mark Clip')} disabled>
                <Mark_clip_icon />
              </Editor_btn>,
              <Editor_btn key="clear_both_marks"
                title={t('Clear Both Marks')} disabled>
                <Clear_both_marks_icon />
              </Editor_btn>,
              <Editor_btn key="add_marker" title={t('Add Marker')} disabled>
                <Marker_icon />
              </Editor_btn>,
              <Editor_btn key="swap_cam_bank" title={t('Swap Cam Bank')}
                disabled>
                <Swap_cam_bank_icon />
              </Editor_btn>,
            ],
            [
              <Editor_btn key="find_bin" title={t('Find Bin')} disabled>
                <Find_bin_icon />
              </Editor_btn>,
              <Editor_btn key="gang" title={t('Gang')} disabled>
                <Gang_icon />
              </Editor_btn>,
              <Editor_btn key="step_backward_10_frames"
                title={t('Step backward 10 frames')}
                onClick={src_step_backward_10_frames}>
                <Step_backward_10_frames_icon />
              </Editor_btn>,
              <Editor_btn key="step_forward_10_frames"
                title={t('Step forward 10 frames')}
                onClick={src_step_forward_10_frames}>
                <Step_forward_10_frames_icon />
              </Editor_btn>,
              <Editor_btn key="go_to_in" title={t('Go to IN')} disabled>
                <Go_to_in_icon />
              </Editor_btn>,
              <Editor_btn key="play_length_toggle" disabled
                style={{gridColumn: 'span 2'}}
                title={t('Play Length Toggle')}>
                <Play_length_toggle_icon />
              </Editor_btn>,
              <Editor_btn key="go_to_out" title={t('Go to OUT')} disabled>
                <Go_to_out_icon />
              </Editor_btn>,
              <Editor_btn key="match_frame" title={t('Match Frame')}
                disabled>
                <Match_frame_icon />
              </Editor_btn>,
              <Editor_btn key="reverse_match_frame" disabled
                title={t('Reverse Match Frame')}>
                <Reverse_match_frame_icon />
              </Editor_btn>,
              <Editor_btn key="quad_split" title={t('Quad Split')}
                key_bind={action2key_bind.quad_split}
                onClick={src_quad_split_toggle}>
                <Quad_split_icon />
              </Editor_btn>,
              <Editor_btn key="nine_split" title={t('Nine Split')}
                key_bind={action2key_bind.nine_split}
                onClick={src_nine_split_toggle}>
                <Nine_split_icon />
              </Editor_btn>,
            ],
          ]}
          ptr_color={theme.green} />
        <Monitor monitor={lbin?.rec_monitor_in} token={token}
          selected_monitor={rec_selected_monitor}
          cmt_svg={cmt_svg} playback_rate={rec_playback_rate}
          on_playback_rate_change={rec_playback_rate_set}
          frame={rec_frame} split={rec_monitor_split}
          on_frame_change={on_rec_frame_change}
          is_focused={focused_monitor == 'rec'}
          is_drawing={is_drawing} drawing_view_box_set={drawing_view_box_set}
          drawing_objs={drawing_objs} drawing_objs_set={drawing_objs_set}
          drawing_tool={drawing_tool} drawing_color={drawing_color}
          drawing_view_box={drawing_view_box}
          cmd_selector_set={cmd_selector_set}
          on_focus={rec_monitor_focus_handle}
          trim_delta_pos="left" rec_frame={rec_frame}
          side="rec" trim_counter_tooltip={t('B-Side Trim Counter')}
          toolbar={[
            [
              <Editor_btn key="go_to_prev_cut"
                key_bind={action2key_bind.go_to_prev_cut}
                title={t('Go to Previous Event')} onClick={go_to_prev_cut}>
                <Go_to_prev_event_icon />
              </Editor_btn>,
              <Editor_btn key="go_to_next_cut" title={t('Go to Next Event')}
                key_bind={action2key_bind.go_to_next_cut}
                onClick={go_to_next_cut}>
                <Go_to_next_event_icon />
              </Editor_btn>,
              <Editor_btn key="step_backward"
                title={t('Step Backward 1 Frame')}
                onClick={rec_step_backward_1_frame}>
                <Step_backward_1_frame_icon />
              </Editor_btn>,
              <Editor_btn key="step_forward"
                title={t('Step Forward 1 Frame')}
                onClick={rec_step_forward_1_frame}>
                <Step_forward_1_frame_icon />
              </Editor_btn>,
              <Editor_btn key="mark_in" title={t('Mark IN')}
                key_bind={action2key_bind.mark_in} onClick={rec_mark_in}>
                <Mark_in_icon />
              </Editor_btn>,
              <Editor_btn key="play" key_bind={action2key_bind.play_stop}
                style={{gridColumn: 'span 2', fontSize: '12px',
                  color: rec_playback_rate == 1 ? green.primary : 'black'}}
                bg={rec_play_btn_colors.bg}
                hover_bg={rec_play_btn_colors.hover_bg}
                active_bg={rec_play_btn_colors.active_bg} title={t('Play')}
                onClick={rec_playing_toggle}>
                {play_btn_content_get(rec_playback_rate, fps)}
              </Editor_btn>,
              <Editor_btn key="mark_out" title={t('Mark OUT')}
                key_bind={action2key_bind.mark_out} onClick={rec_mark_out}>
                <Mark_out_icon />
              </Editor_btn>,
              <Editor_btn key="mark_clip" title={t('Mark Clip')}
                key_bind={action2key_bind.mark_clip} onClick={cmd_mark_clip}>
                <Mark_clip_icon />
              </Editor_btn>,
              <Editor_btn key="add_edit" title={t('Add Edit')}
                key_bind={action2key_bind.cut} onClick={rec_cut}>
                <Add_edit_icon />
              </Editor_btn>,
              <Editor_btn key="lift" title={t('Lift')} onClick={rec_lift}
                key_bind={action2key_bind.lift}>
                <Lift_icon />
              </Editor_btn>,
              <Editor_btn key="extract" title={t('Extract')}
                onClick={rec_extract} key_bind={action2key_bind.extract}>
                <Extract_icon />
              </Editor_btn>,
            ],
            [
              <Editor_btn key="find_bin" title={t('Find Bin')} disabled>
                <Find_bin_icon />
              </Editor_btn>,
              <Editor_btn key="gang" title={t('Gang')} disabled>
                <Gang_icon />
              </Editor_btn>,
              <Editor_btn key="step_backward_10_frames"
                title={t('Step backward 10 frames')}
                onClick={rec_step_backward_10_frames}>
                <Step_backward_10_frames_icon />
              </Editor_btn>,
              <Editor_btn key="step_forward_10_frames"
                title={t('Step forward 10 frames')}
                onClick={rec_step_forward_10_frames}>
                <Step_forward_10_frames_icon />
              </Editor_btn>,
              <Editor_btn key="go_to_in" title={t('Go to IN')}
                onClick={go_to_mark_in}
                key_bind={action2key_bind.go_to_mark_in}>
                <Go_to_in_icon />
              </Editor_btn>,
              <Editor_btn key="play_length_toggle" disabled
                style={{gridColumn: 'span 2'}}
                title={t('Play Length Toggle')}>
                <Play_length_toggle_icon />
              </Editor_btn>,
              <Editor_btn key="go_to_out" title={t('Go to OUT')}
                onClick={go_to_mark_out}
                key_bind={action2key_bind.go_to_mark_out}>
                <Go_to_out_icon />
              </Editor_btn>,
              <Editor_btn key="quick_transition" disabled
                title={t('Quick Transition')}>
                <Quick_transition_icon />
              </Editor_btn>,
              <Editor_btn key="remove_effect" title={t('Remove Effect')}
                disabled>
                <Remove_effect_icon />
              </Editor_btn>,
              <Editor_btn key="collapse" title={t('Collapse')} disabled>
                <Collapse_icon />
              </Editor_btn>,
              <Editor_btn key="video_quality_menu" disabled
                title={t('Video Quality Menu')}>
                <Video_quality_menu_icon />
              </Editor_btn>,
            ],
          ]}
          ptr_color={theme.light_blue} tc_input={tc_input} />
      </div>
    </Editor_panel>
  );
});
let Audio_meter = React.memo(()=>{
  let location = useLocation();
  let container_ref = useRef(null);
  let qs_o = useMemo(()=>{
    return xurl.qs_parse(location.search, true);
  }, [location.search]);
  let is_audio_meter_dbg = useMemo(()=>qs_o.audio_meter, [qs_o.audio_meter]);
  useEffect(()=>{
    if (!is_audio_meter_dbg)
      return;
    let meter_container = container_ref.current;
    console.log('meter_container: ', meter_container);
  }, [is_audio_meter_dbg]);
  return (
    <Meta_info style={{width: '200px', height: '30px'}}>
      <div style={{width: '100%'}} ref={container_ref} />
    </Meta_info>
  );
});
let Timeline_upper_toolbar = React.memo(({cmd_mark_in, cmd_mark_out,
  cmd_cut, cmd_mark_clip, cmd_clear_both_marks, cmd_lift, select_to_the_left,
  select_to_the_right, select_in_out, cmd_extract, toggle_selection_tool,
  toggle_insertion_tool, lbin, src_frame, rec_frame})=>{
  let {t} = useTranslation();
  let [timeline_mode] = use_je('editor.timeline_mode', 'rec');
  let [rec_monitor_split,
    rec_monitor_split_set] = use_je('editor.rec_monitor_split', 'mono');
  let rec_quad_split_toggle = useCallback(()=>{
    rec_monitor_split_set(rec_monitor_split == 'quad' ? 'mono' : 'quad');
  }, [rec_monitor_split, rec_monitor_split_set]);
  let rec_nine_split_toggle = useCallback(()=>{
    rec_monitor_split_set(rec_monitor_split == 'nine' ? 'mono' : 'nine');
  }, [rec_monitor_split, rec_monitor_split_set]);
  let mark_in = useCallback(()=>{
    if (!lbin)
      return;
    if (timeline_mode == 'src' && lbin.src_monitor)
      return cmd_mark_in(lbin.src_monitor.mob_id, src_frame);
    if (timeline_mode == 'rec' && lbin.rec_monitor_in)
      return cmd_mark_in(lbin.rec_monitor_in.mob_id, rec_frame);
    assert(0, 'unexpected timeline_mode: ' + timeline_mode);
  }, [cmd_mark_in, lbin, rec_frame, src_frame, timeline_mode]);
  let mark_out = useCallback(()=>{
    if (!lbin)
      return;
    if (timeline_mode == 'src' && lbin.src_monitor)
      return cmd_mark_out(lbin.src_monitor.mob_id, src_frame);
    if (timeline_mode == 'rec' && lbin.rec_monitor_in)
      return cmd_mark_out(lbin.rec_monitor_in.mob_id, rec_frame);
    assert(0, 'unexpected timeline_mode: ' + timeline_mode);
  }, [cmd_mark_out, lbin, rec_frame, src_frame, timeline_mode]);
  let mark_clip = useCallback(()=>{
    if (!lbin)
      return;
    if (timeline_mode == 'src' && lbin.src_monitor)
      return cmd_mark_clip(lbin.src_monitor.mob_id, src_frame);
    if (timeline_mode == 'rec' && lbin.rec_monitor_in)
      return cmd_mark_clip(lbin.rec_monitor_in.mob_id, rec_frame);
    assert(0, 'unexpected timeline_mode: ' + timeline_mode);
  }, [cmd_mark_clip, lbin, rec_frame, src_frame, timeline_mode]);
  let clear_both_marks = useCallback(()=>{
    if (!lbin)
      return;
    let mob_id;
    if (timeline_mode == 'src')
      mob_id = lbin.src_monitor.mob_id;
    else if (timeline_mode == 'rec')
      mob_id = lbin.rec_monitor_in.mob_id;
    else
      assert(0, 'unexpected timeline_mode: ' + timeline_mode);
    cmd_clear_both_marks(mob_id);
  }, [cmd_clear_both_marks, lbin, timeline_mode]);
  let cut = useCallback(()=>{
    if (!lbin)
      return;
    if (timeline_mode == 'src' && lbin.src_monitor)
      return cmd_cut(lbin.src_monitor.mob_id, src_frame);
    if (timeline_mode == 'rec' && lbin.rec_monitor_in)
      return cmd_cut(lbin.rec_monitor_in.mob_id, rec_frame);
    assert(0, 'unexpected timeline_mode: ' + timeline_mode);
  }, [cmd_cut, lbin, rec_frame, src_frame, timeline_mode]);
  let lift = useCallback(()=>{
    if (timeline_mode == 'src')
    {
      if (lbin?.src_monitor?.mark_in === undefined
        || lbin?.src_monitor?.mark_out === undefined)
      {
        return;
      }
      cmd_lift(lbin.src_monitor.mob_id, lbin.src_monitor.mark_in,
        lbin.src_monitor.mark_out);
    }
    else if (timeline_mode == 'rec')
    {
      if (lbin?.rec_monitor_in?.mark_in === undefined
        || lbin?.rec_monitor_in?.mark_out === undefined)
      {
        return;
      }
      cmd_lift(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
        lbin.rec_monitor_in.mark_out);
    }
  }, [cmd_lift, lbin, timeline_mode]);
  let extract = useCallback(()=>{
    if (timeline_mode == 'src')
    {
      if (lbin?.src_monitor?.mark_in === undefined
        || lbin?.src_monitor?.mark_out === undefined)
      {
        return;
      }
      cmd_extract(lbin.src_monitor.mob_id, lbin.src_monitor.mark_in,
        lbin.src_monitor.mark_out);
    }
    else if (timeline_mode == 'rec')
    {
      if (lbin?.rec_monitor_in?.mark_in === undefined
        || lbin?.rec_monitor_in?.mark_out === undefined)
      {
        return;
      }
      cmd_extract(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
        lbin.rec_monitor_in.mark_out);
    }
  }, [cmd_extract, lbin, timeline_mode]);
  return (
    <div style={{display: 'flex', gap: '2px', padding: '4px 0'}}>
      <Editor_btn_group>
        <Editor_btn title={t('Monitor Volume')} disabled>
          <Monitor_volume_icon />
        </Editor_btn>
        <Audio_meter />
        <Editor_btn title={t('Meter Menu')} disabled>
          <Meter_menu_icon />
        </Editor_btn>
      </Editor_btn_group>
      <Editor_btn_group>
        <Editor_btn title={t('Effect Mode')} disabled>
          <Effect_mode_icon />
        </Editor_btn>
        <Editor_btn title={t('Trim Mode')} disabled>
          <Trim_mode_icon />
        </Editor_btn>
        <Editor_btn title={t('Step In')} disabled>
          <Step_in_icon />
        </Editor_btn>
        <Editor_btn title={t('Step Out')} disabled>
          <Step_out_icon />
        </Editor_btn>
      </Editor_btn_group>
      <Editor_btn_group>
        <Editor_btn title={t('Quick Transition')} disabled>
          <Quick_transition_icon />
        </Editor_btn>
        <Editor_btn title={t('Render Effect')} disabled>
          <Render_effect_icon />
        </Editor_btn>
        <Editor_btn title={t('Collapse')} disabled>
          <Collapse_icon />
        </Editor_btn>
        <Editor_btn title={t('Remove Effect')} disabled>
          <Remove_effect_icon />
        </Editor_btn>
      </Editor_btn_group>
      <Editor_btn_group>
        <Editor_btn key_bind={action2key_bind.mark_in} title={t('Mark IN')}
          onClick={mark_in}>
          <Mark_in_icon />
        </Editor_btn>
        <Editor_btn key_bind={action2key_bind.mark_out} title={t('Mark OUT')}
          onClick={mark_out}>
          <Mark_out_icon />
        </Editor_btn>
        <Editor_btn key_bind={action2key_bind.mark_clip}
          title={t('Mark Clip')} onClick={mark_clip}>
          <Mark_clip_icon />
        </Editor_btn>
        <Editor_btn key_bind={action2key_bind.clear_both_marks}
          title={t('Clear Both Marks')} onClick={clear_both_marks}>
          <Clear_both_marks_icon />
        </Editor_btn>
      </Editor_btn_group>
      <Editor_btn_group>
        <Editor_btn title={t('Tool Palette')} disabled>
          <Tool_palette_icon />
        </Editor_btn>
        <Editor_btn key_bind={action2key_bind.cut} title={t('Add Edit')}
          onClick={cut}>
          <Add_edit_icon />
        </Editor_btn>
        <Editor_btn key_bind={action2key_bind.extract} title={t('Extract')}
          onClick={extract}>
          <Extract_icon />
        </Editor_btn>
        <Editor_btn key_bind={action2key_bind.lift} title={t('Lift')}
          onClick={lift}>
          <Lift_icon />
        </Editor_btn>
      </Editor_btn_group>
      <Editor_btn_group>
        <Editor_btn title={t('Head Fade')} disabled>
          <Head_fade_icon />
        </Editor_btn>
        <Editor_btn title={t('Tall Fade')} disabled>
          <Tall_fade_icon />
        </Editor_btn>
        <Editor_btn title={t('To the Left')} onClick={select_to_the_left}>
          <To_the_left_icon />
        </Editor_btn>
        <Editor_btn title={t('To the Right')} onClick={select_to_the_right}>
          <To_the_right_icon />
        </Editor_btn>
      </Editor_btn_group>
      <Editor_btn_group style={{width: '120px'}}>
        <Editor_btn title={t('Select In/Out')} onClick={select_in_out}>
          <Select_in_out_icon />
        </Editor_btn>
        <Editor_btn title={t('Link Selection Toggle')} disabled>
          <Link_selection_toggle_icon />
        </Editor_btn>
        <Editor_btn title={t('Segment Mode (Lift/Overwrite)')}
          onClick={toggle_selection_tool}
          key_bind={action2key_bind.toggle_selection_tool}>
          <Select_icon />
        </Editor_btn>
        <Editor_btn title={t('Segment Mode (Extract/Splice-In)')}
          onClick={toggle_insertion_tool}
          key_bind={action2key_bind.toggle_insertion_tool}>
          <Select_insert_icon />
        </Editor_btn>
      </Editor_btn_group>
      <Editor_btn_group style={{width: '120px'}}>
        <Editor_btn title={t('HW/SW')} disabled>
          <Hw_sw_icon />
        </Editor_btn>
        <Editor_btn title={t('Titler Pro')} disabled>
          <Titler_pro_icon />
        </Editor_btn>
      </Editor_btn_group>
      <Editor_btn_group style={{width: '60px'}}>
        <Editor_btn key="quad_split" title={t('Quad Split')}
          key_bind={action2key_bind.quad_split} onClick={rec_quad_split_toggle}>
          <Quad_split_icon />
        </Editor_btn>
        <Editor_btn key="nine_split" title={t('Nine Split')}
          key_bind={action2key_bind.nine_split} onClick={rec_nine_split_toggle}>
          <Nine_split_icon />
        </Editor_btn>
      </Editor_btn_group>
      <Editor_btn_group>
        <Editor_btn title={t('HW/SW')} disabled>
          <Hw_sw_icon />
        </Editor_btn>
      </Editor_btn_group>
    </div>
  );
});
let Pos_tc = React.memo(({start_tc, frame, playback_rate, editrate})=>{
  let location = useLocation();
  let qs_o = useMemo(()=>{
    return xurl.qs_parse(location.search, true);
  }, [location.search]);
  let show_frames = useMemo(()=>qs_o.show_frames, [qs_o.show_frames]);
  let value = useMemo(()=>{
    if (!start_tc || !editrate)
      return '00:00:00:00';
    return tc.frame2tc(start_tc + frame, editrate, !playback_rate);
  }, [editrate, frame, playback_rate, start_tc]);
  return (
    <Meta_info style={{width: '100%', height: '26px'}}>
      {value}
      {show_frames && ` (${frame})`}
    </Meta_info>
  );
});
let Track_action_solo = React.memo(({color, ...rest})=>{
  return (
    <div style={{width: '18px', color: 'black',
      background: color, display: 'flex',
      flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      border: `1px solid ${theme.gray1}`,
      fontSize: '10px', cursor: 'pointer'}} {...rest}>
      S
    </div>
  );
});
let Track_action_mute = React.memo(({color, ...rest})=>{
  return (
    <div style={{width: '18px', background: color, display: 'flex',
      flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
      border: `1px solid ${theme.gray1}`, color: 'black',
      fontSize: '10px', cursor: 'pointer'}} {...rest}>
      M
    </div>
  );
});
let Track_action_monitor = React.memo(({selected_monitor, track_id, ...rest})=>{
  let bg_color = useMemo(()=>{
    if (!selected_monitor)
      return theme.gray7;
    if (selected_monitor.track_id == track_id && selected_monitor.is_solo)
      return theme.green;
    if (selected_monitor.track_id == track_id)
      return theme.dark_blue;
    return theme.gray7;
  }, [selected_monitor, track_id]);
  return (
    <div style={{width: '36px', background: bg_color, display: 'flex',
      flexDirection: 'column', justifyContent: 'center',
      alignItems: 'center', cursor: 'pointer',
      border: `1px solid ${theme.gray1}`}} {...rest}>
      <Icon src={monitor_icon} />
    </div>
  );
});
let Track_action_lbl = React.memo(({lbl, is_editing, is_nested, ...rest})=>{
  let bg_color = useMemo(()=>{
    if (is_editing)
      return theme.dark_blue;
    return gray[5];
  }, [is_editing]);
  let track_lbl = useMemo(()=>{
    let _track_lbl = is_nested ? lbl.slice(1) : lbl;
    if (_track_lbl.length > 3)
      _track_lbl = _track_lbl.slice(0, 3) + '...';
    return _track_lbl;
  }, [is_nested, lbl]);
  return (
    <div style={{background: bg_color, width: '42px', display: 'flex',
      justifyContent: 'center', alignItems: 'center', gap: '2px',
      border: `1px solid ${theme.gray1}`, fontSize: '11px', cursor: 'pointer',
      userSelect: 'none', color: 'black', position: 'relative',
      padding: '0 2px'}} title={lbl} {...rest}>
      {is_nested && <CaretDownOutlined />}
      {track_lbl}
    </div>
  );
});
let Track_action_synclock = React.memo(({is_synclock, ...rest})=>{
  let bg_color = useMemo(()=>{
    if (is_synclock)
      return theme.dark_blue;
    return gray[5];
  }, [is_synclock]);
  return (
    <div style={{width: '18px', background: bg_color,
      display: 'flex', flexDirection: 'column', justifyContent: 'center',
      alignItems: 'center', cursor: 'pointer',
      border: `1px solid ${theme.gray1}`}} {...rest}>
      <Antd_icon component={Synclock_icon} style={{fontSize: '10px'}} />
    </div>
  );
});
let Track_action_waveform = React.memo(({is_waveform, ...rest})=>{
  let bg_color = useMemo(()=>{
    if (is_waveform)
      return theme.dark_blue;
    return gray[5];
  }, [is_waveform]);
  return (
    <div style={{height: 'calc(100% + 2px)', background: bg_color,
      width: '27px', marginTop: '-1px', display: 'flex',
      flexDirection: 'column', justifyContent: 'center',
      alignItems: 'center', border: `1px solid ${theme.gray1}`,
      borderRight: 'none', cursor: 'pointer'}} {...rest}>
      <Antd_icon component={Waveform_icon} style={{fontSize: '12px'}} />
    </div>
  );
});
let Track_action_power = React.memo(({color, ...rest})=>{
  return (
    <div style={{height: 'calc(100% + 2px)',
      background: color, width: '27px', marginTop: '-1px',
      display: 'flex', flexDirection: 'column',
      justifyContent: 'center', cursor: 'pointer', alignItems: 'center',
      borderRight: 'none', border: `1px solid ${theme.gray1}`}} {...rest}>
      <Antd_icon component={Power_icon} style={{fontSize: '10px'}} />
    </div>
  );
});
let Track_actions = React.memo(({lbin, rec_track, is_show_ctrl, ctx_menu_handle,
  cmd_power, cmd_solo, cmd_mute, cmd_synclock, src_track, is_nested,
  visible_tracks})=>{
  let [timeline_mode] = use_je('editor.timeline_mode', 'rec');
  let [rec_editing_track_ids, rec_editing_track_ids_set] = use_je(
    'editor.rec_editing_track_ids', []);
  let [src_editing_track_ids, src_editing_track_ids_set] = use_je(
    'editor.src_editing_track_ids', []);
  let [rec_selected_monitor, rec_selected_monitor_set] = use_je(
    'editor.rec_selected_monitor', null);
  let [src_selected_monitor, src_selected_monitor_set] = use_je(
    'editor.src_selected_monitor', null);
  let [drag_mode, drag_mode_set] = use_je('editor.drag_mode', null);
  let [tracks_mark_sliding, tracks_mark_sliding_set] = use_je(
    'editor.tracks_mark_sliding', null);
  let rec_is_editing = useMemo(()=>{
    if (!rec_track)
      return false;
    return rec_editing_track_ids.includes(rec_track.id);
  }, [rec_editing_track_ids, rec_track]);
  let src_is_editing = useMemo(()=>{
    if (!src_track)
      return false;
    return src_editing_track_ids.includes(src_track.id);
  }, [src_editing_track_ids, src_track]);
  let is_no_fill = useMemo(()=>{
    if (!rec_track)
      return false;
    return rec_track.height.endsWith('no_fill');
  }, [rec_track]);
  let height = useMemo(()=>{
    if (rec_track)
      return track_height2px(rec_track.height);
    if (src_track)
      return track_height2px(src_track.height);
    return 0;
  }, [src_track, rec_track]);
  let rec_toggle_monitor = useCallback(e=>{
    // ignore if not left click
    if (e.nativeEvent.which != 1)
      return;
    if (rec_selected_monitor?.track_id == rec_track.id)
      return rec_selected_monitor_set(null);
    rec_selected_monitor_set({track_id: rec_track.id, is_solo: e.ctrlKey});
  }, [rec_selected_monitor?.track_id, rec_selected_monitor_set, rec_track]);
  let src_toggle_monitor = useCallback(e=>{
    // ignore if not left click
    if (e.nativeEvent.which != 1)
      return;
    if (src_selected_monitor?.track_id == rec_track.id)
      return src_selected_monitor_set(null);
    src_selected_monitor_set({track_id: rec_track.id, is_solo: e.ctrlKey});
  }, [src_selected_monitor?.track_id, src_selected_monitor_set, rec_track]);
  let src_mouse_enter_handle = useCallback(()=>{
    if (!tracks_mark_sliding || !src_track)
      return;
    if (tracks_mark_sliding.is_editing
      && !src_editing_track_ids.includes(src_track.id))
    {
      return src_editing_track_ids_set([...src_editing_track_ids,
        src_track.id]);
    }
    if (!tracks_mark_sliding.is_editing
      && src_editing_track_ids.includes(src_track.id))
    {
      let _src_editing_track_ids = [...src_editing_track_ids];
      let idx = _src_editing_track_ids.indexOf(src_track.id);
      _src_editing_track_ids.splice(idx, 1);
      src_editing_track_ids_set(_src_editing_track_ids);
    }
  }, [src_editing_track_ids, src_editing_track_ids_set, tracks_mark_sliding,
    src_track]);
  let rec_track_edit = useCallback(track_id=>{
    if (!track_id)
      assert(0, 'no track_id');
    if (rec_editing_track_ids.includes(track_id))
      return;
    let track = visible_tracks.find(_track=>{
      return _track.id == track_id;
    });
    if (!track)
      assert(0, 'track is not found');
    let _rec_editing_track_ids = [...rec_editing_track_ids];
    _rec_editing_track_ids.push(track_id);
    _rec_editing_track_ids = _rec_editing_track_ids.filter(_track_id=>{
      let _track = visible_tracks.find(({id})=>{
        return id == _track_id;
      });
      return _track.is_nested == track.is_nested;
    });
    rec_editing_track_ids_set(_rec_editing_track_ids);
  }, [rec_editing_track_ids, rec_editing_track_ids_set, visible_tracks]);
  let rec_track_unedit = useCallback(track_id=>{
    if (!track_id)
      assert(0, 'no track_id');
    if (!rec_editing_track_ids.includes(track_id))
      return;
    let _rec_editing_track_ids = rec_editing_track_ids.filter(_track_id=>{
      return _track_id != track_id;
    });
    rec_editing_track_ids_set(_rec_editing_track_ids);
  }, [rec_editing_track_ids, rec_editing_track_ids_set]);
  let rec_mouse_enter_handle = useCallback(()=>{
    if (!tracks_mark_sliding || !rec_track)
      return;
    if (tracks_mark_sliding.is_editing
      && !rec_editing_track_ids.includes(rec_track.id))
    {
      return rec_track_edit(rec_track.id);
    }
    if (!tracks_mark_sliding.is_editing
      && rec_editing_track_ids.includes(rec_track.id))
    {
      return rec_track_unedit(rec_track.id);
    }
  }, [tracks_mark_sliding, rec_track, rec_editing_track_ids, rec_track_edit,
    rec_track_unedit]);
  let mouse_up_handle = useCallback(()=>{
    tracks_mark_sliding_set(null);
  }, [tracks_mark_sliding_set]);
  let src_edit_toggle_handle = useCallback(e=>{
    if (!src_track)
      return;
    // ignore if not left click
    if (e.nativeEvent.which != 1)
      return;
    let is_editing = src_editing_track_ids.includes(src_track.id);
    if (e.shiftKey)
      tracks_mark_sliding_set({is_editing: !is_editing});
    if (!is_editing)
    {
      return src_editing_track_ids_set([...src_editing_track_ids,
        src_track.id]);
    }
    let _src_editing_track_ids = [...src_editing_track_ids];
    let idx = _src_editing_track_ids.indexOf(src_track.id);
    _src_editing_track_ids.splice(idx, 1);
    src_editing_track_ids_set(_src_editing_track_ids);
  }, [src_editing_track_ids, src_editing_track_ids_set, src_track,
    tracks_mark_sliding_set]);
  let rec_edit_toggle_handle = useCallback(e=>{
    if (!rec_track)
      return;
    // ignore if not left click
    if (e.nativeEvent.which != 1)
      return;
    let is_editing = rec_editing_track_ids.includes(rec_track.id);
    if (e.shiftKey)
      tracks_mark_sliding_set({is_editing: !is_editing});
    if (!is_editing)
      return rec_track_edit(rec_track.id);
    rec_track_unedit(rec_track.id);
    if (!drag_mode)
      return;
    let abs_starts = player.abs_starts_get(rec_track);
    let selected_segs = new Map(drag_mode.segs);
    for (let seg of abs_starts.keys())
      selected_segs.delete(seg);
    drag_mode_set({...drag_mode, segs: selected_segs});
  }, [rec_editing_track_ids, rec_track_unedit, drag_mode, drag_mode_set,
    rec_track, tracks_mark_sliding_set, rec_track_edit]);
  let synclock_handle = useCallback(()=>{
    if (!rec_track || rec_track.id != cmt_track_id)
      return;
    cmd_synclock(lbin.rec_monitor_in.mob_id, rec_track.id,
      !rec_track.is_synclock);
  }, [cmd_synclock, rec_track, lbin]);
  let power_handle = useCallback(()=>{
    if (!rec_track)
      return;
    cmd_power(lbin.rec_monitor_in.mob_id, rec_track.id, !rec_track.power.v);
  }, [lbin, cmd_power, rec_track]);
  let solo_handle = useCallback(e=>{
    let is_solo = !rec_track.solo.v;
    let track_ids;
    if (e.altKey)
    {
      track_ids = lbin.rec_monitor_in.tracks
        .filter(track=>track.id[0] == 'A' && track.solo.v != is_solo)
        .map(track=>track.id);
    }
    else
      track_ids = [rec_track.id];
    cmd_solo(lbin.rec_monitor_in.mob_id, track_ids, is_solo);
  }, [cmd_solo, rec_track, lbin]);
  let mute_handle = useCallback(e=>{
    let is_mute = rec_track.mute.v == !!rec_track.mute.is_solo;
    let track_ids;
    if (e.altKey)
    {
      track_ids = lbin.rec_monitor_in.tracks
        .filter(track=>track.id[0] == 'A' && track.mute.v != is_mute)
        .map(track=>track.id);
    }
    else
      track_ids = [rec_track.id];
    cmd_mute(lbin.rec_monitor_in.mob_id, track_ids, is_mute);
  }, [cmd_mute, rec_track, lbin]);
  useEffect(()=>{
    if (!tracks_mark_sliding)
      return;
    window.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      window.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [mouse_up_handle, tracks_mark_sliding]);
  if (timeline_mode == 'src')
  {
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start',
        height: `${height}px`, margin: is_no_fill && '3px 0',
        position: 'relative', width: '100%'}}
      onContextMenu={e=>ctx_menu_handle(e, rec_track)}>
        {!rec_track?.ctrl.has_monitor && !rec_track?.ctrl.has_solo
          && !rec_track?.ctrl.has_mute && <div style={{width: '36px',
          border: `1px solid ${theme.gray1}`}} />}
        {rec_track?.ctrl.has_solo && <Track_action_solo
          color={rec_track.solo.color} onMouseDown={solo_handle} />}
        {rec_track?.ctrl.has_mute && <Track_action_mute
          color={rec_track.mute.color} onMouseDown={mute_handle} />}
        {rec_track?.ctrl.has_monitor && <Track_action_monitor
          selected_monitor={rec_selected_monitor} track_id={rec_track.id}
          onMouseDown={rec_toggle_monitor} />}
        {rec_track && <Track_action_lbl lbl={rec_track.lbl}
          is_editing={rec_is_editing} onMouseDown={rec_edit_toggle_handle}
          onMouseEnter={rec_mouse_enter_handle} is_nested={is_nested} />}
        {src_track && <Track_action_lbl lbl={src_track.lbl}
          is_editing={src_is_editing} onMouseDown={src_edit_toggle_handle}
          onMouseEnter={src_mouse_enter_handle} is_nested={is_nested} />}
        {is_show_ctrl && (src_track?.ctrl.has_waveform
          || src_track?.ctrl.has_power) && <div style={{width: '54px',
          display: 'flex', justifyContent: 'flex-end',
          border: `1px solid ${theme.gray1}`}}>
          {src_track?.ctrl.has_waveform && <Track_action_waveform
            is_waveform={src_track.is_waveform} />}
          {src_track?.ctrl.has_power && <Track_action_power
            color={src_track.power.color} />}
        </div>}
        {!rec_track?.ctrl.has_monitor && !rec_track?.ctrl.has_solo
          && !rec_track?.ctrl.has_mute && <div style={{width: '42px',
          border: `1px solid ${theme.gray1}`}} />}
        {!rec_track?.ctrl.has_monitor && !rec_track?.ctrl.has_solo
          && !rec_track?.ctrl.has_mute && is_show_ctrl &&
          !(src_track?.ctrl.has_waveform && src_track?.ctrl.has_power) && <div
          style={{width: '54px', border: `1px solid ${theme.gray1}`}} />}
        {src_track?.ctrl.has_monitor && <Track_action_monitor
          selected_monitor={src_selected_monitor} track_id={src_track.id}
          onMouseDown={src_toggle_monitor} />}
        {src_track?.ctrl.has_solo && <Track_action_solo
          color={src_track.solo.color} />}
        {src_track?.ctrl.has_mute && <Track_action_mute
          color={src_track.mute.color} />}
        {!rec_track?.ctrl.has_monitor && !rec_track?.ctrl.has_solo
          && !rec_track?.ctrl.has_mute && <div style={{width: '36px',
          border: `1px solid ${theme.gray1}`}} />}
      </div>
    );
  }
  return (
    <div style={{display: 'flex', height: `${height}px`, width: '100%',
      margin: is_no_fill && '3px 0', position: 'relative',
      justifyContent: 'flex-end'}}
    onContextMenu={e=>ctx_menu_handle(e, rec_track)}>
      {src_track?.ctrl.has_solo && <Track_action_solo
        color={src_track.solo.color} />}
      {src_track?.ctrl.has_mute && <Track_action_mute
        color={src_track.mute.color} />}
      {src_track?.ctrl.has_monitor && <Track_action_monitor
        selected_monitor={src_selected_monitor} track_id={src_track.id}
        onMouseDown={src_toggle_monitor} />}
      {src_track && <Track_action_lbl lbl={src_track.lbl}
        is_editing={src_is_editing} onMouseDown={src_edit_toggle_handle}
        onMouseEnter={src_mouse_enter_handle} is_nested={is_nested} />}
      {rec_track && <Track_action_lbl lbl={rec_track.lbl}
        is_editing={rec_is_editing} onMouseDown={rec_edit_toggle_handle}
        onMouseEnter={rec_mouse_enter_handle} is_nested={is_nested} />}
      {rec_track?.ctrl.has_synclock && <Track_action_synclock
        is_synclock={rec_track.is_synclock} onMouseDown={synclock_handle} />}
      {is_show_ctrl && <div style={{width: '54px', display: 'flex',
        justifyContent: 'flex-end', border: `1px solid ${theme.gray1}`}}>
        {rec_track.ctrl.has_waveform && <Track_action_waveform
          is_waveform={rec_track.is_waveform} />}
        {rec_track.ctrl.has_power && <Track_action_power
          color={rec_track.power.color} onMouseDown={power_handle} />}
      </div>}
      {rec_track?.ctrl.has_monitor && <Track_action_monitor
        selected_monitor={rec_selected_monitor} track_id={rec_track.id}
        onMouseDown={rec_toggle_monitor} />}
      {rec_track?.ctrl.has_solo && <Track_action_solo
        color={rec_track.solo.color} onMouseDown={solo_handle} />}
      {rec_track?.ctrl.has_mute && <Track_action_mute
        color={rec_track.mute.color} onMouseDown={mute_handle} />}
      {!rec_track?.ctrl.has_monitor && !rec_track?.ctrl.has_solo
        && !rec_track?.ctrl.has_mute && <div style={{width: '36px',
        border: `1px solid ${theme.gray1}`}} />}
    </div>
  );
});
let Tracks_left_container = React.memo(({lbin, nested_track_paths, rec_frame,
  scroll_y, tracks_total_height, tracks_pad, playback_rate, aaf_in, src_frame,
  tracks_wrapper_width, f2px_k, zoom, scroll_x, scroll_x_set, scroll_y_set,
  tracks_wrapper_height, tracks_container_height, cmd_power, cmd_solo, cmd_mute,
  cmd_synclock, cmd_lock, cmd_track_remove, cmd_match_frame, visible_tracks,
  nested_track_paths_set})=>{
  let [timeline_mode] = use_je('editor.timeline_mode', 'rec');
  let {t} = useTranslation();
  let [modal_api, modal_ctx_holder] = Modal.useModal();
  let [is_show_ctrl, is_show_ctrl_set] = useState(false);
  let [ctx_data, ctx_data_set] = useState({is_open: false});
  let container_ref = useRef(null);
  let click_handle = useCallback(()=>{
    return is_show_ctrl_set(!is_show_ctrl);
  }, [is_show_ctrl]);
  let ctx_menu_handle = useCallback((e, track)=>{
    e.preventDefault();
    if (track.type != 'timeline_track' || e.ctrlKey)
      return;
    let container = container_ref.current;
    if (!container)
      return;
    let coords = coords_get(container);
    let x = window.scrollX + e.clientX - coords.left;
    let y = window.scrollY + e.clientY - coords.top + scroll_y;
    let items = [
      {key: 'match_frame_track', label: t('Match Frame Track'),
        disabled: !aaf_in},
      {key: 'reverse_match_frame_track', label: t('Reverse Match Frame Track'),
        disabled: true},
      {type: 'divider'},
      {key: 'lock_track', label: t('Lock Track'), disabled: !aaf_in},
      {key: 'unlock_track', label: t('Unlock Track'), disabled: !aaf_in},
      {key: 'track_remove', label: t('Delete Track'), disabled: !aaf_in},
      {type: 'divider'},
      {key: 'rename_track', label: t('Rename Track...'), disabled: true},
      {type: 'divider'},
      {key: 'track_color', label: t('Track Color'), disabled: true},
      {type: 'divider'},
      {key: 'what_is_this', label: t('What\'s This?'), disabled: true},
    ];
    ctx_data_set({is_open: true, x, y, items, track});
  }, [t, scroll_y, aaf_in]);
  let wheel_handle = useCallback(e=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let delta_x = e.ctrlKey ? e.deltaY : e.deltaX;
    let delta_y = e.ctrlKey ? e.deltaX : e.deltaY;
    let can_move_x;
    if (e.deltaX > 0)
    {
      can_move_x = scroll_x
        + player.px2f(tracks_wrapper_width, f2px_k, zoom)
        < lbin.rec_monitor_in.len;
    }
    else
      can_move_x = !!scroll_x;
    let can_move_y = e.deltaY > 0
      ? scroll_y + tracks_wrapper_height < tracks_container_height : !!scroll_y;
    if (delta_x && can_move_x || delta_y && can_move_y)
      e.preventDefault();
    scroll_x_set(scroll_x + player.px2f(delta_x, f2px_k, zoom));
    scroll_y_set(scroll_y + delta_y);
  }, [f2px_k, lbin?.rec_monitor_in?.len, scroll_x, scroll_x_set, scroll_y,
    scroll_y_set, tracks_container_height, tracks_wrapper_height,
    tracks_wrapper_width, zoom]);
  let ctx_close_handle = useCallback(()=>{
    ctx_data_set({is_open: false});
  }, []);
  let select_handle = useCallback(opt=>{
    ctx_close_handle();
    if (opt.key == 'match_frame_track')
    {
      if (timeline_mode == 'src' && lbin.src_monitor)
      {
        return cmd_match_frame(lbin.src_monitor.mob_id, ctx_data.track.id,
          src_frame);
      }
      if (timeline_mode == 'rec' && lbin.rec_monitor_in)
      {
        return cmd_match_frame(lbin.rec_monitor_in.mob_id, ctx_data.track.id,
          rec_frame);
      }
      assert(0, 'unexpected timeline_mode: ' + timeline_mode);
    }
    if (opt.key == 'lock_track')
      cmd_lock(lbin.rec_monitor_in.mob_id, ctx_data.track.id, true);
    if (opt.key == 'unlock_track')
      cmd_lock(lbin.rec_monitor_in.mob_id, ctx_data.track.id, false);
    if (opt.key == 'track_remove')
    {
      modal_api.confirm({
        title: t('Are you sure you want to delete the selected track(s)?'),
        onOk: ()=>{
          let _nested_track_paths = nested_track_close(nested_track_paths,
            ctx_data.track.id);
          nested_track_paths_set(_nested_track_paths);
          let mob_id;
          if (timeline_mode == 'src')
            mob_id = lbin.src_monitor.mob_id;
          else if (timeline_mode == 'rec')
            mob_id = lbin.rec_monitor_in.mob_id;
          else
            assert(0, 'unexpected timeline_mode: ' + timeline_mode);
          cmd_track_remove(mob_id, ctx_data.track.id);
        },
      });
    }
  }, [cmd_lock, cmd_match_frame, cmd_track_remove, ctx_close_handle, src_frame,
    ctx_data, rec_frame, modal_api, t, lbin, nested_track_paths_set,
    nested_track_paths, timeline_mode]);
  useEffect(()=>{
    let target = container_ref.current;
    if (!target)
      return;
    target.addEventListener('wheel', wheel_handle,
      {passive: false});
    return ()=>{
      target.removeEventListener('wheel', wheel_handle);
    };
  }, [wheel_handle]);
  return (
    <div ref={container_ref} style={{position: 'relative',
      paddingRight: '4px'}}>
      {modal_ctx_holder}
      <div style={{minWidth: '200px', width: '200px', height: '100%',
        position: 'relative', overflow: 'hidden'}}>
        {lbin?.rec_monitor_in && <div style={{display: 'flex', zIndex: 1,
          position: 'relative'}}>
          <Pos_tc start_tc={lbin?.rec_monitor_in?.start_tc}
            playback_rate={playback_rate} frame={rec_frame}
            editrate={lbin?.rec_monitor_in?.editrate} />
          <Editor_btn onClick={click_handle}>
            <Icon src={is_show_ctrl ? left_arrow_icon : right_arrow_icon} />
          </Editor_btn>
        </div>}
        <div style={{height: `${tracks_total_height + tracks_pad * 2}px`,
          display: 'flex', flexDirection: 'column', alignItems: 'flex-end',
          justifyContent: 'center', position: 'absolute',
          top: `${tracks_pad - scroll_y}px`, right: 0}}>
          {visible_tracks.map(rec_track=>{
            let src_track = lbin.src_monitor?.tracks.find(sub_track=>{
              return sub_track.id == rec_track.id;
            });
            return (
              <Track_actions rec_track={rec_track} src_track={src_track}
                is_show_ctrl={is_show_ctrl} ctx_menu_handle={ctx_menu_handle}
                cmd_power={cmd_power} cmd_solo={cmd_solo} lbin={lbin}
                cmd_mute={cmd_mute} cmd_synclock={cmd_synclock}
                nested_track_paths={nested_track_paths}
                is_nested={rec_track.is_nested} key={rec_track.id}
                visible_tracks={visible_tracks} />
            );
          })}
        </div>
      </div>
      <Ctx_menu x={ctx_data.x} y={ctx_data.y - scroll_y}
        is_open={ctx_data.is_open} items={ctx_data.items}
        on_close={ctx_close_handle} on_select={select_handle} />
    </div>
  );
});
// XXX vladimir: move to player.js
let Frame_ptr = React.memo(({left, color=theme.light_blue, is_dashed,
  style={}})=>{
  return <div style={{height: '100%', width: '1px', borderLeftWidth: '1px',
    borderLeftColor: color, borderLeftStyle: is_dashed ? 'dashed' : 'solid',
    position: 'absolute', left: `${left}px`, pointerEvents: 'none',
    top: '0px', zIndex: 102, ...style}} />;
});
let Tc_lbl = React.memo(({tc_lbl, pos})=>{
  return (
    <div style={{position: 'absolute', left: `${pos}px`,
      transform: 'translateX(-50%)', height: '100%', fontSize: '11px',
      display: 'flex', flexDirection: 'column', alignItems: 'center',
      justifyContent: 'space-between'}}>
      <div style={{height: '8px', width: '1px', background: 'black'}} />
      <span style={{userSelect: 'none', color: 'black'}}>{tc_lbl}</span>
    </div>
  );
});
let allow_select_fillers = false;
let Seg = React.memo(({editrate, start_tc, track_idx, seg, fps, zoom, monitor,
  is_no_border, tracks_container_ref, ctx_data_set, f2px_k,
  nested_track_paths, nested_track_paths_set, track_id, visible_tracks,
  is_parent_selected=false, playback_rate=1, drag_start_handle,
  is_trim_start_moved=false, trim_offset=0})=>{
  let {t} = useTranslation();
  let [rec_editing_track_ids, rec_editing_track_ids_set] = use_je(
    'editor.rec_editing_track_ids', []);
  let [trim_mode] = use_je('editor.trim_mode', null);
  let [drag_mode, drag_mode_set] = use_je('editor.drag_mode', null);
  let ref = useRef(null);
  let is_selected = useMemo(()=>{
    return is_parent_selected || drag_mode?.segs.has(seg);
  }, [is_parent_selected, seg, drag_mode?.segs]);
  let ctx_menu_handle = useCallback(e=>{
    e.preventDefault();
    let tracks_container = tracks_container_ref.current;
    if (!tracks_container || seg.depth != 2)
      return;
    let x = window.scrollX + e.clientX - coords_get(tracks_container).left;
    let y = window.scrollY + e.clientY - coords_get(tracks_container).top;
    let items;
    if (seg.is_on_right_click_menu)
    {
      items = seg.arr.map((child_seg, idx)=>{
        return {key: `src_${idx}`, label: child_seg.lbl,
          icon: seg.select_idx == idx
            ? <div><Icon src={tick_icon} /></div>
            : <div />};
      }).filter(Boolean);
      items.push({type: 'divider'});
      items.push({key: 'find_bin', label: t('Find Bin'), icon: <div />,
        disabled: true});
      if (is_selected)
      {
        let is_audio_track = track_id[0] == 'A';
        items.push({key: 'mute_clips', label: t('Mute Clips'), icon: <div />,
          disabled: true});
        items.push({key: 'change_source_track', label: t('Change Source Track'),
          icon: <div style={{display: 'inline-block'}} />,
          disabled: !is_audio_track,
          children: seg.arr.map(
            child_seg=>({key: child_seg.lbl, label: child_seg.lbl}))});
      }
    }
    else
    {
      items = [
        {key: 'new', label: t('New'), disabled: true, children: []},
        {key: 'add_filter', label: t('Add Filter'), disabled: true,
          children: []},
        {type: 'divider'},
        {key: 'create_sequence_template', label: t('Create Sequence Template'),
          disabled: true},
        {type: 'divider'},
        {key: 'mute_clips', label: t('Mute Clips'), disabled: true},
        {key: 'unmute_clips', label: t('Unmute Clips'), disabled: true},
        {type: 'divider'},
        {key: 'change_source_track', label: t('Change Source Track'),
          disabled: true, children: []},
        {key: 'waveform_sync', label: t('Waveform Sync'), disabled: true},
        {type: 'divider'},
        {key: 'add_edit_title', label: t('Add/Edit Title...'), disabled: true},
        {key: 'promote_titles', label: t('Promote Titles'), disabled: true,
          children: []},
        {type: 'divider'},
        {key: 'render', label: t('Render'), disabled: true, children: []},
        {key: 'mixdown', label: t('Mixdown'), disabled: true, children: []},
        {type: 'divider'},
        {key: 'audio_ducking', label: t('Audio Ducking'), disabled: true},
        {type: 'divider'},
        {key: 'show_sequence_map', label: t('Show Sequence Map'),
          disabled: true},
        {type: 'divider'},
        {key: 'strip_silence_from_sequence',
          label: t('Strip Silence from Sequence'), disabled: true},
        {type: 'divider'},
        {key: 'remove_match_frame_edits',
          label: t('Remove Match Frame Edits'),
          disabled: true},
        {key: 'select', label: t('Select'), disabled: true, children: []},
        {key: 'create_sequence_based_on_selection',
          label: t('Create Sequence Based on Selection'), disabled: true},
        {type: 'divider'},
        {key: 'find_bin', label: t('Find Bin'), disabled: true},
        {type: 'divider'},
        {key: 'find_black_hole', label: t('Find Black Hole'), disabled: true},
        {key: 'find_flash_frame', label: t('Find Flash Frame'), disabled: true},
        {type: 'divider'},
        {key: 'lock_tracks', label: t('Lock Tracks'), disabled: true},
        {key: 'unlock_tracks', label: t('Unlock Tracks'), disabled: true},
        {type: 'divider'},
        {key: 'move_clip_leaves_filter', disabled: true,
          label: t('Move Clip Leaves Filter')},
        {type: 'divider'},
        {key: 'select_filter_with_segment_tools', disabled: true,
          label: t('Select Filter with Segment Tools')},
        {type: 'divider'},
        {key: 'add_timeline_clip_note', label: t('Add Timeline Clip Note...'),
          disabled: true},
        {type: 'divider'},
        {key: 'get_position_info', label: t('Get Position Info'),
          disabled: true},
        {key: 'set_local_clip_color', label: t('Set Local Clip Color'),
          disabled: true, children: []},
        {key: 'timeline_settings', label: t('Timeline Settings...'),
          disabled: true},
        {type: 'divider'},
        {key: 'whats_this', label: t('What\'s this?'), disabled: true},
      ];
    }
    ctx_data_set({is_open: true, x, y, track_id, seg, items});
  }, [tracks_container_ref, seg, ctx_data_set, track_id, t, is_selected]);
  let mouse_down_handle = useCallback(e=>{
    if (!drag_mode || seg.depth != 2)
      return;
    if (!allow_select_fillers && seg.type == 'filler')
      return;
    let selected_segs;
    let track_ids;
    let is_dragging = false;
    if (e.shiftKey)
    {
      selected_segs = new Map(drag_mode.segs);
      track_ids = new Map(drag_mode.track_ids);
      if (selected_segs.has(seg))
      {
        selected_segs.delete(seg);
        track_ids.delete(seg);
      }
      else
      {
        selected_segs.set(seg, 0);
        track_ids.set(seg, track_id);
      }
    }
    else
    {
      if (drag_mode.segs.has(seg))
      {
        selected_segs = new Map(drag_mode.segs);
        track_ids = new Map(drag_mode.track_ids);
      }
      else
      {
        selected_segs = new Map();
        track_ids = new Map();
        selected_segs.set(seg, 0);
        track_ids.set(seg, track_id);
      }
      is_dragging = true;
    }
    drag_mode_set({...drag_mode, segs: selected_segs, track_ids,
      start_track_idx: track_idx, is_dragging,
      start_coords: {x: e.clientX, y: e.clientY}});
    if (!rec_editing_track_ids.includes(track_id))
    {
      let _rec_editing_track_ids = [...rec_editing_track_ids, track_id];
      rec_editing_track_ids_set(_rec_editing_track_ids);
    }
  }, [drag_mode, seg, drag_mode_set, rec_editing_track_ids, track_id, track_idx,
    rec_editing_track_ids_set]);
  let double_click_handle = useCallback(e=>{
    e.preventDefault();
    if (!seg.is_on_double_click_track)
      return;
    if (nested_track_paths[track_id])
    {
      let nested_tracks = nested_tracks_get(monitor, track_id,
        nested_track_paths);
      let track_ids = nested_tracks.map(track=>track.id);
      let _rec_editing_track_ids = [...rec_editing_track_ids]
        .filter(id=>!track_ids.includes(id));
      rec_editing_track_ids_set(_rec_editing_track_ids);
      let _nested_track_paths = nested_track_close(nested_track_paths,
        track_id);
      nested_track_paths_set(_nested_track_paths);
      return;
    }
    let track = visible_tracks.find(_track=>_track.id == track_id);
    if (!track)
      return;
    let path = obj_path_find(track, seg);
    if (!path)
      assert(0, 'path is not found');
    let _nested_track_paths = {...nested_track_paths};
    _nested_track_paths[track_id] = path;
    nested_track_paths_set(_nested_track_paths);
  }, [nested_track_paths, nested_track_paths_set, seg, rec_editing_track_ids,
    rec_editing_track_ids_set, monitor, track_id, visible_tracks]);
  let child_segs = useMemo(()=>{
    if (seg.is_no_arr)
      return [];
    if (seg.is_no_border)
      return [seg.arr[seg.select_idx]];
    return seg.arr;
  }, [seg]);
  let color = useMemo(()=>{
    if (seg.is_hide)
      return 'transparent';
    if (is_selected)
      return '#608DAF';
    if (seg.rndr?.is_mute)
      return '#848484';
    if (seg.color)
      return seg.color;
    return theme.gray3;
  }, [seg, is_selected]);
  let show_icons_container = useMemo(()=>{
    return seg.icon?.is_icon_inp || seg.icon?.is_icon_out;
  }, [seg.icon?.is_icon_inp, seg.icon?.is_icon_out]);
  let edge_mouse_down_handle = useCallback(e=>{
    e.stopPropagation();
    drag_start_handle();
  }, [drag_start_handle]);
  let is_left_trim = useMemo(()=>{
    return trim_mode && trim_mode.left_segs[track_id]?.includes(seg);
  }, [trim_mode, track_id, seg]);
  let is_right_trim = useMemo(()=>{
    return trim_mode && trim_mode.right_segs[track_id]?.includes(seg);
  }, [trim_mode, track_id, seg]);
  let is_trim = useMemo(()=>{
    return is_left_trim || is_right_trim;
  }, [is_left_trim, is_right_trim]);
  let trim_delta = useMemo(()=>{
    if (is_trim)
      return trim_mode.deltas[track_id];
    return 0;
  }, [is_trim, track_id, trim_mode?.deltas]);
  let left = useMemo(()=>{
    if (is_left_trim)
      return player.f2px(seg.start + trim_delta, f2px_k, zoom);
    if (is_right_trim)
      return player.f2px(seg.start, f2px_k, zoom);
    if (seg.start == 0)
      return player.f2px(0, f2px_k, zoom);
    return player.f2px(seg.start - trim_offset, f2px_k, zoom);
  }, [f2px_k, is_right_trim, seg.start, trim_delta, trim_offset, zoom,
    is_left_trim]);
  let len = useMemo(()=>{
    if (is_left_trim)
    {
      return player.f2px(seg.len - trim_delta, f2px_k, zoom)
        * playback_rate;
    }
    if (is_right_trim)
    {
      return player.f2px(seg.len + trim_delta, f2px_k, zoom)
        * playback_rate;
    }
    return player.f2px(seg.len - trim_offset, f2px_k, zoom) * playback_rate;
  }, [f2px_k, is_left_trim, is_right_trim, playback_rate, seg.len, trim_delta,
    zoom, trim_offset]);
  let next_playback_rate = useMemo(()=>{
    if (seg.type == 'operation_motion' && seg.playrate !== undefined)
      return playback_rate / seg.playrate;
    return playback_rate;
  }, [playback_rate, seg.playrate, seg.type]);
  let next_trim_offset = useMemo(()=>{
    if (trim_offset)
      return trim_offset;
    if (is_left_trim)
      return trim_delta;
    return 0;
  }, [is_left_trim, trim_offset, trim_delta]);
  let is_on_boundary = useMemo(()=>{
    if (!trim_mode || !seg.trim)
      return false;
    return is_left_trim
      && (trim_delta == -seg.trim?.start_pre
        || trim_delta == seg.trim?.start_post)
      || is_right_trim
      && (trim_delta == -seg.trim?.end_pre
        || trim_delta == seg.trim?.end_post);
  }, [is_left_trim, is_right_trim, seg.trim, trim_delta, trim_mode]);
  let trim_handle_color = useMemo(()=>{
    if (!trim_mode)
      return null;
    if (is_on_boundary && trim_mode.is_dragging)
      return 'linear-gradient(90deg, #ff0101 0%, #6b0000 100%)';
    if (trim_mode.is_side_clicked)
      return 'linear-gradient(90deg, #FFFE00 0%, #B4B100 100%)';
    return 'linear-gradient(90deg, #fb5ce3 0%, #79286d 100%)';
  }, [is_on_boundary, trim_mode]);
  if (seg.type == 'marker')
  {
    return (
      <Marker_seg color={seg.color} left={left} zidx={seg.zidx}
        opacity={seg.opacity} />
    );
  }
  if (seg.type == 'mark_in')
    return <Mark_in_seg left={left} zidx={seg.zidx} opacity={seg.opacity} />;
  if (seg.type == 'mark_out')
    return <Mark_out_seg left={left} zidx={seg.zidx} opacity={seg.opacity} />;
  if (seg.type == 'cmt')
  {
    return (
      <Tooltip
        title={<div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar src={seg.avatar} style={{width: '12px', height: '12px'}} />
            <span
              style={{fontSize: '11px', color: theme.gray9, marginLeft: '10px'}}
            >
              {seg.user_id.split('@')[0]}
            </span>
          </div>
          <span style={{fontSize: '11px'}}>
            <span style={{color: theme.green}}>
              {tc.frame2tc(start_tc + player.frame_start(seg), editrate)}
            </span>
            <span>
              {' '}{seg.msg}
            </span>
          </span>
        </div>}
      >
        <Avatar src={seg.avatar}
          style={{width: '12px', height: '12px', position: 'absolute',
            top: '50%', left: `${left}px`,
            opacity: seg.opacity, zIndex: seg.zidx,
            transform: 'translate(-50%, -50%)'}} />
      </Tooltip>
    );
  }
  return (
    <>
      {len && <div style={{display: 'flex', top: 0, position: 'absolute',
        height: seg.depth > 1 && !is_no_border ? 'calc(100% + 2px)' : '100%',
        left: `${left}px`, width: `${len}px`, opacity: seg.opacity,
        background: color, padding: '2px', whiteSpace: 'nowrap',
        borderTop: !is_no_border && `1px solid ${theme.gray9}`,
        borderLeft: !is_no_border && `1px solid ${theme.gray9}`,
        borderBottom: !is_no_border && `1px solid ${theme.gray1}`,
        borderRight: !is_no_border && `1px solid ${theme.gray1}`,
        marginTop: seg.depth > 1 && !is_no_border ? '-1px' : '0px',
        marginLeft: seg.depth > 1 && !is_no_border ? '-1px' : '0px',
        overflow: 'hidden', zIndex: seg.zidx}}
      onContextMenu={ctx_menu_handle} onDoubleClick={double_click_handle}
      onMouseDown={mouse_down_handle} ref={ref}>
        {child_segs.map((child_seg, idx)=>{
          return <Seg key={idx} editrate={editrate} start_tc={start_tc}
            monitor={monitor} track_idx={track_idx} track_id={track_id}
            is_parent_selected={is_selected} trim_offset={next_trim_offset}
            fps={fps} zoom={zoom} visible_tracks={visible_tracks}
            is_no_border={is_no_border || seg.is_no_border} seg={child_seg}
            tracks_container_ref={tracks_container_ref} f2px_k={f2px_k}
            ctx_data_set={ctx_data_set}
            nested_track_paths={nested_track_paths}
            nested_track_paths_set={nested_track_paths_set}
            playback_rate={next_playback_rate}
            drag_start_handle={drag_start_handle}
            is_trim_start_moved={is_trim_start_moved || is_left_trim} />;
        })}
        {seg.lbl && !seg.is_lbl_hide && <span style={{fontSize: 12,
          userSelect: 'none', zIndex: seg.zidx, pointerEvents: 'none',
          fontStyle: seg.is_lbl_italic ? 'italic' : 'normal',
          color: 'black'}}>
          {seg.lbl}
        </span>}
        {show_icons_container && <div style={{display: 'flex', top: '50%',
          position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)',
          gap: '2px', zIndex: seg.zidx, pointerEvents: 'none'}}>
          {seg.icon.is_icon_inp && <Icon src={gray_icon} />}
          {seg.icon.is_icon_out && seg.icon.icon_out == 'transition'
            && <Icon src={purple_icon} />}
          {seg.icon.is_icon_out && seg.icon.icon_out == 'timewarp'
            && <Icon src={motion_icon} />}
        </div>}
        {!!seg.outsync && <div style={{position: 'absolute', bottom: 0,
          left: `${player.f2px(seg.outsync.start, f2px_k, zoom)}px`,
          width: `${player.f2px(seg.outsync.len, f2px_k, zoom)}px`,
          height: '16px', borderBottom: `2px solid ${seg.outsync.color}`,
          display: 'flex', justifyContent: 'flex-end', fontSize: '11px',
          pointerEvents: 'none'}}>
          <span style={{userSelect: 'none'}}>{seg.outsync.v}</span>
        </div>}
        {!!seg.dup && <div style={{position: 'absolute', top: 0, height: '2px',
          left: `${player.f2px(seg.dup.start, f2px_k, zoom)}px`,
          width: `${player.f2px(seg.dup.len, f2px_k, zoom)}px`,
          background: seg.dup.color, borderBottom: '1px solid black',
          pointerEvents: 'none'}} />}
      </div>}
      {is_left_trim && <div style={{position: 'absolute', top: 2,
        height: 'calc(100% - 4px)', left: left, zIndex: 101,
        width: '5px', background: trim_handle_color}} />}
      {is_right_trim && <div style={{position: 'absolute', top: 2,
        height: 'calc(100% - 4px)', left: left + len - 5, zIndex: 101,
        width: '5px', background: trim_handle_color}} />}
      {is_left_trim && <div style={{width: '8px', height: '100%', top: 0,
        position: 'absolute', zIndex: 101, left: left,
        cursor: 'e-resize'}}
      onMouseDown={edge_mouse_down_handle} />}
      {is_right_trim && <div style={{width: '8px', height: '100%', top: 0,
        position: 'absolute', zIndex: 101, left: left + len - 8,
        cursor: 'w-resize'}}
      onMouseDown={edge_mouse_down_handle} />}
      {seg.is_match_cut && <div style={{position: 'absolute', top: '50%',
        left: `${left - 5}px`, zIndex: seg.zidx,
        transform: 'translateY(-50%)', width: '7px', height: '7px',
        borderTop: '3px solid #8A8AF7', borderBottom: '3px solid #8A8AF7'}} />}
    </>
  );
});
let Track = React.memo(({idx, id, monitor, height, start, len, color, arr=[],
  fps, zoom, scroll_x, is_no_border, frames_gap, is_show_tcs, style={},
  tracks_container_ref, f2px_k, ctx_data_set, nested_track_paths,
  nested_track_paths_set, drag_start_handle, cmd_clip_move, visible_tracks,
  cmd_clip_move_insert, ...rest})=>{
  let [timeline_mode] = use_je('editor.timeline_mode', 'rec');
  let [drag_mode, drag_mode_set] = use_je('editor.drag_mode', null);
  let start_tc = useMemo(()=>{
    return monitor?.start_tc;
  }, [monitor?.start_tc]);
  let root_seg = useMemo(()=>arr[0], [arr]);
  let no_fill = useMemo(()=>{
    return height ? height.endsWith('no_fill') : false;
  }, [height]);
  let left = useMemo(()=>{
    return player.f2px(start, f2px_k, zoom);
  }, [f2px_k, start, zoom]);
  let width = useMemo(()=>player.f2px(len, f2px_k, zoom), [f2px_k, len, zoom]);
  let tc_lbls = useMemo(()=>{
    if (!monitor || !is_show_tcs)
      return [];
    let _tc_lbls = [];
    let min_frame = scroll_x - scroll_x % frames_gap;
    let max_frame = len;
    for (let frame = min_frame; frame < max_frame; frame += frames_gap)
    {
      let sec = player.f2px(frame, f2px_k, zoom);
      let tc_lbl = tc.frame2tc(start_tc + frame, monitor.editrate);
      _tc_lbls.push(<Tc_lbl key={frame} tc_lbl={tc_lbl} pos={sec} />);
    }
    return _tc_lbls;
  }, [f2px_k, frames_gap, monitor, scroll_x, is_show_tcs,
    start_tc, zoom, len]);
  let mouse_up_handle = useCallback(e=>{
    if (!drag_mode?.is_dragging)
      return;
    let delta_x_px = e.clientX - drag_mode.start_coords.x;
    if (!delta_x_px)
      return drag_mode_set({...drag_mode, is_dragging: false});
    let delta_x = Math.round(player.px2f(delta_x_px, f2px_k, zoom));
    let start_track_idx = drag_mode.start_track_idx;
    let track_delta = start_track_idx - idx;
    for (let selected_seg of drag_mode.segs.keys())
    {
      let seg_track_id = drag_mode.track_ids.get(selected_seg);
      let seg_track_prefix = seg_track_id.slice(0, 1);
      let seg_track_num = parseInt(seg_track_id.slice(1), 10);
      let next_track_num;
      if (seg_track_prefix == 'V')
        next_track_num = seg_track_num + track_delta;
      else
        next_track_num = seg_track_num - track_delta;
      let next_track_id = seg_track_prefix + next_track_num;
      if (drag_mode.type == 'select')
      {
        let mob_id;
        if (timeline_mode == 'src')
          mob_id = monitor.mob_id;
        else if (timeline_mode == 'rec')
          mob_id = monitor.mob_id;
        else
          assert(0, 'unexpected timeline_mode: ' + timeline_mode);
        cmd_clip_move(mob_id, seg_track_id, next_track_id,
          selected_seg.abs_start, selected_seg.abs_start + delta_x);
      }
      else if (drag_mode.type == 'insert')
      {
        let mob_id;
        if (timeline_mode == 'src')
          mob_id = monitor.mob_id;
        else if (timeline_mode == 'rec')
          mob_id = monitor.mob_id;
        else
          assert(0, 'unexpected timeline_mode: ' + timeline_mode);
        cmd_clip_move_insert(mob_id, seg_track_id, next_track_id,
          selected_seg.abs_start, selected_seg.abs_start + delta_x);
      }
    }
    drag_mode_set(null);
  }, [cmd_clip_move, f2px_k, idx, drag_mode, drag_mode_set, zoom,
    cmd_clip_move_insert, monitor, timeline_mode]);
  return (
    <div style={{position: 'relative', height: `${track_height2px(height)}px`,
      left: `${left}px`, width: `${width}px`, margin: no_fill && '3px 0',
      background: color, ...style}} {...rest} onMouseUp={mouse_up_handle}>
      {!!root_seg && <Seg editrate={monitor.editrate} seg={root_seg}
        start_tc={monitor.start_tc} track_idx={idx} track_id={id}
        fps={fps} is_no_border={is_no_border} f2px_k={f2px_k} monitor={monitor}
        ctx_data_set={ctx_data_set} zoom={zoom} visible_tracks={visible_tracks}
        tracks_container_ref={tracks_container_ref}
        nested_track_paths={nested_track_paths}
        nested_track_paths_set={nested_track_paths_set}
        drag_start_handle={drag_start_handle} />}
      {is_show_tcs && tc_lbls}
    </div>
  );
});
let Ctx_menu = React.memo(({x, y, items, is_open, on_select, on_close})=>{
  return (
    <ConfigProvider theme={{algorithm: theme.darkAlgorithm, components: {
      Dropdown: {borderRadiusLG: 0, borderRadiusSM: 0, borderRadiusXS: 0,
        colorBgElevated: '#686868', fontSize: 10, paddingXS: 8,
        paddingBlock: 2}, Menu: {itemHeight: 10}}}}>
      <Antd_dropdown menu={{items, onClick: on_select}} open={is_open}
        onOpenChange={on_close} trigger={['click']}>
        <div style={{position: 'absolute', left: `${x}px`, top: `${y}px`}} />
      </Antd_dropdown>
    </ConfigProvider>
  );
});
let Tracks_right_container = React.memo(({lbin, fps, zoom, f2px_k, scroll_x,
  scroll_x_set, scroll_y, scroll_y_set, tracks_total_height, tracks_pad,
  src_frame, rec_frame, rec_frame_set, playback_rate_set, nested_track_paths,
  nested_track_paths_set, tracks_container_width, is_v_scroll_visible,
  tracks_wrapper_width, tracks_wrapper_width_set, tracks_container_height,
  tracks_wrapper_height, tracks_wrapper_height_set, cuts, cmd_selector_set,
  drag_start_handle, cmd_clip_move, cmd_clip_move_insert, visible_tracks,
  src_frame_set})=>{
  let [timeline_mode] = use_je('editor.timeline_mode', 'rec');
  let [drag_mode, drag_mode_set] = use_je('editor.drag_mode', null);
  let [show_v_ptr, show_v_ptr_set] = useState(false);
  let [v_ptr_frame, v_ptr_frame_set] = useState(0);
  let [is_ptr_moving, is_ptr_moving_set] = useState(false);
  let [scrolling_left, scrolling_left_set] = useState(false);
  let [scrolling_right, scrolling_right_set] = useState(false);
  let [ctx_data, ctx_data_set] = useState({is_open: false});
  let tracks_wrapper_ref = useRef(null);
  let tracks_container_ref = useRef(null);
  let mouse_enter_handle = useCallback(()=>show_v_ptr_set(true), []);
  let monitor = useMemo(()=>{
    if (!lbin)
      return null;
    if (timeline_mode == 'src')
      return lbin?.src_monitor;
    if (timeline_mode == 'rec')
      return lbin?.rec_monitor_in;
    assert(0, 'unexpected timeline_mode: ' + timeline_mode);
  }, [lbin, timeline_mode]);
  let timeline_frame = useMemo(()=>{
    return timeline_mode == 'src' ? src_frame : rec_frame;
  }, [rec_frame, src_frame, timeline_mode]);
  let timeline_frame_set = useCallback(frame=>{
    if (timeline_mode == 'src')
      return src_frame_set(frame);
    else if (timeline_mode == 'rec')
      return rec_frame_set(frame);
    assert(0, 'unexpected timeline_mode: ' + timeline_mode);
  }, [rec_frame_set, src_frame_set, timeline_mode]);
  let mouse_move_handle = useCallback(e=>{
    if (!monitor)
      return;
    let target = tracks_container_ref.current;
    let _v_ptr_frame = player.px2f(e.clientX - coords_get(target).left, f2px_k,
      zoom);
    _v_ptr_frame = xutil.clamp(_v_ptr_frame, monitor.start, monitor.len);
    _v_ptr_frame = Math.floor(_v_ptr_frame);
    v_ptr_frame_set(_v_ptr_frame);
    show_v_ptr_set(_v_ptr_frame != timeline_frame);
  }, [f2px_k, monitor, timeline_frame, zoom]);
  let mouse_leave_handle = useCallback(()=>show_v_ptr_set(false), []);
  let mouse_down_handle = useCallback(e=>{
    if (drag_mode)
      return;
    // ignore if not left click
    if (e.nativeEvent.which != 1)
      return;
    let _timeline_frame;
    if (e.ctrlKey)
      _timeline_frame = editor.nearest_cut_get(cuts, v_ptr_frame);
    else
      _timeline_frame = v_ptr_frame;
    is_ptr_moving_set(true);
    timeline_frame_set(_timeline_frame);
    show_v_ptr_set(v_ptr_frame != _timeline_frame);
    playback_rate_set(0);
  }, [drag_mode, cuts, v_ptr_frame, timeline_frame_set, playback_rate_set]);
  let mouse_up_handle = useCallback(()=>{
    is_ptr_moving_set(false);
    let _drag_mode = je.get('editor.drag_mode');
    if (_drag_mode?.is_dragging)
      drag_mode_set({...drag_mode, is_dragging: false});
  }, [drag_mode, drag_mode_set]);
  let wheel_handle = useCallback(e=>{
    if (!monitor)
      return;
    let delta_x = e.ctrlKey ? e.deltaY : e.deltaX;
    let delta_y = e.ctrlKey ? e.deltaX : e.deltaY;
    let can_move_x;
    if (e.deltaX > 0)
    {
      can_move_x = scroll_x + player.px2f(tracks_wrapper_width, f2px_k, zoom)
        < monitor.len;
    }
    else
      can_move_x = !!scroll_x;
    let can_move_y = e.deltaY > 0
      ? scroll_y + tracks_wrapper_height < tracks_container_height : !!scroll_y;
    if (delta_x && can_move_x || delta_y && can_move_y)
      e.preventDefault();
    scroll_x_set(scroll_x + player.px2f(delta_x, f2px_k, zoom));
    scroll_y_set(scroll_y + delta_y);
  }, [f2px_k, monitor, scroll_x, scroll_x_set, scroll_y,
    scroll_y_set, tracks_container_height, tracks_wrapper_height,
    tracks_wrapper_width, zoom]);
  let ctx_close_handle = useCallback(()=>{
    ctx_data_set({is_open: false});
  }, []);
  let select_handle = useCallback(opt=>{
    ctx_close_handle();
    if (!opt.key.startsWith('src_'))
      return;
    let [, selector_idx] = opt.key.split('_');
    selector_idx = parseInt(selector_idx, 10);
    let mob_id;
    if (timeline_mode == 'src')
      mob_id = lbin.src_monitor.mob_id;
    else if (timeline_mode == 'rec')
      mob_id = lbin.rec_monitor_in.mob_id;
    else
      assert(0, 'unexpected timeline_mode: ' + timeline_mode);
    cmd_selector_set(mob_id, ctx_data.track_id, selector_idx,
      ctx_data.seg.abs_start);
  }, [cmd_selector_set, ctx_close_handle, ctx_data?.seg?.abs_start,
    ctx_data?.track_id, lbin?.rec_monitor_in?.mob_id, lbin?.src_monitor?.mob_id,
    timeline_mode]);
  let resize_handle = useCallback(()=>{
    let _tracks_wrapper_width = tracks_wrapper_ref.current?.offsetWidth;
    tracks_wrapper_width_set(_tracks_wrapper_width);
    let _tracks_wrapper_height = tracks_wrapper_ref.current?.offsetHeight;
    tracks_wrapper_height_set(_tracks_wrapper_height);
  }, [tracks_wrapper_height_set, tracks_wrapper_width_set]);
  let ptr_move_handle = useCallback(e=>{
    let target = tracks_container_ref.current;
    let _v_ptr_frame = player.px2f(e.clientX - coords_get(target).left,
      f2px_k, zoom);
    let _timeline_frame;
    if (e.ctrlKey)
      _timeline_frame = editor.nearest_cut_get(cuts, v_ptr_frame);
    else
      _timeline_frame = _v_ptr_frame;
    timeline_frame_set(_timeline_frame);
    show_v_ptr_set(_v_ptr_frame != _timeline_frame);
    playback_rate_set(0);
    if (zoom <= 1)
      return;
    let wrapper_coords = coords_get(tracks_wrapper_ref.current);
    if (e.clientX < wrapper_coords.left)
      scrolling_left_set(true);
    else
      scrolling_left_set(false);
    if (e.clientX > wrapper_coords.right - 40)
      scrolling_right_set(true);
    else
      scrolling_right_set(false);
  }, [f2px_k, zoom, cuts, v_ptr_frame, timeline_frame_set, playback_rate_set]);
  let ptr_leave_handle = useCallback(()=>{
    is_ptr_moving_set(false);
    scrolling_left_set(false);
    scrolling_right_set(false);
  }, []);
  let frames_gap_get = useCallback(()=>{
    let min_gap_px = 120;
    let min_gap_s = player.px2f(min_gap_px, f2px_k, zoom) / fps;
    let periods_s = [0.04, 0.2, 1, 2, 5, 10, 15]; // 15 * 2 ^ n
    let min_period = periods_s.at(0);
    if (min_gap_s < periods_s.at(min_period))
    {
      let frames_gap = min_period * fps;
      return frames_gap;
    }
    let sec_gap = periods_s.find((period, index)=>{
      return min_gap_s >= periods_s[index - 1] && min_gap_s <= period;
    });
    if (sec_gap)
    {
      let frames_gap = sec_gap * fps;
      return frames_gap;
    }
    let base_period = periods_s.at(-1);
    let power = Math.floor(Math.log2(2 * min_gap_s / base_period));
    sec_gap = base_period * 2 ** power;
    let frames_gap = sec_gap * fps;
    return frames_gap;
  }, [fps, zoom, f2px_k]);
  useEffect(()=>{
    if (!is_ptr_moving)
      return;
    document.addEventListener('mousemove', ptr_move_handle);
    document.addEventListener('mouseup', ptr_leave_handle);
    return ()=>{
      document.removeEventListener('mousemove', ptr_move_handle);
      document.removeEventListener('mouseup', ptr_leave_handle);
    };
  }, [ptr_leave_handle, ptr_move_handle, is_ptr_moving]);
  useEffect(resize_handle, [resize_handle, is_v_scroll_visible]);
  useEffect(()=>{
    resize_handle();
    window.addEventListener('resize', resize_handle);
    return ()=>window.removeEventListener('resize', resize_handle);
  }, [resize_handle, tracks_wrapper_width_set, zoom]);
  useEffect(()=>{
    let target = tracks_container_ref.current;
    if (!target)
      return;
    target.addEventListener('wheel', wheel_handle,
      {passive: false});
    return ()=>{
      target.removeEventListener('wheel', wheel_handle);
    };
  }, [wheel_handle]);
  use_effect_eserf(()=>eserf(function* _use_effect_player_interval(){
    if (!monitor)
      return;
    if (!scrolling_left && !scrolling_right)
      return;
    while (1)
    {
      let _timeline_frame;
      if (scrolling_left)
        _timeline_frame = timeline_frame - 1;
      else if (scrolling_right)
        _timeline_frame = timeline_frame + 1;
      _timeline_frame = Math.min(_timeline_frame, player.frame_end(monitor));
      timeline_frame_set(_timeline_frame);
      playback_rate_set(0);
      if (scrolling_left)
        scroll_x_set(_timeline_frame);
      else if (scrolling_right)
        scroll_x_set(scroll_x + 1);
      yield eserf.sleep(50);
    }
  }), [timeline_frame, timeline_frame_set, scrolling_left, scroll_x_set,
    scrolling_right, scroll_x, monitor, tracks_wrapper_width, fps, zoom,
    playback_rate_set]);
  let tc_track = useMemo(()=>{
    if (!monitor)
      return null;
    return monitor.tracks.find(track=>track.type == 'tc_track');
  }, [monitor]);
  let frames_gap = useMemo(()=>{
    return frames_gap_get();
  }, [frames_gap_get]);
  let frame_ptr_left = useMemo(()=>{
    return player.f2px(timeline_frame, f2px_k, zoom);
  }, [f2px_k, timeline_frame, zoom]);
  let ghost_frame_ptr_left = useMemo(()=>{
    return player.f2px(timeline_frame + 1, f2px_k, zoom);
  }, [f2px_k, timeline_frame, zoom]);
  return (
    <div style={{width: '100%', height: '100%', position: 'relative'}}>
      <div ref={tracks_wrapper_ref} style={{width: '100%',
        overflow: 'hidden', position: 'relative', zIndex: 0,
        marginRight: '4px', height: '100%'}}>
        <div ref={tracks_container_ref} id="tracks-container" style={{
          width: `${tracks_container_width}px`, height: '100%',
          position: 'absolute', top: 0,
          left: `${-player.f2px(scroll_x, f2px_k, zoom)}px`}}
        onMouseEnter={mouse_enter_handle} onMouseLeave={mouse_leave_handle}
        onMouseMove={mouse_move_handle} onMouseDown={mouse_down_handle}
        onMouseUp={mouse_up_handle}>
          {tc_track && <Track is_show_tcs is_no_border idx={0}
            height="small" start={0} arr={tc_track.arr} color={theme.gray2}
            len={player.px2f(tracks_container_width, f2px_k, zoom)}
            fps={fps} f2px_k={f2px_k} zoom={zoom} scroll_x={scroll_x}
            frames_gap={frames_gap} tracks_container_ref={tracks_container_ref}
            style={{zIndex: 101}} cmd_clip_move_insert={cmd_clip_move_insert}
            cmd_clip_move={cmd_clip_move} monitor={monitor} />}
          <div style={{position: 'absolute', top: `${tracks_pad - scroll_y}px`,
            height: `${tracks_total_height + tracks_pad * 2}px`,
            display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
            justifyContent: 'center', overflow: 'hidden'}}>
            {visible_tracks.map((rec_track, idx)=>{
              let src_track = lbin.src_monitor?.tracks.find(sub_track=>{
                return sub_track.id == rec_track.id;
              });
              let arr = timeline_mode == 'rec' ? rec_track.arr
                : src_track?.arr || [];
              let is_show_tcs = rec_track.type == 'tc_track';
              return (
                <Track key={rec_track.lbl} is_show_tcs={is_show_tcs} idx={idx}
                  monitor={monitor} height={rec_track.height} arr={arr}
                  len={rec_track.len} color={rec_track.color}
                  fps={fps} zoom={zoom} f2px_k={f2px_k} scroll_x={scroll_x}
                  frames_gap={frames_gap} id={rec_track.id}
                  tracks_container_ref={tracks_container_ref}
                  ctx_data_set={ctx_data_set} visible_tracks={visible_tracks}
                  nested_track_paths={nested_track_paths}
                  nested_track_paths_set={nested_track_paths_set}
                  drag_start_handle={drag_start_handle}
                  cmd_clip_move_insert={cmd_clip_move_insert}
                  cmd_clip_move={cmd_clip_move} start={rec_track.start} />
              );
            })}
          </div>
          {monitor && <Frame_ptr left={frame_ptr_left}
            color={timeline_mode == 'src' ? theme.green : theme.light_blue} />}
          {monitor && <Frame_ptr left={ghost_frame_ptr_left}
            color={timeline_mode == 'src' ? theme.green : theme.light_blue}
            is_dashed />}
          {monitor && show_v_ptr && !drag_mode && <Frame_ptr
            left={player.f2px(v_ptr_frame, f2px_k, zoom)}
            color={theme.gray9} style={{opacity: 0.8}} />}
        </div>
      </div>
      <Ctx_menu x={ctx_data.x} y={ctx_data.y} is_open={ctx_data.is_open}
        items={ctx_data.items} on_select={select_handle}
        on_close={ctx_close_handle} />
    </div>
  );
});
let Tracks_container = React.memo(({lbin, fps, zoom, f2px_k, scroll_x,
  scroll_x_set, src_frame, rec_frame, rec_frame_set, playback_rate, aaf_in,
  playback_rate_set, tracks_container_width, is_v_scroll_visible,
  tracks_wrapper_width, tracks_wrapper_width_set, tracks_container_height,
  tracks_wrapper_height, tracks_wrapper_height_set, nested_track_paths,
  nested_track_paths_set, tracks_pad, tracks_total_height, src_frame_set,
  cuts, cmd_selector_set, cmd_power, cmd_clip_move_insert,
  cmd_solo, cmd_mute, cmd_synclock, cmd_lock, visible_tracks,
  cmd_track_remove, drag_start_handle, cmd_clip_move, cmd_match_frame})=>{
  let [scroll_y, scroll_y_set] = useState(0);
  let scroll_y_handle = useCallback(_scroll_y=>{
    let min_scroll = 0;
    let max_scroll = tracks_total_height + tracks_pad * 3 -
    tracks_wrapper_height;
    _scroll_y = xutil.clamp(_scroll_y, min_scroll, max_scroll);
    scroll_y_set(_scroll_y);
  }, [tracks_pad, tracks_total_height, tracks_wrapper_height]);
  let tracks_wrapper_height_handle = useCallback(_tracks_wrapper_height=>{
    let min_scroll = 0;
    let max_scroll = tracks_total_height + tracks_pad * 3 -
    _tracks_wrapper_height;
    let _scroll_y = xutil.clamp(scroll_y, min_scroll, max_scroll);
    scroll_y_set(_scroll_y);
    tracks_wrapper_height_set(_tracks_wrapper_height);
  }, [scroll_y, tracks_pad, tracks_total_height, tracks_wrapper_height_set]);
  return (
    <div style={{maxWidth: '100%', height: '100%', display: 'flex'}}>
      <Tracks_left_container lbin={lbin} aaf_in={aaf_in} src_frame={src_frame}
        nested_track_paths={nested_track_paths} src_monitor={lbin?.src_monitor}
        rec_frame={rec_frame} playback_rate={playback_rate} scroll_y={scroll_y}
        tracks_total_height={tracks_total_height} tracks_pad={tracks_pad}
        scroll_x={scroll_x} f2px_k={f2px_k} zoom={zoom}
        scroll_x_set={scroll_x_set} scroll_y_set={scroll_y_set}
        tracks_wrapper_height={tracks_wrapper_height} cmd_lock={cmd_lock}
        tracks_container_height={tracks_container_height}
        cmd_power={cmd_power} cmd_solo={cmd_solo} cmd_mute={cmd_mute}
        cmd_synclock={cmd_synclock} cmd_match_frame={cmd_match_frame}
        cmd_track_remove={cmd_track_remove} visible_tracks={visible_tracks}
        nested_track_paths_set={nested_track_paths_set} />
      <Tracks_right_container fps={fps} zoom={zoom} f2px_k={f2px_k}
        scroll_x={scroll_x} scroll_x_set={scroll_x_set} scroll_y={scroll_y}
        scroll_y_set={scroll_y_handle} tracks_total_height={tracks_total_height}
        tracks_pad={tracks_pad} src_frame={src_frame} rec_frame={rec_frame}
        lbin={lbin} rec_frame_set={rec_frame_set}
        playback_rate_set={playback_rate_set}
        nested_track_paths={nested_track_paths}
        nested_track_paths_set={nested_track_paths_set}
        tracks_container_width={tracks_container_width}
        is_v_scroll_visible={is_v_scroll_visible} cmd_clip_move={cmd_clip_move}
        tracks_wrapper_width={tracks_wrapper_width}
        tracks_wrapper_width_set={tracks_wrapper_width_set}
        tracks_container_height={tracks_container_height}
        tracks_wrapper_height={tracks_wrapper_height}
        tracks_wrapper_height_set={tracks_wrapper_height_handle}
        cuts={cuts} cmd_selector_set={cmd_selector_set}
        drag_start_handle={drag_start_handle} visible_tracks={visible_tracks}
        cmd_clip_move_insert={cmd_clip_move_insert}
        src_frame_set={src_frame_set} />
      <V_scroll_ctrl is_visible={is_v_scroll_visible} lbin={lbin}
        scroll_y={scroll_y} scroll_y_set={scroll_y_set}
        tracks_total_height={tracks_total_height} tracks_pad={tracks_pad}
        tracks_wrapper_height={tracks_wrapper_height} />
    </div>
  );
});
let Seq_info = React.memo(({lbl, resolution, fps})=>{
  let value = useMemo(()=>{
    if (!lbl || !resolution?.w || !resolution?.h)
      return '—';
    return `${lbl} - ${resolution.w}x${resolution.h} - ${fps} fps`;
  }, [fps, lbl, resolution?.h, resolution?.w]);
  return (
    <Meta_info style={{fontWeight: 'bold'}}>
      {value}
    </Meta_info>
  );
});
let Search = React.memo(({lbin, rec_frame, rec_frame_set})=>{
  let [query, query_set] = useState('');
  let [cuts, cuts_set] = useState([]);
  let change_handle = useCallback(e=>{
    query_set(e.target.value);
  }, []);
  let next_find = useCallback(()=>{
    if (!cuts.length)
      return;
    let _cut_idx = cuts.findIndex(cut=>cut > rec_frame);
    if (_cut_idx == -1)
      _cut_idx = 0;
    rec_frame_set(cuts[_cut_idx]);
  }, [cuts, rec_frame, rec_frame_set]);
  let prev_find = useCallback(()=>{
    if (!cuts.length)
      return;
    let _cut_idx = cuts.findLastIndex(cut=>cut < rec_frame);
    if (_cut_idx == -1)
      _cut_idx = cuts.length - 1;
    rec_frame_set(cuts[_cut_idx]);
  }, [cuts, rec_frame, rec_frame_set]);
  let submit_handle = useCallback(()=>{
    if (!query || !lbin?.rec_monitor_in?.tracks)
      return;
    let search_query = query.trim().toLowerCase();
    let abs_starts = player.abs_starts_get(lbin.rec_monitor_in.tracks);
    let _cuts = [];
    for (let seg of abs_starts.keys())
    {
      if (!seg.lbl)
        continue;
      let lbl = seg.lbl.trim().toLowerCase();
      if (lbl.includes(search_query))
        _cuts.push(seg.abs_start);
    }
    _cuts.sort((a, b)=>a - b);
    cuts_set(_cuts);
    if (!_cuts.length)
      return;
    let _cut_idx = _cuts.findIndex(cut=>cut > rec_frame);
    if (_cut_idx == -1)
      _cut_idx = 0;
    rec_frame_set(_cuts[_cut_idx]);
  }, [lbin?.rec_monitor_in?.tracks, query, rec_frame, rec_frame_set]);
  return (
    <Form style={{maxWidth: '200px', width: '100%', display: 'flex',
      background: theme.gray7}} onFinish={submit_handle}>
      <Input variant="borderless" size="small" allowClear
        styles={{input: {borderRadius: 0}}}
        value={query} onChange={change_handle}
        prefix={<SearchOutlined style={{color: '#2A2A2A'}} />} />
      <Editor_btn style={{minWidth: '18px'}} onClick={prev_find} type="button">
        <Icon src={left_arrow_icon} />
      </Editor_btn>
      <Editor_btn style={{minWidth: '18px'}} onClick={next_find} type="button">
        <Icon src={right_arrow_icon} />
      </Editor_btn>
    </Form>
  );
});
let min_zoom = -2;
let max_zoom = 12;
let zoom_delta = max_zoom - min_zoom;
let zoom_step = 1;
let Zoom_ctrl = React.memo(({zoom, zoom_set})=>{
  let range_width = 260;
  let pin_width = 40;
  let pad = 2;
  let available_space = range_width - pin_width - pad * 2;
  let [is_dragging, is_dragging_set] = useState(false);
  let [shift, shift_set] = useState(0);
  let container_ref = useRef(null);
  let pin_ref = useRef(null);
  let zoom_out_handle = useCallback(()=>{
    let _zoom = Math.round(zoom - zoom_step);
    _zoom = xutil.clamp(_zoom, min_zoom, max_zoom);
    zoom_set(_zoom);
  }, [zoom, zoom_set]);
  let zoom_in_handle = useCallback(()=>{
    let _zoom = Math.round(zoom + zoom_step);
    _zoom = xutil.clamp(_zoom, min_zoom, max_zoom);
    zoom_set(_zoom);
  }, [zoom, zoom_set]);
  let mouse_down_handle = useCallback(e=>{
    is_dragging_set(true);
    shift_set(e.clientX - coords_get(pin_ref.current).left);
  }, []);
  let mouse_move_handle = useCallback(e=>{
    let container = container_ref.current;
    let container_offset = container.offsetLeft;
    let _zoom = min_zoom +
      (e.clientX - container_offset - shift - pad) / available_space
      * zoom_delta;
    _zoom = xutil.clamp(_zoom, min_zoom, max_zoom);
    zoom_set(_zoom);
  }, [available_space, pad, shift, zoom_set]);
  let mouse_up_handle = useCallback(()=>{
    is_dragging_set(false);
  }, []);
  useEffect(()=>{
    if (!is_dragging)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [is_dragging, mouse_move_handle, mouse_up_handle]);
  let action2func = useMemo(()=>({
    zoom_in: zoom_in_handle,
    zoom_out: zoom_out_handle,
  }), [zoom_in_handle, zoom_out_handle]);
  useEffect(()=>{
    let unsubscribe = tinykeys(window, key_binding_map_get(action2func));
    return ()=>unsubscribe();
  }, [action2func, zoom_in_handle, zoom_out_handle]);
  let left = pad + (zoom - min_zoom) / zoom_delta * available_space;
  return (
    <div style={{display: 'flex'}}>
      <Editor_btn key_bind={action2key_bind.zoom_out}
        onClick={zoom_out_handle}>
        <Icon src={zoom_out_icon} />
      </Editor_btn>
      <div ref={container_ref} style={{background: theme.gray11,
        padding: `${pad}px`, width: `${range_width}px`, position: 'relative'}}>
        <div ref={pin_ref}
          style={{position: 'absolute', background: theme.gray6,
            left: `${left}px`, borderRadius: '4px', cursor: 'pointer',
            width: `${pin_width}px`, height: `calc(100% - ${pad * 2}px)`}}
          onMouseDown={mouse_down_handle} />
      </div>
      <Editor_btn key_bind={action2key_bind.zoom_in} onClick={zoom_in_handle}>
        <Icon src={zoom_in_icon} />
      </Editor_btn>
    </div>
  );
});
let H_scroll_ctrl = React.memo(({lbin, scroll_x, scroll_x_set,
  tracks_wrapper_width, tracks_container_width, is_v_scroll_visible, f2px_k,
  zoom})=>{
  let [is_dragging, is_dragging_set] = useState(false);
  let [shift, shift_set] = useState(0);
  let [container_width, container_width_set] = useState();
  let container_ref = useRef(null);
  let pin_ref = useRef(null);
  let pad = 2;
  let min_scroll = 0;
  let max_scroll = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    return lbin.rec_monitor_in.len
    - player.px2f(tracks_wrapper_width, f2px_k, zoom) / 2;
  }, [f2px_k, lbin?.rec_monitor_in?.len, tracks_wrapper_width, zoom]);
  let scroll_step = max_scroll / 10;
  let min_pin_width = 25;
  let pin_width = (container_width - pad * 2) * tracks_wrapper_width
    / tracks_container_width;
  pin_width = Math.max(pin_width, min_pin_width);
  let available_space = container_width - pin_width - pad * 2;
  let scroll_x_left_handle = useCallback(()=>{
    scroll_x_set(scroll_x - scroll_step);
  }, [scroll_x, scroll_x_set, scroll_step]);
  let scroll_x_right_handle = useCallback(()=>{
    scroll_x_set(scroll_x + scroll_step);
  }, [scroll_x, scroll_x_set, scroll_step]);
  let mouse_down_handle = useCallback(e=>{
    is_dragging_set(true);
    shift_set(e.clientX - coords_get(pin_ref.current).left);
  }, []);
  let mouse_move_handle = useCallback(e=>{
    let container = pin_ref.current.parentElement;
    let container_offset = container.offsetLeft;
    let scroll_x_rel = (e.clientX - container_offset - shift - pad)
      / available_space;
    scroll_x_rel = isFinite(scroll_x_rel) ? scroll_x_rel : 0;
    let _scroll_x = min_scroll + max_scroll * scroll_x_rel;
    scroll_x_set(_scroll_x);
  }, [shift, pad, available_space, scroll_x_set, min_scroll, max_scroll]);
  let mouse_up_handle = useCallback(()=>{
    is_dragging_set(false);
  }, []);
  let window_resize_handle = useCallback(()=>{
    container_width_set(container_ref.current?.offsetWidth || 0);
  }, []);
  useEffect(window_resize_handle, [is_v_scroll_visible, window_resize_handle]);
  useEffect(()=>{
    window_resize_handle();
    window.addEventListener('resize', window_resize_handle);
    return ()=>window.removeEventListener('resize', window_resize_handle);
  }, [window_resize_handle]);
  useEffect(()=>{
    if (!is_dragging)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [is_dragging, mouse_move_handle, mouse_up_handle]);
  let left = pad + (scroll_x * available_space / max_scroll || 0);
  return (
    <div style={{display: 'flex', width: '100%'}}>
      <Editor_btn onClick={scroll_x_left_handle} style={{minWidth: '18px'}}>
        <Icon src={left_arrow_icon} />
      </Editor_btn>
      <div
        ref={container_ref}
        style={{background: theme.gray11, padding: `${pad}px`, width: '100%',
          position: 'relative'}}
      >
        <div
          ref={pin_ref}
          style={{position: 'absolute', left: `${left}px`,
            background: theme.gray6, borderRadius: '4px', cursor: 'pointer',
            width: `${pin_width}px`, height: `calc(100% - ${pad * 2}px)`,
            display: !available_space && 'none'}}
          onMouseDown={mouse_down_handle}
        />
      </div>
      <Editor_btn onClick={scroll_x_right_handle} style={{minWidth: '18px'}}>
        <Icon src={right_arrow_icon} />
      </Editor_btn>
    </div>
  );
});
let V_scroll_ctrl = React.memo(({is_visible, scroll_y, scroll_y_set,
  tracks_total_height, tracks_pad, tracks_wrapper_height})=>{
  let [is_dragging, is_dragging_set] = useState(false);
  let [shift, shift_set] = useState(0);
  let [container_height, container_height_set] = useState(0);
  let container_ref = useRef(null);
  let pin_ref = useRef(null);
  let scroll_width = 18;
  let pad = 2;
  let min_scroll = 0;
  let max_scroll = tracks_total_height + tracks_pad * 3 - tracks_wrapper_height;
  let scroll_step = max_scroll / 10;
  let min_pin_height = 25;
  let pin_height = (container_height - pad * 2)
    * (tracks_wrapper_height - tracks_pad)
    / (tracks_total_height + tracks_pad * 2);
  pin_height = Math.max(pin_height, min_pin_height);
  let available_space = container_height - pin_height - pad * 2;
  let scroll_up_handle = useCallback(()=>{
    scroll_y_set(scroll_y - scroll_step);
  }, [scroll_y, scroll_y_set, scroll_step]);
  let scroll_down_handle = useCallback(()=>{
    scroll_y_set(scroll_y + scroll_step);
  }, [scroll_y, scroll_y_set, scroll_step]);
  let mouse_down_handle = useCallback(e=>{
    is_dragging_set(true);
    shift_set(e.clientY - coords_get(pin_ref.current).top + window.scrollY);
  }, []);
  let mouse_move_handle = useCallback(e=>{
    let container = pin_ref.current.parentElement;
    let container_offset = coords_get(container).top - window.scrollY;
    let scroll_y_rel = (e.clientY - container_offset - shift - pad)
      / available_space;
    scroll_y_rel = isFinite(scroll_y_rel) ? scroll_y_rel : 0;
    let _scroll_y = min_scroll + max_scroll * scroll_y_rel;
    scroll_y_set(_scroll_y);
  }, [shift, pad, available_space, scroll_y_set, min_scroll, max_scroll]);
  let mouse_up_handle = useCallback(()=>{
    is_dragging_set(false);
  }, []);
  let window_resize_handle = useCallback(()=>{
    container_height_set(container_ref.current?.offsetHeight || 0);
  }, []);
  useEffect(()=>{
    window_resize_handle();
    window.addEventListener('resize', window_resize_handle);
    return ()=>window.removeEventListener('resize', window_resize_handle);
  }, [window_resize_handle]);
  useEffect(window_resize_handle, [window_resize_handle, is_visible]);
  useEffect(()=>{
    if (!is_dragging)
      return;
    document.addEventListener('mousemove', mouse_move_handle);
    document.addEventListener('mouseup', mouse_up_handle);
    return ()=>{
      document.removeEventListener('mousemove', mouse_move_handle);
      document.removeEventListener('mouseup', mouse_up_handle);
    };
  }, [is_dragging, mouse_move_handle, mouse_up_handle]);
  let top = pad + (scroll_y * available_space / max_scroll || 0);
  if (!is_visible)
    return null;
  return (
    <div
      style={{display: 'flex', flexDirection: 'column', height: '100%',
        width: `${scroll_width}px`}}
    >
      <Editor_btn style={{minWidth: '18px'}} onClick={scroll_up_handle}>
        <Icon src={up_arrow_icon} style={{width: '8px'}} />
      </Editor_btn>
      <div
        ref={container_ref}
        style={{background: theme.gray11, padding: `${pad}px`, height: '100%',
          width: '100%', position: 'relative'}}
      >
        <div
          ref={pin_ref}
          style={{position: 'absolute', top: `${top}px`,
            background: theme.gray6, borderRadius: '4px', cursor: 'pointer',
            width: `calc(100% - ${pad * 2}px)`, height: `${pin_height}px`}}
          onMouseDown={mouse_down_handle}
        />
      </div>
      <Editor_btn style={{minWidth: '18px'}} onClick={scroll_down_handle}>
        <Icon src={down_arrow_icon} style={{width: '8px'}} />
      </Editor_btn>
    </div>
  );
});
let Fast_menu_btn = React.memo(({cmts_panel_open_set, toggle_fullscreen,
  aaf_in, token, user, shortcuts_modal_open, lbin})=>{
  let {t} = useTranslation();
  let container_ref = useRef(null);
  let dropdown_items = useMemo(()=>[
    {key: 'default_setup', label: t('Default Setup'), disabled: true},
    {type: 'divider'},
    {key: 'view_type', label: t('View Type'), disabled: true},
    {type: 'divider'},
    {key: 'track_panel', label: t('Track Panel'), disabled: true},
    {key: 'effect_icons', label: t('Effect Icons'), disabled: true},
    {key: 'render_rangers', label: t('Render Rangers'), disabled: true},
    {key: 'dissolve_icons', label: t('Dissolve Icons'), disabled: true},
    {key: 'clip_frames', label: t('Clip Frames'), disabled: true},
    {key: 'clip_text', label: t('Clip Text'), disabled: true},
    {key: 'sync_breaks', label: t('Sync Breaks'), disabled: true},
    {key: 'dupe_detection', label: t('Dupe Detection'), disabled: true},
    {key: 'color_correction', label: t('Color Correction'), disabled: true},
    {key: 'audio_data', label: t('Audio Data'), disabled: true},
    {type: 'divider'},
    {key: 'clip_color', label: t('Clip Color...'), disabled: true},
    {key: 'segment_selection', label: t('Segment Selection'), disabled: true},
    {key: 'mark_io_selection', label: t('Mark I/O Selection'), disabled: true},
    {key: 'track_color', label: t('Track Color'), disabled: true},
    {type: 'divider'},
    {key: 'show_markers', label: t('Show Markers'), disabled: true},
    {key: 'show_adapters', label: t('Show Adapters'), disabled: true},
    {type: 'divider'},
    {key: 'show_track', label: t('Show Track'), disabled: true},
    {key: 'track_control_panel', label: t('Track Control Panel'),
      disabled: true},
    {key: 'zoom', label: t('Zoom'), disabled: true},
    {type: 'divider'},
    {key: 'shortcuts', label: t('Shortcuts')},
    {key: 'export', label: t('Export')},
    {key: 'open_comments', label: t('Open Comments')},
    {key: 'toggle_fullscreen', label: t('Toggle Fullscreen')},
  ], [t]);
  let aaf_export = useCallback(()=>{
    if (!token || !lbin || !aaf_in || !user?.email)
      return;
    let filename = lbin.rec_monitor_in.lbl + '.aaf';
    let url = config_ext.back.app.url + xurl.url('/private/aaf/get.aaf',
      {file: aaf_in, email: user.email, token, ver: config_ext.ver,
        filename});
    download(url, filename);
  }, [aaf_in, token, user?.email, lbin]);
  let click_handle = useCallback(({key})=>{
    if (key == 'shortcuts')
      shortcuts_modal_open();
    if (key == 'export')
      aaf_export();
    if (key == 'open_comments' && cmts_panel_open_set)
      cmts_panel_open_set(true);
    if (key == 'toggle_fullscreen' && toggle_fullscreen)
      toggle_fullscreen();
  }, [aaf_export, cmts_panel_open_set, shortcuts_modal_open,
    toggle_fullscreen]);
  return (
    <div ref={container_ref}>
      <Dropdown getPopupContainer={()=>container_ref.current}
        menu={{items: dropdown_items, onClick: click_handle}}
        trigger={['click']} placement="topRight">
        <Editor_btn style={{height: '100%'}}>
          <Icon src={fast_menu_icon} />
        </Editor_btn>
      </Dropdown>
    </div>
  );
});
let Timeline_lower_toolbar = React.memo(({lbin, fps, zoom, f2px_k, zoom_set,
  scroll_x, scroll_x_set, tracks_wrapper_width, tracks_container_width, user,
  is_v_scroll_visible, cmts_panel_open_set, toggle_fullscreen,
  rec_frame, rec_frame_set, aaf_in, token, shortcuts_modal_open})=>{
  let {t} = useTranslation();
  let [timeline_mode, timeline_mode_set] = use_je('editor.timeline_mode',
    'rec');
  let toggle_timeline_mode = useCallback(()=>{
    timeline_mode_set(timeline_mode == 'rec' ? 'src' : 'rec');
  }, [timeline_mode, timeline_mode_set]);
  return (
    <div style={{display: 'flex', background: theme.gray11, width: '100%',
      height: '20px'}}>
      <Fast_menu_btn cmts_panel_open_set={cmts_panel_open_set} user={user}
        toggle_fullscreen={toggle_fullscreen} aaf_in={aaf_in} token={token}
        shortcuts_modal_open={shortcuts_modal_open} lbin={lbin} />
      <Seq_info lbl={lbin?.rec_monitor_in?.lbl}
        resolution={lbin?.rec_monitor_in?.resolution} fps={fps} />
      <div style={{display: 'flex', width: '100%'}}>
        <Editor_btn style={{minWidth: '18px'}} title={t('Focus')} disabled>
          <Focus_icon />
        </Editor_btn>
        <Editor_btn style={{minWidth: '18px',
          color: timeline_mode == 'rec' ? '#000' : theme.green}}
        onClick={toggle_timeline_mode}
        title={t('Toggle Source/Record In Timeline')}>
          <Toggle_src_rec_icon />
        </Editor_btn>
        <Editor_btn style={{minWidth: '18px'}} title={t('Video Quality Menu')}
          disabled>
          <Video_quality_menu_colorful_icon />
        </Editor_btn>
        <Editor_btn style={{minWidth: '18px'}} disabled
          title={t('Toggle Client Monitor')}>
          <Toggle_client_monitor_icon />
        </Editor_btn>
        <Editor_btn style={{minWidth: '18px'}} title={t('Step In')} disabled>
          <Step_in_icon />
        </Editor_btn>
        <Editor_btn style={{minWidth: '18px'}} title={t('Step Out')} disabled>
          <Step_out_icon />
        </Editor_btn>
      </div>
      <Search lbin={lbin} rec_frame={rec_frame} rec_frame_set={rec_frame_set} />
      <Zoom_ctrl zoom={zoom} zoom_set={zoom_set} />
      <H_scroll_ctrl lbin={lbin} fps={fps} scroll_x={scroll_x}
        scroll_x_set={scroll_x_set} zoom={zoom}
        f2px_k={f2px_k} tracks_wrapper_width={tracks_wrapper_width}
        tracks_container_width={tracks_container_width}
        is_v_scroll_visible={is_v_scroll_visible} />
      {is_v_scroll_visible && <div style={{minWidth: '18px'}} />}
    </div>
  );
});
let tracks_container_pad = 26;
export let Timeline_panel = React.memo(({lbin, zoom: ext_zoom, on_zoom_change,
  scroll_x: ext_scroll_x, on_scroll_x_change, cmd_insert, token, aaf_in,
  tracks_wrapper_width: ext_tracks_wrapper_width, track_add_modal_open, user,
  nested_track_paths: ext_nested_track_paths, on_nested_track_paths_change,
  on_tracks_wrapper_width_change, select_to_the_left, select_to_the_right,
  tracks_wrapper_height: ext_tracks_wrapper_height, select_in_out,
  on_tracks_wrapper_height_change, rec_frame: ext_rec_frame,
  src_frame: ext_src_frame, on_rec_frame_change,
  rec_playback_rate: ext_rec_playback_rate,
  on_rec_playback_rate_change, on_src_frame_change,
  on_cmts_panel_toggle, toggle_fullscreen, cmd_track_add,
  cmd_mark_in, cmd_mark_out, cmd_cut, cmd_mark_clip, cmd_clear_both_marks,
  cmd_lift, cmd_extract, cmd_selector_set, cmd_power, cmd_solo, cmd_mute,
  cmd_synclock, cmd_lock, marker_modal_open, cmd_clip_move_insert,
  play_in_to_out, cmd_track_remove,
  trim_toggle, cmd_clip_move, trim_drag_start_handle, trim_shift,
  on_src_playback_rate_change, cmd_match_frame, toggle_selection_tool,
  toggle_insertion_tool, src_playback_rate, src_playback_rate_set,
  shortcuts_modal_open, ...rest})=>{
  let [timeline_mode] = use_je('editor.timeline_mode', 'rec');
  let [message_api, message_ctx_holder] = message.useMessage();
  let {t} = useTranslation();
  let is_nested_track_paths_controlled = useMemo(()=>{
    return typeof ext_nested_track_paths != 'undefined';
  }, [ext_nested_track_paths]);
  let [int_nested_track_paths, int_nested_track_paths_set] = useState(
    is_nested_track_paths_controlled ? ext_nested_track_paths : {});
  let nested_track_paths = useMemo(()=>{
    return is_nested_track_paths_controlled ? ext_nested_track_paths
      : int_nested_track_paths;
  }, [ext_nested_track_paths, int_nested_track_paths,
    is_nested_track_paths_controlled]);
  let is_zoom_controlled = useMemo(()=>{
    return typeof ext_zoom != 'undefined';
  }, [ext_zoom]);
  let [int_zoom, int_zoom_set] = useState(is_zoom_controlled ? ext_zoom : 0);
  let zoom = useMemo(()=>{
    return is_zoom_controlled ? ext_zoom : int_zoom;
  }, [ext_zoom, int_zoom, is_zoom_controlled]);
  let is_scroll_x_controlled = useMemo(()=>{
    return typeof ext_scroll_x != 'undefined';
  }, [ext_scroll_x]);
  let [int_scroll_x, int_scroll_x_set] = useState(
    is_scroll_x_controlled ? ext_zoom : 0);
  let scroll_x = useMemo(()=>{
    return is_scroll_x_controlled ? ext_scroll_x : int_scroll_x;
  }, [ext_scroll_x, int_scroll_x, is_scroll_x_controlled]);
  let is_tracks_wrapper_width_controlled = useMemo(()=>{
    return typeof ext_tracks_wrapper_width != 'undefined';
  }, [ext_tracks_wrapper_width]);
  let [int_tracks_wrapper_width, int_tracks_wrapper_width_set] = useState(
    is_tracks_wrapper_width_controlled ? ext_tracks_wrapper_width :
      undefined);
  let tracks_wrapper_width = useMemo(()=>{
    return is_tracks_wrapper_width_controlled
      ? ext_tracks_wrapper_width : int_tracks_wrapper_width;
  }, [ext_tracks_wrapper_width, int_tracks_wrapper_width,
    is_tracks_wrapper_width_controlled]);
  let is_tracks_wrapper_height_controlled = useMemo(()=>{
    return typeof ext_tracks_wrapper_height != 'undefined';
  }, [ext_tracks_wrapper_height]);
  let [int_tracks_wrapper_height, int_tracks_wrapper_height_set] = useState(
    is_tracks_wrapper_height_controlled ? ext_tracks_wrapper_height :
      undefined);
  let tracks_wrapper_height = useMemo(()=>{
    return is_tracks_wrapper_height_controlled
      ? ext_tracks_wrapper_height : int_tracks_wrapper_height;
  }, [ext_tracks_wrapper_height, int_tracks_wrapper_height,
    is_tracks_wrapper_height_controlled]);
  let is_rec_frame_controlled = useMemo(()=>{
    return typeof ext_rec_frame != 'undefined';
  }, [ext_rec_frame]);
  let is_src_frame_controlled = useMemo(()=>{
    return typeof ext_src_frame != 'undefined';
  }, [ext_src_frame]);
  let [int_rec_frame, int_rec_frame_set] = useState(
    is_rec_frame_controlled ? ext_rec_frame : 0);
  let [int_src_frame, int_src_frame_set] = useState(
    is_src_frame_controlled ? ext_src_frame : 0);
  let rec_frame = useMemo(()=>{
    return is_rec_frame_controlled ? ext_rec_frame : int_rec_frame;
  }, [ext_rec_frame, int_rec_frame, is_rec_frame_controlled]);
  let src_frame = useMemo(()=>{
    return is_src_frame_controlled ? ext_src_frame : int_src_frame;
  }, [ext_src_frame, int_src_frame, is_src_frame_controlled]);
  let is_rec_playback_rate_controlled = useMemo(()=>{
    return typeof ext_rec_playback_rate != 'undefined';
  }, [ext_rec_playback_rate]);
  let [int_rec_playback_rate, int_rec_playback_rate_set] = useState(
    is_rec_playback_rate_controlled ? ext_rec_playback_rate : 0);
  let rec_playback_rate = useMemo(()=>{
    return is_rec_playback_rate_controlled
      ? ext_rec_playback_rate : int_rec_playback_rate;
  }, [ext_rec_playback_rate, int_rec_playback_rate,
    is_rec_playback_rate_controlled]);
  let fps = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.editrate?.d
      || !lbin?.rec_monitor_in?.editrate?.n)
    {
      return 0;
    }
    return lbin.rec_monitor_in.editrate.n / lbin.rec_monitor_in.editrate.d;
  }, [lbin?.rec_monitor_in?.editrate?.d, lbin?.rec_monitor_in?.editrate?.n]);
  let f2px_k = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    if (lbin.rec_monitor_in.len == 0)
      return 1;
    return lbin.rec_monitor_in.len / tracks_wrapper_width;
  }, [lbin?.rec_monitor_in?.len, tracks_wrapper_width]);
  let tracks_container_width = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    let _tracks_container_width = player.f2px(lbin.rec_monitor_in.len, f2px_k,
      zoom);
    _tracks_container_width += tracks_wrapper_width / 2;
    if (_tracks_container_width < tracks_wrapper_width)
      _tracks_container_width = tracks_wrapper_width;
    return _tracks_container_width;
  }, [f2px_k, lbin?.rec_monitor_in?.len, tracks_wrapper_width, zoom]);
  let visible_tracks = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    let _visible_tracks = visible_tracks_get(lbin.rec_monitor_in,
      nested_track_paths);
    return _visible_tracks;
  }, [lbin, nested_track_paths]);
  let tracks_total_height = useMemo(()=>{
    return editor.tracks_total_height_get(visible_tracks);
  }, [visible_tracks]);
  let tracks_container_height = useMemo(()=>{
    return tracks_total_height + tracks_container_pad * 3;
  }, [tracks_total_height]);
  let is_v_scroll_visible = useMemo(()=>{
    return tracks_wrapper_height <= tracks_container_height;
  }, [tracks_container_height, tracks_wrapper_height]);
  let cuts = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return editor.cuts_get(lbin.rec_monitor_in.tracks);
  }, [lbin?.rec_monitor_in?.tracks]);
  let markers_pos = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return editor.markers_pos_get(lbin.rec_monitor_in.tracks);
  }, [lbin?.rec_monitor_in?.tracks]);
  let [rec_editing_track_ids, rec_editing_track_ids_set] = use_je(
    'editor.rec_editing_track_ids', []);
  let [src_editing_track_ids] = use_je(
    'editor.rec_editing_track_ids', []);
  let [focused_monitor] = use_je('editor.focused_monitor', 'rec');
  let [rec_monitor_split,
    rec_monitor_split_set] = use_je('editor.rec_monitor_split', 'mono');
  let zoom_change_handle = useCallback(_zoom=>{
    if (on_zoom_change)
      on_zoom_change(_zoom);
    if (!is_zoom_controlled)
      int_zoom_set(_zoom);
    let _scroll_x = rec_frame
      - player.px2f(tracks_wrapper_width, f2px_k, _zoom) / 2;
    if (_scroll_x < 0)
      _scroll_x = 0;
    if (on_scroll_x_change)
      on_scroll_x_change(_scroll_x);
    if (!is_scroll_x_controlled)
      int_scroll_x_set(_scroll_x);
  }, [on_zoom_change, is_zoom_controlled, rec_frame, tracks_wrapper_width,
    f2px_k, on_scroll_x_change, is_scroll_x_controlled]);
  let scroll_x_change_handle = useCallback(_scroll_x=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(tracks_wrapper_width, f2px_k, zoom) / 2;
    _scroll_x = xutil.clamp(_scroll_x, min_scroll, max_scroll);
    if (on_scroll_x_change)
      on_scroll_x_change(_scroll_x);
    if (!is_scroll_x_controlled)
      int_scroll_x_set(_scroll_x);
  }, [f2px_k, is_scroll_x_controlled, lbin?.rec_monitor_in?.len,
    on_scroll_x_change, tracks_wrapper_width, zoom]);
  let tracks_wrapper_width_handle = useCallback(_tracks_wrapper_width=>{
    if (on_tracks_wrapper_width_change)
      on_tracks_wrapper_width_change(_tracks_wrapper_width);
    if (!is_tracks_wrapper_width_controlled)
      int_tracks_wrapper_width_set(_tracks_wrapper_width);
  }, [is_tracks_wrapper_width_controlled, on_tracks_wrapper_width_change]);
  let tracks_wrapper_height_handle = useCallback(_tracks_wrapper_height=>{
    if (on_tracks_wrapper_height_change)
      on_tracks_wrapper_height_change(_tracks_wrapper_height);
    if (!is_tracks_wrapper_height_controlled)
      int_tracks_wrapper_height_set(_tracks_wrapper_height);
  }, [is_tracks_wrapper_height_controlled, on_tracks_wrapper_height_change]);
  let rec_playback_rate_handle = useCallback(_rec_playback_rate=>{
    if (on_rec_playback_rate_change)
      on_rec_playback_rate_change(_rec_playback_rate);
    if (!is_rec_playback_rate_controlled)
      int_rec_playback_rate_set(_rec_playback_rate);
  }, [is_rec_playback_rate_controlled, on_rec_playback_rate_change]);
  let rec_frame_handle = useCallback((_rec_frame, ignore_clamp, scroll)=>{
    if (lbin?.rec_monitor_in?.start === undefined
      || lbin?.rec_monitor_in?.len === undefined)
    {
      return;
    }
    _rec_frame = Math.floor(_rec_frame);
    if (!ignore_clamp)
    {
      let min_frame = lbin.rec_monitor_in.start;
      let max_frame = lbin.rec_monitor_in.len;
      _rec_frame = xutil.clamp(_rec_frame, min_frame, max_frame);
    }
    if (on_rec_frame_change)
      on_rec_frame_change(_rec_frame);
    if (!is_rec_frame_controlled)
      int_rec_frame_set(_rec_frame);
    if (!scroll)
      return;
    let frames_visible = player.px2f(tracks_wrapper_width, f2px_k, zoom);
    if (_rec_frame < scroll_x || _rec_frame > scroll_x + frames_visible)
      scroll_x_change_handle(_rec_frame - frames_visible / 2);
  }, [f2px_k, is_rec_frame_controlled, lbin?.rec_monitor_in?.len,
    lbin?.rec_monitor_in?.start, on_rec_frame_change, scroll_x,
    scroll_x_change_handle, tracks_wrapper_width, zoom]);
  let src_frame_handle = useCallback((_src_frame, ignore_clamp, scroll)=>{
    if (lbin?.src_monitor?.start === undefined
      || lbin?.src_monitor?.len === undefined)
    {
      return;
    }
    _src_frame = Math.floor(_src_frame);
    if (!ignore_clamp)
    {
      let min_frame = lbin.src_monitor.start;
      let max_frame = lbin.src_monitor.len;
      _src_frame = xutil.clamp(_src_frame, min_frame, max_frame);
    }
    if (on_src_frame_change)
      on_src_frame_change(_src_frame);
    if (!is_src_frame_controlled)
      int_src_frame_set(_src_frame);
    if (!scroll)
      return;
    let frames_visible = player.px2f(tracks_wrapper_width, f2px_k, zoom);
    if (_src_frame < scroll_x || _src_frame > scroll_x + frames_visible)
      scroll_x_change_handle(_src_frame - frames_visible / 2);
  }, [f2px_k, is_src_frame_controlled, lbin?.src_monitor?.len,
    lbin?.src_monitor?.start, on_src_frame_change, scroll_x,
    scroll_x_change_handle, tracks_wrapper_width, zoom]);
  let nested_track_paths_handle = useCallback(_nested_track_paths=>{
    if (on_nested_track_paths_change)
      on_nested_track_paths_change(_nested_track_paths);
    if (!is_nested_track_paths_controlled)
      int_nested_track_paths_set(_nested_track_paths);
  }, [is_nested_track_paths_controlled, on_nested_track_paths_change]);
  let rec_playing_toggle = useCallback(()=>{
    rec_playback_rate_handle(rec_playback_rate ? 0 : 1);
  }, [rec_playback_rate_handle, rec_playback_rate]);
  let src_playing_toggle = useCallback(()=>{
    src_playback_rate_set(src_playback_rate ? 0 : 1);
  }, [src_playback_rate_set, src_playback_rate]);
  let play_stop = useCallback(()=>{
    if (focused_monitor == 'src')
      src_playing_toggle();
    if (focused_monitor == 'rec')
      rec_playing_toggle();
  }, [focused_monitor, rec_playing_toggle, src_playing_toggle]);
  let src_play_backwards = useCallback(()=>{
    // XXX vladimir: move playback rate progression to comp.js
    let playback_rates = [-8, -5, -3, -2, -1, 0];
    if (src_playback_rate > 0)
      return src_playback_rate_set(playback_rates.at(-2));
    let idx = playback_rates.indexOf(src_playback_rate);
    let _playback_rate = playback_rates[idx - 1];
    if (!_playback_rate)
      _playback_rate = playback_rates[0];
    src_playback_rate_set(_playback_rate);
  }, [src_playback_rate, src_playback_rate_set]);
  let rec_play_backwards = useCallback(()=>{
    // XXX vladimir: move playback rate progression to comp.js
    let playback_rates = [-8, -5, -3, -2, -1, 0];
    if (rec_playback_rate > 0)
      return rec_playback_rate_handle(playback_rates.at(-2));
    let idx = playback_rates.indexOf(rec_playback_rate);
    let _playback_rate = playback_rates[idx - 1];
    if (!_playback_rate)
      _playback_rate = playback_rates[0];
    rec_playback_rate_handle(_playback_rate);
  }, [rec_playback_rate, rec_playback_rate_handle]);
  let play_backwards = useCallback(()=>{
    if (focused_monitor == 'src')
      src_play_backwards();
    if (focused_monitor == 'rec')
      rec_play_backwards();
  }, [focused_monitor, rec_play_backwards, src_play_backwards]);
  let stop_handle = useCallback(()=>{
    if (focused_monitor == 'src')
      src_playback_rate_set(0);
    if (focused_monitor == 'rec')
      rec_playback_rate_handle(0);
  }, [focused_monitor, rec_playback_rate_handle, src_playback_rate_set]);
  let src_play_handle = useCallback(()=>{
    // XXX vladimir: move playback rate progression to comp.js
    let playback_rates = [0, 1, 2, 3, 5, 8];
    if (src_playback_rate < 0)
      return src_playback_rate_set(playback_rates[0]);
    let idx = playback_rates.indexOf(src_playback_rate);
    let _playback_rate = playback_rates[idx + 1];
    if (!_playback_rate)
      _playback_rate = playback_rates.at(-1);
    src_playback_rate_set(_playback_rate);
  }, [src_playback_rate, src_playback_rate_set]);
  let rec_play_handle = useCallback(()=>{
    // XXX vladimir: move playback rate progression to comp.js
    let playback_rates = [0, 1, 2, 3, 5, 8];
    if (rec_playback_rate < 0)
      return rec_playback_rate_handle(playback_rates[0]);
    let idx = playback_rates.indexOf(rec_playback_rate);
    let _playback_rate = playback_rates[idx + 1];
    if (!_playback_rate)
      _playback_rate = playback_rates.at(-1);
    rec_playback_rate_handle(_playback_rate);
  }, [rec_playback_rate, rec_playback_rate_handle]);
  let play_handle = useCallback(()=>{
    if (focused_monitor == 'src')
      src_play_handle();
    if (focused_monitor == 'rec')
      rec_play_handle();
  }, [focused_monitor, rec_play_handle, src_play_handle]);
  let go_to_prev_cut = useCallback(()=>{
    if (!cuts.length)
      return;
    let _rec_frame = cuts.find((cut, index)=>{
      return cut < rec_frame && rec_frame <= cuts[index + 1];
    });
    if (!_rec_frame)
      _rec_frame = cuts.at(0);
    rec_frame_handle(_rec_frame, false, true);
    rec_playback_rate_handle(0);
  }, [rec_playback_rate_handle, cuts, rec_frame, rec_frame_handle]);
  let go_to_next_cut = useCallback(()=>{
    if (!cuts.length)
      return;
    let _rec_frame = cuts.find(cut=>rec_frame < cut);
    if (!_rec_frame)
      _rec_frame = cuts.at(-1);
    rec_frame_handle(_rec_frame, false, true);
    rec_playback_rate_handle(0);
  }, [cuts, rec_frame, rec_frame_handle, rec_playback_rate_handle]);
  let go_to_prev_marker = useCallback(()=>{
    if (!markers_pos.length)
      return;
    let min_pos = Math.min(...markers_pos);
    if (rec_frame <= min_pos)
      return;
    let max_pos = Math.max(...markers_pos);
    let _rec_frame;
    if (rec_frame > max_pos)
      _rec_frame = max_pos;
    else
    {
      _rec_frame = markers_pos.find((marker, index)=>{
        return marker < rec_frame && rec_frame <= markers_pos[index + 1];
      });
    }
    rec_frame_handle(_rec_frame, false, true);
  }, [markers_pos, rec_frame_handle, rec_frame]);
  let go_to_next_marker = useCallback(()=>{
    if (!markers_pos.length)
      return;
    let _rec_frame = markers_pos.find(marker=>rec_frame < marker);
    if (!_rec_frame)
      _rec_frame = markers_pos.at(-1);
    rec_frame_handle(_rec_frame, false, true);
  }, [markers_pos, rec_frame_handle, rec_frame]);
  let go_to_mark_in = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_in === undefined)
      return;
    rec_frame_handle(lbin.rec_monitor_in.mark_in, false, true);
  }, [lbin?.rec_monitor_in?.mark_in, rec_frame_handle]);
  let go_to_mark_out = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_out === undefined)
      return;
    rec_frame_handle(lbin.rec_monitor_in.mark_out, false, true);
  }, [lbin?.rec_monitor_in?.mark_out, rec_frame_handle]);
  let src_move_frame = useCallback(frame_shift=>{
    let _src_frame = src_frame + frame_shift;
    on_src_frame_change(_src_frame, false, true);
    on_src_playback_rate_change(0);
  }, [on_src_frame_change, on_src_playback_rate_change, src_frame]);
  let rec_move_frame = useCallback(frame_shift=>{
    let _rec_frame = rec_frame + frame_shift;
    rec_frame_handle(_rec_frame, false, true);
    rec_playback_rate_handle(0);
  }, [rec_frame, rec_frame_handle, rec_playback_rate_handle]);
  let move_one_frame_left = useCallback(()=>{
    if (focused_monitor == 'src')
      src_move_frame(-1);
    if (focused_monitor == 'rec')
      rec_move_frame(-1);
  }, [focused_monitor, rec_move_frame, src_move_frame]);
  let move_one_frame_right = useCallback(()=>{
    if (focused_monitor == 'src')
      src_move_frame(1);
    if (focused_monitor == 'rec')
      rec_move_frame(1);
  }, [focused_monitor, rec_move_frame, src_move_frame]);
  let move_ten_frames_left = useCallback(()=>{
    if (focused_monitor == 'src')
      src_move_frame(-10);
    if (focused_monitor == 'rec')
      rec_move_frame(-10);
  }, [focused_monitor, rec_move_frame, src_move_frame]);
  let move_ten_frames_right = useCallback(()=>{
    if (focused_monitor == 'src')
      src_move_frame(10);
    if (focused_monitor == 'rec')
      rec_move_frame(10);
  }, [focused_monitor, rec_move_frame, src_move_frame]);
  let mark_all_tracks = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return;
    let _rec_editing_track_ids = lbin.rec_monitor_in.tracks.map(track=>{
      return track.id;
    });
    rec_editing_track_ids_set(_rec_editing_track_ids);
  }, [rec_editing_track_ids_set, lbin?.rec_monitor_in?.tracks]);
  let unmark_all_tracks = useCallback(()=>{
    rec_editing_track_ids_set([]);
  }, [rec_editing_track_ids_set]);
  let rec_quad_split_toggle = useCallback(()=>{
    rec_monitor_split_set(rec_monitor_split == 'quad' ? 'mono' : 'quad');
  }, [rec_monitor_split, rec_monitor_split_set]);
  let rec_nine_split_toggle = useCallback(()=>{
    rec_monitor_split_set(rec_monitor_split == 'nine' ? 'mono' : 'nine');
  }, [rec_monitor_split, rec_monitor_split_set]);
  let insert = useCallback(()=>{
    if (rec_editing_track_ids.length != 1
      || lbin?.rec_monitor_in?.mark_in === undefined
      || lbin?.rec_monitor_in?.mark_out === undefined)
    {
      return;
    }
    if (timeline_mode == 'src')
    {
      if (src_editing_track_ids.length != 1
        || lbin?.src_monitor?.mark_in === undefined
        || lbin?.src_monitor?.mark_out === undefined)
      {
        return;
      }
      return cmd_insert(lbin.src_monitor.mob_id, rec_editing_track_ids[0],
        lbin.src_monitor.mark_in, lbin.src_monitor.mark_out);
    }
    if (timeline_mode == 'rec')
    {
      if (rec_editing_track_ids.length != 1
        || lbin?.rec_monitor_in?.mark_in === undefined
        || lbin?.rec_monitor_in?.mark_out === undefined)
      {
        return;
      }
      return cmd_insert(lbin.rec_monitor_in.mob_id, rec_editing_track_ids[0],
        lbin.rec_monitor_in.mark_in, lbin.rec_monitor_in.mark_out);
    }
    assert(0, 'unexpected timeline_mode: ' + timeline_mode);
  }, [cmd_insert, rec_editing_track_ids, lbin?.rec_monitor_in?.mark_in,
    lbin?.rec_monitor_in?.mark_out, lbin?.src_monitor?.mark_in,
    lbin?.src_monitor?.mark_out, timeline_mode, src_editing_track_ids,
    lbin?.src_monitor?.mob_id, lbin?.rec_monitor_in?.mob_id]);
  let video_track_add = useCallback(()=>{
    if (!lbin?.rec_monitor_in)
      return;
    let video_tracks = lbin.rec_monitor_in.tracks.filter(track=>{
      return track.type == 'timeline_track' && track.lbl.startsWith('V');
    });
    let track_num = new_track_num_get(video_tracks);
    let track_id = 'V' + track_num;
    let mob_id;
    if (timeline_mode == 'src')
      mob_id = lbin.src_monitor.mob_id;
    else if (timeline_mode == 'rec')
      mob_id = lbin.rec_monitor_in.mob_id;
    else
      assert(0, 'unexpected timeline_mode: ' + timeline_mode);
    cmd_track_add(mob_id, 'video', track_id);
  }, [cmd_track_add, lbin, timeline_mode]);
  let mono_audio_track_add = useCallback(()=>{
    if (!lbin?.rec_monitor_in)
      return;
    let audio_tracks = lbin.rec_monitor_in.tracks.filter(track=>{
      return track.type == 'timeline_track' && track.lbl.startsWith('A');
    });
    let track_num = new_track_num_get(audio_tracks);
    let track_id = 'A' + track_num;
    let mob_id;
    if (timeline_mode == 'src')
      mob_id = lbin.src_monitor.mob_id;
    else if (timeline_mode == 'rec')
      mob_id = lbin.rec_monitor_in.mob_id;
    else
      assert(0, 'unexpected timeline_mode: ' + timeline_mode);
    cmd_track_add(mob_id, 'audio', track_id);
  }, [cmd_track_add, lbin, timeline_mode]);
  let stereo_audio_track_add = useCallback(()=>{
    message_api.error(t('Stereo audio tracks are not supported'));
  }, [message_api, t]);
  let mark_in = useCallback(()=>{
    if (!lbin)
      return;
    if (timeline_mode == 'src' && lbin.src_monitor)
      return cmd_mark_in(lbin.src_monitor.mob_id, src_frame);
    if (timeline_mode == 'rec' && lbin.rec_monitor_in)
      return cmd_mark_in(lbin.rec_monitor_in.mob_id, rec_frame);
    assert(0, 'unexpected timeline_mode: ' + timeline_mode);
  }, [cmd_mark_in, lbin, rec_frame, src_frame, timeline_mode]);
  let mark_out = useCallback(()=>{
    if (!lbin)
      return;
    if (timeline_mode == 'src' && lbin.src_monitor)
      return cmd_mark_out(lbin.src_monitor.mob_id, src_frame);
    if (timeline_mode == 'rec' && lbin.rec_monitor_in)
      return cmd_mark_out(lbin.rec_monitor_in.mob_id, rec_frame);
    assert(0, 'unexpected timeline_mode: ' + timeline_mode);
  }, [cmd_mark_out, lbin, rec_frame, src_frame, timeline_mode]);
  let mark_clip = useCallback(()=>{
    if (!lbin)
      return;
    let data = {abs_frame: rec_frame};
    if (timeline_mode == 'src')
      data.mob_id_src = lbin.src_monitor.mob_id;
    else if (timeline_mode == 'rec')
      data.mob_id_rec = lbin.rec_monitor_in.mob_id;
    else
      assert(0, 'unexpected timeline_mode: ' + timeline_mode);
    cmd_mark_clip(lbin.rec_monitor_in.mob_id, rec_frame);
  }, [cmd_mark_clip, lbin, rec_frame, timeline_mode]);
  let clear_both_marks = useCallback(()=>{
    if (!lbin)
      return;
    let mob_id;
    if (timeline_mode == 'src')
      mob_id = lbin.src_monitor.mob_id;
    else if (timeline_mode == 'rec')
      mob_id = lbin.rec_monitor_in.mob_id;
    else
      assert(0, 'unexpected timeline_mode: ' + timeline_mode);
    cmd_clear_both_marks(mob_id);
  }, [cmd_clear_both_marks, lbin, timeline_mode]);
  let cut = useCallback(()=>{
    if (!lbin)
      return;
    if (timeline_mode == 'src' && lbin.src_monitor)
      return cmd_cut(lbin.src_monitor.mob_id, src_frame);
    if (timeline_mode == 'rec' && lbin.rec_monitor_in)
      return cmd_cut(lbin.rec_monitor_in.mob_id, rec_frame);
    assert(0, 'unexpected timeline_mode: ' + timeline_mode);
  }, [cmd_cut, lbin, rec_frame, src_frame, timeline_mode]);
  let lift = useCallback(()=>{
    if (timeline_mode == 'src')
    {
      if (lbin?.src_monitor?.mark_in === undefined
        || lbin?.src_monitor?.mark_out === undefined)
      {
        return;
      }
      cmd_lift(lbin.src_monitor.mob_id, lbin.src_monitor.mark_in,
        lbin.src_monitor.mark_out);
    }
    else if (timeline_mode == 'rec')
    {
      if (lbin?.rec_monitor_in?.mark_in === undefined
        || lbin?.rec_monitor_in?.mark_out === undefined)
      {
        return;
      }
      cmd_lift(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
        lbin.rec_monitor_in.mark_out);
    }
  }, [cmd_lift, lbin, timeline_mode]);
  let extract = useCallback(()=>{
    if (timeline_mode == 'src')
    {
      if (lbin?.src_monitor?.mark_in === undefined
        || lbin?.src_monitor?.mark_out === undefined)
      {
        return;
      }
      cmd_extract(lbin.src_monitor.mob_id, lbin.src_monitor.mark_in,
        lbin.src_monitor.mark_out);
    }
    else if (timeline_mode == 'rec')
    {
      if (lbin?.rec_monitor_in?.mark_in === undefined
        || lbin?.rec_monitor_in?.mark_out === undefined)
      {
        return;
      }
      cmd_extract(lbin.rec_monitor_in.mob_id, lbin.rec_monitor_in.mark_in,
        lbin.rec_monitor_in.mark_out);
    }
  }, [cmd_extract, lbin, timeline_mode]);
  let rec_editing_tracks = useMemo(()=>{
    if (!lbin?.rec_monitor_in)
      return [];
    let _rec_editing_tracks = [];
    for (let idx = 0; idx < lbin.rec_monitor_in.tracks.length; idx++)
    {
      let track = lbin.rec_monitor_in.tracks[idx];
      if (rec_editing_track_ids.includes(track.id))
        _rec_editing_tracks.push(track);
    }
    for (let parent_track_id in nested_track_paths)
    {
      let tracks = nested_tracks_get(lbin.rec_monitor_in, parent_track_id,
        nested_track_paths);
      for (let track of tracks)
      {
        if (rec_editing_track_ids.includes(track.id))
          _rec_editing_tracks.push(track);
      }
    }
    return _rec_editing_tracks;
  }, [rec_editing_track_ids, lbin, nested_track_paths]);
  let selector_change = useCallback(shift=>{
    for (let track of rec_editing_tracks)
    {
      let abs_starts_map = player.abs_starts_get(track);
      for (let [seg] of abs_starts_map.entries())
      {
        if (seg.type != 'selector' || seg.abs_start > rec_frame
          || seg.abs_start + seg.len < rec_frame)
        {
          continue;
        }
        let next_selector_idx = (seg.select_idx + shift) % seg.arr.length;
        if (next_selector_idx < 0)
          next_selector_idx += seg.arr.length;
        let mob_id;
        if (timeline_mode == 'src')
          mob_id = lbin.src_monitor.mob_id;
        else if (timeline_mode == 'rec')
          mob_id = lbin.rec_monitor_in.mob_id;
        else
          assert(0, 'unexpected timeline_mode: ' + timeline_mode);
        cmd_selector_set(mob_id, track.id, next_selector_idx,
          seg.abs_start);
        return;
      }
    }
  }, [rec_editing_tracks, cmd_selector_set, lbin, rec_frame, timeline_mode]);
  let action2func = useMemo(()=>({play_stop, play_backwards, stop: stop_handle,
    play: play_handle, go_to_prev_cut, go_to_next_cut, go_to_prev_marker,
    go_to_next_marker, go_to_mark_in, go_to_mark_out, move_one_frame_left,
    move_one_frame_right, move_ten_frames_left, move_ten_frames_right, mark_in,
    mark_out, mark_clip, clear_both_marks, cut, lift, extract,
    marker_add: ()=>marker_modal_open(),
    marker_add_green: ()=>marker_modal_open(marker_colors.green),
    marker_add_red: ()=>marker_modal_open(marker_colors.red),
    play_in_to_out,
    change_selector_prev: ()=>selector_change(-1),
    change_selector_next: ()=>selector_change(1),
    trim_mode: trim_toggle,
    trim_move_one_frame_left: ()=>trim_shift(-1),
    trim_move_one_frame_right: ()=>trim_shift(1),
    trim_move_ten_frames_left: ()=>trim_shift(-10),
    trim_move_ten_frames_right: ()=>trim_shift(10),
    video_track_add,
    mono_audio_track_add,
    stereo_audio_track_add,
    track_add: track_add_modal_open,
    mark_all_tracks,
    unmark_all_tracks,
    quad_split: rec_quad_split_toggle,
    nine_split: rec_nine_split_toggle,
    insert,
  }), [play_stop, play_backwards, stop_handle, play_handle,
    go_to_prev_cut, go_to_next_cut, go_to_prev_marker, go_to_next_marker,
    go_to_mark_in, go_to_mark_out, move_one_frame_left, move_one_frame_right,
    move_ten_frames_left, move_ten_frames_right, mark_in, mark_out,
    mark_clip, clear_both_marks, cut, lift, extract, selector_change,
    play_in_to_out, trim_toggle, track_add_modal_open,
    mark_all_tracks, unmark_all_tracks, rec_quad_split_toggle,
    rec_nine_split_toggle, insert, marker_modal_open, trim_shift,
    video_track_add, mono_audio_track_add, stereo_audio_track_add]);
  useEffect(()=>{
    let unsubscribe = tinykeys(window, key_binding_map_get(action2func));
    return ()=>unsubscribe();
  }, [action2func]);
  return (
    <Editor_panel style={{flexDirection: 'column'}} {...rest}>
      {message_ctx_holder}
      <Timeline_upper_toolbar cmd_mark_in={cmd_mark_in} lbin={lbin}
        cmd_mark_out={cmd_mark_out} cmd_cut={cmd_cut} cmd_lift={cmd_lift}
        cmd_mark_clip={cmd_mark_clip} cmd_extract={cmd_extract}
        cmd_clear_both_marks={cmd_clear_both_marks} rec_frame={rec_frame}
        select_to_the_left={select_to_the_left} src_frame={src_frame}
        select_to_the_right={select_to_the_right}
        select_in_out={select_in_out}
        toggle_selection_tool={toggle_selection_tool}
        toggle_insertion_tool={toggle_insertion_tool} />
      <Tracks_container lbin={lbin} fps={fps} aaf_in={aaf_in}
        playback_rate={rec_playback_rate} zoom={zoom} f2px_k={f2px_k}
        scroll_x={scroll_x} scroll_x_set={scroll_x_change_handle}
        rec_frame={rec_frame} playback_rate_set={rec_playback_rate_handle}
        rec_frame_set={rec_frame_handle} visible_tracks={visible_tracks}
        tracks_container_width={tracks_container_width} src_frame={src_frame}
        is_v_scroll_visible={is_v_scroll_visible} cmd_clip_move={cmd_clip_move}
        tracks_wrapper_width={tracks_wrapper_width}
        cmd_clip_move_insert={cmd_clip_move_insert}
        tracks_wrapper_width_set={tracks_wrapper_width_handle}
        tracks_container_height={tracks_container_height}
        tracks_wrapper_height={tracks_wrapper_height}
        tracks_wrapper_height_set={tracks_wrapper_height_handle}
        nested_track_paths={nested_track_paths}
        nested_track_paths_set={nested_track_paths_handle}
        tracks_pad={tracks_container_pad} cmd_track_remove={cmd_track_remove}
        tracks_total_height={tracks_total_height}
        cuts={cuts} cmd_selector_set={cmd_selector_set} cmd_power={cmd_power}
        cmd_solo={cmd_solo} cmd_mute={cmd_mute}
        cmd_synclock={cmd_synclock} cmd_lock={cmd_lock}
        cmd_match_frame={cmd_match_frame} src_frame_set={src_frame_handle}
        drag_start_handle={trim_drag_start_handle} />
      <Timeline_lower_toolbar lbin={lbin} fps={fps} zoom={zoom} f2px_k={f2px_k}
        zoom_set={zoom_change_handle} scroll_x={scroll_x} token={token}
        scroll_x_set={scroll_x_change_handle} aaf_in={aaf_in} user={user}
        tracks_wrapper_width={tracks_wrapper_width}
        tracks_container_width={tracks_container_width}
        is_v_scroll_visible={is_v_scroll_visible}
        shortcuts_modal_open={shortcuts_modal_open}
        cmts_panel_open_set={on_cmts_panel_toggle} rec_frame={rec_frame}
        toggle_fullscreen={toggle_fullscreen} rec_frame_set={rec_frame_handle}
      />
    </Editor_panel>
  );
});
let Cmt = React.memo(({ts, avatar, color, user, frame, editrate, msg,
  on_delete, on_select, allow_delete})=>{
  let {t} = useTranslation();
  let [modal, modal_ctx_holder] = Modal.useModal();
  let [is_hover, is_hover_set] = useState(false);
  let mouse_enter_handler = useCallback(()=>is_hover_set(true), []);
  let mouse_leave_handler = useCallback(()=>is_hover_set(false), []);
  let delete_handle = useCallback(e=>{
    e.stopPropagation();
    modal.confirm({
      title: t('Delete this comment?'),
      content: t('Are you sure you want to delete this comment?'),
      okText: t('Delete'), okButtonProps: {danger: true}, onOk: on_delete});
  }, [modal, on_delete, t]);
  let is_delete_btn_visible = useMemo(()=>{
    return allow_delete && is_hover;
  }, [allow_delete, is_hover]);
  let time_diff = time_diff_get(new Date(ts.insert), new Date());
  return (
    <>
      {modal_ctx_holder}
      <div style={{display: 'flex', flexDirection: 'column', cursor: 'pointer',
        padding: '16px 16px 0px', transition: '0.1s ease',
        background: is_hover ? theme.gray9 : theme.gray4}}
      onMouseEnter={mouse_enter_handler} onMouseLeave={mouse_leave_handler}
      onClick={on_select}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Avatar src={avatar} color={color} style={{width: '24px',
            height: '24px'}} />
          <span style={{fontSize: '14px', color: 'white', marginLeft: '10px'}}>
            {user}
          </span>
          <span style={{fontSize: '14px', color: theme.gray11,
            marginLeft: '10px'}}>
            {time_diff}
          </span>
        </div>
        <div style={{fontSize: '14px', marginTop: '6px'}}>
          <span style={{color: theme.green, fontWeight: 'bold'}}>
            {tc.frame2tc(frame, editrate)}
          </span>
          <span style={{color: 'white'}}>
            {' '}{msg}
          </span>
        </div>
        <div style={{marginTop: '6px', display: 'flex', height: '35px',
          justifyContent: 'flex-end'}}>
          {is_delete_btn_visible && <div style={{color: 'white', padding: '5px',
            height: '35px'}} onClick={delete_handle}>
            <DeleteOutlined />
          </div>}
        </div>
      </div>
    </>
  );
});
export let cmt_sort_methods = ['inserted_asc', 'inserted_desc', 'tc_asc',
  'user_asc'];
export let sort_method2lbl = sort_method=>{
  switch (sort_method)
  {
  case 'inserted_asc':
    return 'Oldest';
  case 'inserted_desc':
    return 'Newest';
  case 'tc_asc':
    return 'Timecode';
  case 'user_asc':
    return 'Commenter';
  default:
    metric.error('Unexpected sort method: ', sort_method);
    return null;
  }
};
export let cmts_sort = (cmts, sort_method)=>{
  return cmts.sort((a, b)=>{
    switch (sort_method)
    {
    case 'inserted_asc':
      return a.ts.insert - b.ts.insert;
    case 'inserted_desc':
      return b.ts.insert - a.ts.insert;
    case 'tc_asc':
      return a.frame - b.frame;
    case 'user_asc':
      return str.cmp(a.user_id, b.user_id);
    default:
      metric.error('Unexpected sort method: ', sort_method);
      return 0;
    }
  });
};
let Cmts_panel = React.memo(({lbin, editrate, fps, start_tc, rec_frame, cmts,
  cmts_panel_open_set, rec_frame_set, cmts_get, is_drawing, is_drawing_set,
  drawing_objs, drawing_objs_set, drawing_tool, drawing_tool_set, drawing_color,
  drawing_color_set, drawing_view_box, cmt_svg_set, token, user_full,
  rec_playback_rate_set})=>{
  let [message_api, message_ctx_holder] = message.useMessage();
  let {t} = useTranslation();
  let [msg, msg_set] = useState('');
  let [loading, loading_set] = useState(false);
  let [use_cur_tc, use_cur_tc_set] = useState(true);
  let [sort_method, sort_method_set] = useState('tc_asc');
  let form_ref = useRef(null);
  let close_handle = useCallback(()=>{
    cmts_panel_open_set(false);
  }, [cmts_panel_open_set]);
  let change_handle = useCallback(e=>{
    msg_set(e.target.value);
  }, []);
  let submit_handle = useCallback(e=>eserf(function* _submit_handle(){
    e.preventDefault();
    if (loading || !msg)
      return;
    loading_set(true);
    let _svg = null;
    if (drawing_objs.length && drawing_view_box)
    {
      _svg = svg.compose(drawing_objs, drawing_view_box.x,
        drawing_view_box.y);
    }
    let frame = use_cur_tc || is_drawing ? start_tc + rec_frame : start_tc;
    let _tc = tc.frame2tc(frame, editrate);
    let res = yield back_app.cmt.insert(token, msg, _svg, _tc, fps,
      lbin.rec_monitor_in.mob_id);
    loading_set(false);
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      metric.error('_submit_handle', res.err);
      return;
    }
    msg_set('');
    is_drawing_set(false);
    drawing_objs_set([]);
    cmt_svg_set(_svg);
    cmts_get();
  }), [cmt_svg_set, cmts_get, drawing_objs, drawing_objs_set, drawing_view_box,
    editrate, fps, is_drawing, is_drawing_set, lbin.rec_monitor_in.mob_id,
    loading, msg, rec_frame, start_tc, t, token, use_cur_tc, message_api]);
  let key_down_handle = useCallback(e=>{
    if (e.key != 'Enter' || e.shiftKey || !form_ref.current)
      return;
    e.preventDefault();
    form_ref.current.dispatchEvent(new Event('submit', {cancelable: true,
      bubbles: true}));
  }, []);
  let toggle_handle = useCallback(()=>{
    use_cur_tc_set(!use_cur_tc);
  }, [use_cur_tc]);
  let sort_method_change_handle = useCallback(opt=>{
    sort_method_set(opt.key);
  }, []);
  let dropdown_items = useMemo(()=>cmt_sort_methods.map(key=>{
    return {key, label: sort_method2lbl(key)};
  }), []);
  let draw_toggle = useCallback(()=>{
    is_drawing_set(!is_drawing);
  }, [is_drawing, is_drawing_set]);
  let drawing_tools = useMemo(()=>[
    {key: 'brush', icon: Brush_icon},
    {key: 'arrow', icon: Arrow_icon},
    {key: 'rect', icon: Rect_icon},
  ], []);
  let drawing_colors = useMemo(()=>[blue.primary, green[4], orange.primary,
    red.primary], []);
  return (
    <>
      {message_ctx_holder}
      <div style={{position: 'absolute', width: '390px',
        height: 'calc(100% - 20px)', top: 0, left: 0, background: theme.gray4,
        border: '1px solid black', display: 'flex', flexDirection: 'column',
        boxShadow: '0px 0px 36px #00000060', zIndex: 1}}>
        <div style={{display: 'flex', justifyContent: 'space-between',
          padding: '16px'}}>
          <span style={{color: 'white', fontSize: '18px', fontWeight: 'bold'}}>
            Comments
          </span>
          <Editor_btn bg={theme.gray4} hover_bg={theme.gray2}
            active_bg={theme.gray6} onClick={close_handle}
            style={{color: 'white', height: '30px', width: '30px'}}>
            <CloseOutlined />
          </Editor_btn>
        </div>
        <div style={{borderTop: '1px solid black', padding: '16px',
          display: 'flex', borderBottom: '1px solid black',
          justifyContent: 'space-between'}}>
          <Dropdown trigger={['click']}
            menu={{items: dropdown_items, onClick: sort_method_change_handle}}>
            <div style={{color: 'white', cursor: 'pointer'}}>
              <Space>
                <span>{sort_method2lbl(sort_method)}</span>
                <DownOutlined style={{fontSize: '12px'}} />
              </Space>
            </div>
          </Dropdown>
        </div>
        <div
          style={{display: 'flex', flexDirection: 'column', overflowY: 'auto'}}>
          {cmts_sort(cmts, sort_method).map((cmt, idx)=>{
            let select_handle = ()=>{
              rec_playback_rate_set(0);
              rec_frame_set(cmt.frame - start_tc, false, true);
              cmt_svg_set(cmt.svg);
            };
            let delete_handle = ()=>eserf(function* _delete_handle(){
              let res = yield back_app.cmt.delete(token, cmt._id);
              if (res.err)
              {
                message_api.error(t('Something went wrong'));
                metric.error('_delete_handle', res.err);
                return;
              }
              cmts_get();
            });
            return (
              <Cmt key={idx} ts={cmt.ts} avatar={cmt.avatar}
                user={cmt.user_id.split('@')[0]}
                frame={cmt.frame} editrate={editrate} msg={cmt.msg}
                on_select={select_handle} on_delete={delete_handle}
                allow_delete={user_full.id == cmt.user_id} />
            );
          })}
        </div>
        <div style={{display: 'flex', padding: '16px', gap: '10px'}}>
          <div>
            <Avatar src={user_full.img}
              style={{width: '24px', height: '24px'}} />
          </div>
          <form
            style={{display: 'flex', flexDirection: 'column', width: '100%',
              gap: '12px'}}
            onSubmit={submit_handle}
            ref={form_ref}
          >
            <textarea
              placeholder={t('Leave your comment here...')}
              value={msg}
              onChange={change_handle}
              rows={msg.split('\n').length}
              style={{background: 'none', border: 'none', outline: 'none',
                color: 'white', resize: 'none'}}
              onKeyDown={key_down_handle}
            ></textarea>
            <div style={{display: 'flex', justifyContent: 'space-between',
              height: '32px'}}>
              <div
                style={{padding: '4px 10px', borderRadius: '5px',
                  fontSize: '14px', cursor: 'pointer',
                  background: use_cur_tc || is_drawing
                    ? theme.gray10 : theme.gray9,
                  display: 'flex', alignItems: 'center', gap: '10px'}}
                onClick={toggle_handle}
              >
                <ClockCircleOutlined style={{
                  color: use_cur_tc || is_drawing ? purple.primary : 'white'}}
                />
                <span
                  style={{
                    color: use_cur_tc || is_drawing ? purple.primary : 'white'}}
                >
                  {tc.frame2tc(start_tc + rec_frame, editrate)}
                </span>
                <Checkbox checked={use_cur_tc || is_drawing} />
              </div>
              <div style={{display: 'flex', gap: '10px'}}>
                <Editor_btn
                  type="button"
                  style={{padding: '4px 10px', borderRadius: '5px',
                    fontSize: '14px', color: 'white'}}
                  onClick={draw_toggle}
                  bg={is_drawing ? theme.gray10 : theme.gray9}
                >
                  <Antd_icon component={Brush_icon}
                    style={{color: is_drawing ? purple.primary : 'white'}} />
                </Editor_btn>
                <Editor_btn
                  type="submit"
                  style={{padding: '4px 10px', borderRadius: '5px',
                    fontSize: '14px', color: 'white'}}
                >
                  {t('Send')}
                </Editor_btn>
              </div>
            </div>
            {is_drawing && <div
              style={{display: 'flex', justifyContent: 'space-between',
                height: '32px'}}
            >
              <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                {drawing_colors.map(color=>{
                  return (
                    <Editor_btn type="button" key={color}
                      style={{borderRadius: '50%', minWidth: '20px',
                        width: '20px', minHeight: '20px', height: '20px',
                        border: drawing_color == color ? '2px solid white'
                          : 'none'}} onClick={()=>drawing_color_set(color)}
                      bg={color} hover_bg={color} active_bg={color} />
                  );
                })}
              </div>
              <div style={{display: 'flex', gap: '10px'}}>
                {drawing_tools.map(({key, icon})=>{
                  return (
                    <Editor_btn type="button" key={key}
                      style={{padding: '4px 10px', borderRadius: '5px',
                        fontSize: '14px', color: 'white'}}
                      onClick={()=>drawing_tool_set(key)}
                      bg={key == drawing_tool ? theme.gray10 : theme.gray9}>
                      <Antd_icon
                        component={icon}
                        style={{color: key == drawing_tool ? purple.primary
                          : 'white'}} />
                    </Editor_btn>
                  );
                })}
              </div>
            </div>}
          </form>
        </div>
      </div>
    </>
  );
});
let Info_modal = React.memo(({lbin, is_open, frame, on_close})=>{
  let {t} = useTranslation();
  return (
    <Modal title={t('Tracks Info')} open={is_open} footer={null}
      onCancel={on_close}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {lbin?.rec_monitor_in?.tracks?.map(track=>{
          return (
            <div key={track.id} style={{display: 'flex',
              justifyContent: 'space-between'}}>
              <span style={{fontWeight: 'bold'}}>{track.lbl}</span>
              <span>{frame - track.start}</span>
            </div>
          );
        })}
      </div>
    </Modal>
  );
});
let Shortcuts_modal = React.memo(({is_open, on_close})=>{
  let {t} = useTranslation();
  let [query, query_set] = useState('');
  let action2lbl = useMemo(()=>({
    zoom_in: t('Zoom In'),
    zoom_out: t('Zoom Out'),
    move_one_frame_left: t('Move One Frame Left'),
    move_one_frame_right: t('Move One Frame Right'),
    move_ten_frames_left: t('Move Ten Frames Left'),
    move_ten_frames_right: t('Move Ten Frames Right'),
    play_stop: t('Play/Stop'),
    play_backwards: t('Play Backwards'),
    stop: t('Stop'),
    play: t('Play'),
    go_to_prev_cut: t('Go to Previous Cut'),
    go_to_next_cut: t('Go to Next Cut'),
    go_to_prev_marker: t('Go to Previous Marker'),
    go_to_next_marker: t('Go to Next Marker'),
    go_to_mark_in: t('Go to Mark In'),
    go_to_mark_out: t('Go to Mark Out'),
    tc_backspace_marker_remove: t('Delete Marker'),
    toggle_selection_tool: t('Toggle Selection Tool'),
    toggle_insertion_tool: t('Toggle Insertion Tool'),
    info_modal_open: t('Open Info Modal'),
    mark_in: t('Mark In'),
    mark_out: t('Mark Out'),
    mark_clip: t('Mark Clip'),
    clear_both_marks: t('Clear Both Marks'),
    cut: t('Cut'),
    lift: t('Lift'),
    extract: t('Extract'),
    marker_add: t('Add Marker'),
    marker_add_green: t('Add Green Marker'),
    marker_add_red: t('Add Red Marker'),
    play_in_to_out: t('Play In to Out'),
    trim_mode: t('Toggle Trim Mode'),
    trim_move_one_frame_left: t('Move Trim One Frame Left'),
    trim_move_one_frame_right: t('Move Trim One Frame Right'),
    trim_move_ten_frames_left: t('Move Trim Ten Frames Left'),
    trim_move_ten_frames_right: t('Move Trim Ten Frames Right'),
    video_track_add: t('Add Video Track'),
    mono_audio_track_add: t('Add Mono Audio Track'),
    stereo_audio_track_add: t('Add Stereo Audio Track'),
    track_add: t('Add Track'),
    undo: t('Undo'),
    redo: t('Redo'),
    mark_all_tracks: t('Mark All Tracks'),
    unmark_all_tracks: t('Unmark All Tracks'),
    quad_split: t('Quad Split'),
    nine_split: t('Nine Split'),
    insert: t('Insert'),
  }), [t]);
  let cols = useMemo(()=>{
    return [
      {key: 'lbl', dataIndex: 'lbl', title: 'name'},
      {key: 'key_bind', dataIndex: 'key_bind', title: 'Shortcut'},
    ];
  }, []);
  let data_src = useMemo(()=>{
    return Object.entries(action2key_bind)
      .filter(([action])=>action2lbl[action])
      .map(([action, key_bind])=>{
        if (!Array.isArray(key_bind))
          key_bind = [key_bind];
        let key_bind_lbl = key_bind
          .map(_key_bind=>key_bind2lbl(_key_bind))
          .join(', ');
        return {lbl: action2lbl[action], key_bind: key_bind_lbl};
      })
      .filter(({lbl, key_bind})=>{
        if (!query)
          return true;
        return lbl.toLowerCase().includes(query.toLowerCase()) ||
          key_bind.toLowerCase().includes(query.toLowerCase());
      });
  }, [action2lbl, query]);
  let query_change_handle = useCallback(e=>{
    query_set(e.target.value);
  }, []);
  return (
    <Modal title={t('Shortcuts')} open={is_open} footer={null}
      onCancel={on_close}>
      <Space direction="vertical" size="middle" style={{display: 'flex'}}>
        <Row>
          <Col span={12} offset={12}>
            <Input placeholder={t('Search...')} value={query} allowClear
              onChange={query_change_handle} />
          </Col>
        </Row>
        <Table dataSource={data_src} columns={cols} />
      </Space>
    </Modal>
  );
});
let Track_add_modal = React.memo(({is_open, on_close, cmd_track_add, lbin,
  track_ids})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [timeline_mode] = use_je('editor.timeline_mode', 'rec');
  let track_types = useMemo(()=>[
    {value: 'video', label: t('Video')},
    {value: 'audio', label: t('Audio Mono')},
    {value: 'audio_stereo', label: t('Audio Stereo'), disabled: true},
    {value: '5.1_surround', label: t('5.1 Surround'), disabled: true},
    {value: '7.1_surround', label: t('7.1 Surround'), disabled: true},
    {value: 'data', label: t('Data'), disabled: true},
  ], [t]);
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err)
      return;
    let track_prefix = values.data_type == 'video' ? 'V' : 'A';
    let track_id = track_prefix + values.track_num;
    let mob_id;
    if (timeline_mode == 'src')
      mob_id = lbin.src_monitor.mob_id;
    else if (timeline_mode == 'rec')
      mob_id = lbin.rec_monitor_in.mob_id;
    else
      assert(0, 'unexpected timeline_mode: ' + timeline_mode);
    cmd_track_add(mob_id, values.data_type, track_id);
    on_close();
  }), [lbin, cmd_track_add, form, on_close, timeline_mode]);
  let track_num_validator = useCallback((rule, value)=>{
    let data_type = form.getFieldValue('data_type');
    let track_prefix = data_type == 'video' ? 'V' : 'A';
    let track_id = track_prefix + value;
    if (track_ids.includes(track_id))
      return Promise.reject(new Error(t('The track already exists')));
    return Promise.resolve();
  }, [form, t, track_ids]);
  let initial_values = useMemo(()=>({track_num: 1, data_type: 'video'}), []);
  return (
    <Modal title={t('Add Track')} open={is_open} okText={t('OK')}
      onOk={submit_handle} onCancel={on_close}>
      <Form form={form} preserve={false} onFinish={submit_handle}
        layout="vertical" initialValues={initial_values}>
        <Form.Item name="data_type"
          label={<span style={{color: gray[9]}}>{t('Track Type')}</span>}>
          <Select options={track_types} />
        </Form.Item>
        <Form.Item name="track_num"
          label={<span style={{color: gray[9]}}>{t('Track Num')}</span>}
          rules={[{validator: track_num_validator}]}>
          <InputNumber min={1} />
        </Form.Item>
      </Form>
    </Modal>
  );
});
let marker_colors = {red: '#a12f19', green: '#33cc33', blue: '#3333cc',
  cyan: '#33cccc', magenta: '#cc33cc', yellow: '#e6e619', black: '#000000',
  white: '#ffffff'};
let Edit_marker_modal = React.memo(({lbin, is_open, init_name='',
  init_color=marker_colors.magenta, frame, init_track='V1', init_cmt='',
  cmd_marker_add, on_close, user, token, user_full})=>{
  let {t} = useTranslation();
  let [form] = Form.useForm();
  let [timeline_mode] = use_je('editor.timeline_mode', 'rec');
  let [message_api, message_ctx_holder] = message.useMessage();
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err)
      return;
    if (values.modal_disable)
    {
      let res = yield back_app.user_set_editor_setting(token, user.email,
        'is_disable_marker_modal', true);
      if (res.err)
      {
        message_api.error(t('Something went wrong'));
        metric.error('back_app.user_set_editor_setting', res.err);
        return;
      }
    }
    let mob_id;
    if (timeline_mode == 'src')
      mob_id = lbin.src_monitor.mob_id;
    else if (timeline_mode == 'rec')
      mob_id = lbin.rec_monitor_in.mob_id;
    else
      assert(0, 'unexpected timeline_mode: ' + timeline_mode);
    let res = yield cmd_marker_add(mob_id, values.track_id, frame,
      values.color, values.cmt);
    if (res.err)
    {
      message_api.error(t('Something went wrong'));
      metric.error('cmd_marker_add', res.err);
      return;
    }
    on_close();
  }), [cmd_marker_add, form, frame, message_api, on_close, t, token, user.email,
    lbin, timeline_mode]);
  let track_opts = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return lbin.rec_monitor_in.tracks.map(track=>{
      return {value: track.id, label: track.lbl};
    });
  }, [lbin?.rec_monitor_in?.tracks]);
  let color_opts = useMemo(()=>{
    return Object.entries(marker_colors).map(([label, color])=>{
      return {value: color, label: _.capitalize(label)};
    });
  }, []);
  useEffect(()=>{
    if (!is_open)
      return;
    form.setFieldsValue({track_id: init_track, name: init_name,
      color: init_color, cmt: init_cmt});
  }, [form, init_color, init_cmt, init_name, init_track, is_open]);
  return (
    <Modal title={t('Edit Marker')} open={is_open} okText={t('Add')}
      onOk={submit_handle} onCancel={on_close} destroyOnClose>
      {message_ctx_holder}
      <Form form={form} preserve={false} onFinish={submit_handle}
        initialValues={{track_id: init_track, name: init_name,
          color: init_color, cmt: init_cmt,
          modal_disable: user_full.setting?.editor?.is_disable_marker_modal}}
        layout="vertical">
        <Form.Item name="name"
          label={<span style={{color: gray[9]}}>{t('Marker Name')}</span>}>
          <Input allowClear />
        </Form.Item>
        <Form.Item name="color"
          label={<span style={{color: gray[9]}}>{t('Color')}</span>}>
          <Select options={color_opts} />
        </Form.Item>
        <div style={{marginBottom: '24px', color: gray[9]}}>
          Frame: {frame}
        </div>
        <Form.Item name="track_id"
          label={<span style={{color: gray[9]}}>{t('Track')}</span>}>
          <Select options={track_opts} />
        </Form.Item>
        <Form.Item name="cmt">
          <Input.TextArea rows={6} allowClear />
        </Form.Item>
        <Form.Item name="modal_disable" valuePropName="checked">
          <Checkbox>{t('Disable popup')}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
});
let Quick_transition_modal = React.memo(({lbin, is_open, on_close})=>{
  let {t} = useTranslation();
  let {token: {color}} = antd_theme.useToken();
  let [form] = Form.useForm();
  let [, message_ctx_holder] = message.useMessage();
  let submit_handle = useCallback(()=>eserf(function* _submit_handle(){
    let values = yield this.wait_ext2(form.validateFields());
    if (values.err)
      return;
    on_close();
  }), [form, on_close]);
  let init_values = useMemo(()=>{
    return {type: 'dissolve', dur: 0, start: 0};
  }, []);
  let type_opts = useMemo(()=>{
    return [
      {value: 'dissolve', label: t('Dissolve')},
      {value: 'film_dissolve', label: t('Film Dissolve'), disabled: true},
      {value: 'fade_to_color', label: t('Fade to Color'), disabled: true},
      {value: 'fade_from_color', label: t('Fade from Color'), disabled: true},
      {value: 'dip_to_color', label: t('Dip to Color'), disabled: true},
      {value: 's3d_depth_transition', label: t('S3D Depth Transition'),
        disabled: true},
    ];
  }, [t]);
  return (
    <Modal title={t('Quick Transition')} open={is_open} okText={t('Add')}
      onOk={submit_handle} onCancel={on_close} destroyOnClose>
      {message_ctx_holder}
      <Form form={form} preserve={false} onFinish={submit_handle}
        initialValues={init_values} layout="vertical">
        <Form.Item name="type" label={<span style={{color}}>{t('Add')}</span>}>
          <Select options={type_opts} />
        </Form.Item>
        <Form.Item name="dur"
          label={<span style={{color}}>{t('Duration')}</span>}>
          <Input suffix={t('frames')} />
        </Form.Item>
        <Form.Item name="start"
          label={<span style={{color}}>{t('Start')}</span>}>
          <Input suffix={t('frames before cut')} />
        </Form.Item>
        <div style={{position: 'relative', height: '34px'}}>
          <div style={{position: 'absolute', height: '16px',
            border: '1px solid black', width: '100%', left: 0, top: 0,
            background: '#00919B', fontSize: 10, color: gray[9],
            borderLeft: 'none', padding: '0 4px'}}>
            A
            <div style={{width: '50%', height: '100%', background: '#00777F',
              top: 0, right: 0, borderLeft: '1px solid black',
              position: 'absolute'}} />
          </div>
          <div style={{position: 'absolute', height: '16px',
            border: '1px solid black', width: '100%', right: 0, bottom: 0,
            background: '#00919B', fontSize: 10, color: gray[9],
            borderRight: 'none', padding: '0 4px', textAlign: 'right'}}>
            B
            <div style={{width: '50%', height: '100%', background: '#00777F',
              top: 0, left: 0, borderRight: '1px solid black',
              position: 'absolute'}} />
          </div>
          <div style={{left: '50%', top: '50%', height: '20px', width: '80px',
            background: '#84578B', position: 'absolute',
            transform: 'translate(-50%, -50%)', border: '1px solid black',
            cursor: 'grab'}}>
            <div style={{position: 'absolute', left: 0, right: 0,
              width: '102.63%', height: '1px', backgroundColor: 'black',
              transform: 'rotate(12.99deg)', transformOrigin: 'left top'}} />
          </div>
        </div>
      </Form>
    </Modal>
  );
});
let E = ()=>{
  let {t} = useTranslation();
  let navigate = useNavigate();
  let [message_api, message_ctx_holder] = message.useMessage();
  let [modal, modal_ctx_holder] = Modal.useModal();
  use_qs_clear({src: 1, src_path: 1, src_is_dir: 1, src_filename: 1,
    src_file_id: 1});
  let {qs_o} = use_qs();
  let {user, token, org, user_full} = auth.use_auth();
  let is_early_access_editor = useMemo(()=>{
    return qs_o.dbg||user_full?.flag?.is_early_access_editor;
  }, [qs_o.dbg, user_full]);
  let [is_rate, set_is_rate] = useState(false);
  let initial_src = useMemo(()=>qs_o.src, [qs_o.src]);
  let [is_info_modal_open, is_info_modal_open_set] = useState(false);
  let [is_shortcuts_modal_open, is_shortcuts_modal_open_set] = useState(false);
  let [marker_modal_name, marker_modal_name_set] = useState('');
  let [marker_modal_color, marker_modal_color_set] = useState(
    marker_colors.magenta);
  let [marker_modal_track, marker_modal_track_set] = useState('V1');
  let [marker_modal_msg, marker_modal_msg_set] = useState('');
  let [is_marker_modal_open, is_marker_modal_open_set] = useState(false);
  let [is_track_add_modal_open, is_track_add_modal_open_set] = useState(false);
  let [modal_load, set_modal_load] = useState(false);
  let [lbin_name, lbin_name_set] = useState(initial_src ? null
    : 'editor_tester');
  let [timeline_mode] = use_je('editor.timeline_mode', 'rec');
  // XXX colin: lbin -> lseq
  let [lbin, lbin_set] = useState(initial_src ? null : lbin_demo.lbin);
  let [lbin_changes, lbin_changes_set] = useState([]);
  let [cur_lbin_change_idx, cur_lbin_change_idx_set] = useState(-1);
  let [aaf_in, aaf_in_set] = useState();
  let aaf_in_ref = useRef();
  let [task_id, task_id_set] = useState();
  let task_id_ref = useRef();
  let [etag, etag_set] = useState();
  let [nested_track_paths, nested_track_paths_set] = useState({});
  let [src_frame, src_frame_set] = useState(0);
  // XXX colin: when moving to lbin_demo.lbin1 change to 5500
  let [rec_frame, rec_frame_set] = useState(0);
  let [zoom, zoom_set] = useState(0);
  let [scroll_x, scroll_x_set] = useState(0);
  let [tracks_wrapper_width, tracks_wrapper_width_set] = useState();
  let [, focused_panel_set] = useState('timeline');
  let [focused_monitor, focused_monitor_set] = use_je('editor.focused_monitor',
    'rec');
  let [max_playing_frame, max_playing_frame_set] = useState();
  let [src_editing_track_ids, src_editing_track_ids_set] = use_je(
    'editor.src_editing_track_ids', []);
  let [rec_editing_track_ids, rec_editing_track_ids_set] = use_je(
    'editor.rec_editing_track_ids', []);
  let [trim_mode, trim_mode_set] = use_je('editor.trim_mode', null);
  let [drag_mode, drag_mode_set] = use_je('editor.drag_mode', null);
  let [, rec_selected_monitor_set] = use_je('editor.rec_selected_monitor',
    null);
  let [, src_selected_monitor_set] = use_je('editor.src_selected_monitor',
    null);
  let cuts = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return editor.cuts_get(lbin.rec_monitor_in.tracks);
  }, [lbin?.rec_monitor_in?.tracks]);
  let [tc_input, tc_input_set] = useState(null);
  let fps = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.editrate?.d
      || !lbin?.rec_monitor_in?.editrate?.n)
    {
      return 0;
    }
    return lbin.rec_monitor_in.editrate.n / lbin.rec_monitor_in.editrate.d;
  }, [lbin?.rec_monitor_in?.editrate?.d, lbin?.rec_monitor_in?.editrate?.n]);
  let f2px_k = useMemo(()=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return 0;
    if (lbin.rec_monitor_in.len == 0)
      return 1;
    return lbin.rec_monitor_in.len / tracks_wrapper_width;
  }, [lbin?.rec_monitor_in?.len, tracks_wrapper_width]);
  let [src_playback_rate, src_playback_rate_set] = useState(0);
  let [rec_playback_rate, rec_playback_rate_set] = useState(0);
  let [is_loading, is_loading_set] = useState(false);
  let prev_props_ref = useRef({});
  useEffect(()=>{
    if (!lbin)
      return;
    if (lbin.rec_monitor_in)
    {
      let _rec_editing_track_ids = lbin.rec_monitor_in.tracks
        .filter(track=>track.is_edit)
        .map(track=>track.id);
      let rec_selected_monitor_track = lbin.rec_monitor_in.tracks
        .find(track=>track.is_monitor_selected);
      rec_editing_track_ids_set(_rec_editing_track_ids);
      rec_selected_monitor_set({track_id: rec_selected_monitor_track.id});
    }
    if (lbin.src_monitor)
    {
      let _src_editing_track_ids = lbin.src_monitor.tracks
        .filter(track=>track.is_edit)
        .map(track=>track.id);
      let src_selected_monitor_track = lbin.src_monitor.tracks
        .find(track=>track.is_monitor_selected);
      let _src_selected_monitor = {track_id: src_selected_monitor_track.id};
      src_selected_monitor_set(_src_selected_monitor);
      src_editing_track_ids_set(_src_editing_track_ids);
    }
  }, [etag, lbin, rec_editing_track_ids_set, rec_selected_monitor_set,
    src_editing_track_ids_set, src_selected_monitor_set]);
  let [is_cmts_loaded, is_cmts_loaded_set] = useState(false);
  let [cmts, cmts_set] = useState([]);
  let [cmts_panel_open, cmts_panel_open_set] = useState(false);
  let [is_drawing, is_drawing_set] = useState(false);
  let [drawing_view_box, drawing_view_box_set] = useState(null);
  let [drawing_tool, drawing_tool_set] = useState('brush');
  let [drawing_color, drawing_color_set] = useState(red.primary);
  let [drawing_objs, drawing_objs_set] = useState([]);
  let [cmt_svg, cmt_svg_set] = useState(null);
  let editor_ref = useRef(null);
  let scroll_x_handle = useCallback(_scroll_x=>{
    if (lbin?.rec_monitor_in?.len === undefined)
      return;
    let min_scroll = 0;
    let max_scroll = lbin.rec_monitor_in.len
      - player.px2f(tracks_wrapper_width, f2px_k, zoom);
    _scroll_x = xutil.clamp(_scroll_x, min_scroll, max_scroll);
    scroll_x_set(_scroll_x);
  }, [f2px_k, lbin?.rec_monitor_in?.len, tracks_wrapper_width, zoom]);
  let src_frame_handle = useCallback((_src_frame, ignore_clamp)=>{
    if (lbin?.src_monitor === undefined)
      return;
    _src_frame = Math.floor(_src_frame);
    if (!ignore_clamp && lbin.src_monitor)
    {
      let min_frame = 0;
      let max_frame = lbin.src_monitor.tracks[0].len;
      _src_frame = xutil.clamp(_src_frame, min_frame, max_frame);
    }
    src_frame_set(_src_frame);
  }, [lbin?.src_monitor]);
  let src_move_frame = useCallback(frame_shift=>{
    let _src_frame = src_frame + frame_shift;
    src_frame_handle(_src_frame);
    src_playback_rate_set(0);
  }, [src_frame, src_frame_handle]);
  let play_in_to_out = useCallback(()=>{
    if (!lbin?.rec_monitor_in)
      return;
    if (rec_playback_rate)
      return rec_playback_rate_set(0);
    if (lbin.rec_monitor_in.mark_in)
      rec_frame_set(lbin.rec_monitor_in.mark_in);
    rec_playback_rate_set(1);
    if (lbin.rec_monitor_in.mark_out)
      max_playing_frame_set(lbin.rec_monitor_in.mark_out);
  }, [lbin?.rec_monitor_in, rec_playback_rate]);
  let src_playback_rate_handle = useCallback(_src_playback_rate=>{
    if (!lbin?.src_monitor)
      return;
    src_playback_rate_set(_src_playback_rate);
  }, [lbin?.src_monitor]);
  let rec_playback_rate_handle = useCallback(_rec_playback_rate=>{
    rec_playback_rate_set(_rec_playback_rate);
    max_playing_frame_set(undefined);
  }, []);
  let cmd_eval = useCallback((_aaf_in, cmd, data={})=>eserf(function*
  _cmd_eval(){
    if (!aaf_in_ref.current)
      return {err: 'no aaf_in'};
    if (!cmd)
      assert(0, 'no cmd');
    if (!Array.isArray(data))
      data = [data];
    // XXX colin: add dbg_py to all
    if (!data.length)
      assert(0, 'no data');
    let data_with_mob_ids = data.map(_data=>{
      _data = {..._data};
      if (lbin.rec_monitor_in)
        _data.mob_id_rec = lbin.rec_monitor_in.mob_id;
      if (lbin.src_monitor)
        _data.mob_id_src = lbin.src_monitor.mob_id;
      return _data;
    });
    let res = yield back_app.editor.cmds(token, aaf_in_ref.current,
      task_id_ref.current, cmd, data_with_mob_ids);
    if (res.err)
      return {err: res.err};
    let _lbin = {...lbin, ...res.lbin};
    if (res.lbin.rec_monitor_out)
    {
      let rec_monitor_in = res.lbin.rec_monitor_out;
      if (lbin.rec_monitor_in)
      {
        rec_monitor_in = monitor_sync(lbin.rec_monitor_in,
          res.lbin.rec_monitor_out);
      }
      _lbin.rec_monitor_in = rec_monitor_in;
    }
    if (res.lbin.src_monitor)
    {
      let src_monitor = res.lbin.src_monitor;
      if (lbin.src_monitor)
      {
        src_monitor = monitor_sync(lbin.src_monitor,
          res.lbin.src_monitor);
      }
      _lbin.src_monitor = src_monitor;
    }
    lbin_set(_lbin);
    let _rec_editing_track_ids = rec_editing_track_ids
      .filter(track_id=>{
        let track = _lbin.rec_monitor_in.tracks.find(_track=>{
          return _track.id == track_id;
        });
        let nested_tracks = nested_tracks_get(_lbin.rec_monitor_in, track_id,
          nested_track_paths);
        let nested_track = nested_tracks.find(_track=>{
          return _track.id == track_id;
        });
        return track || nested_track;
      });
    let _src_editing_track_ids = [];
    if (res.lbin.src_monitor)
    {
      _src_editing_track_ids = src_editing_track_ids
        .filter(track_id=>{
          return _lbin.src_monitor.tracks.find(track=>track.id == track_id);
        });
    }
    let diffs = deep_diff(lbin, _lbin);
    let lbin_change = {cmd, diffs, aaf_in: _aaf_in, etag};
    let _lbin_changes = lbin_changes.slice(0, cur_lbin_change_idx + 1);
    _lbin_changes.push(lbin_change);
    lbin_changes_set(_lbin_changes);
    cur_lbin_change_idx_set(cur_lbin_change_idx + 1);
    aaf_in_set(res.file);
    aaf_in_ref.current = res.file;
    task_id_set(res.task_id);
    task_id_ref.current = res.task_id;
    etag_set(res.etag);
    src_editing_track_ids_set(_src_editing_track_ids);
    rec_editing_track_ids_set(_rec_editing_track_ids);
    return {aaf_in: res.file, etag: res.etag};
  }), [cur_lbin_change_idx, lbin, lbin_changes, rec_editing_track_ids_set,
    src_editing_track_ids_set, rec_editing_track_ids, src_editing_track_ids,
    token, etag, nested_track_paths]);
  let editing_tracks = useMemo(()=>{
    if (!lbin?.rec_monitor_in)
      return [];
    let _editing_tracks = [];
    for (let idx = 0; idx < lbin.rec_monitor_in.tracks.length; idx++)
    {
      let track = lbin.rec_monitor_in.tracks[idx];
      if (rec_editing_track_ids.includes(track.id))
        _editing_tracks.push(track);
    }
    for (let parent_track_id in nested_track_paths)
    {
      let tracks = nested_tracks_get(lbin.rec_monitor_in, parent_track_id,
        nested_track_paths);
      for (let track of tracks)
      {
        if (rec_editing_track_ids.includes(track.id))
          _editing_tracks.push(track);
      }
    }
    return _editing_tracks;
  }, [rec_editing_track_ids, lbin, nested_track_paths]);
  let cmd_clip_move = useCallback((mob_id, track_id, track_id_set, abs_frame,
    abs_frame_set)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (track_id_set === undefined)
      assert(0, 'no track_id_set');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    if (abs_frame_set === undefined)
      assert(0, 'no abs_frame_set');
    return cmd_eval(aaf_in, 'clip_move', {mob_id, track_id, track_id_set,
      abs_frame, abs_frame_set});
  }, [aaf_in, cmd_eval]);
  let cmd_clip_move_insert = useCallback((mob_id, track_id, track_id_set,
    abs_frame, abs_frame_set)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (track_id_set === undefined)
      assert(0, 'no track_id_set');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    if (abs_frame_set === undefined)
      assert(0, 'no abs_frame_set');
    return cmd_eval(aaf_in, 'clip_move_insert', {mob_id, track_id, track_id_set,
      abs_frame, abs_frame_set});
  }, [aaf_in, cmd_eval]);
  let cmd_match_frame = useCallback((mob_id, track_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval(aaf_in, 'match_frame', {mob_id, track_id, abs_frame});
  }, [aaf_in, cmd_eval]);
  let cmd_trim = useCallback((mob_id, starts, deltas, cmd='trim')=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (starts === undefined)
      assert(0, 'no starts');
    if (deltas === undefined)
      assert(0, 'no deltas');
    if (!['trim', 'trim_pre', 'trim_post'].includes(cmd))
      assert(0, 'unexpected cmd: ' + cmd);
    let data = Object.entries(deltas)
      .filter(([track_id, delta])=>delta)
      .map(([track_id, delta])=>{
        let abs_frame = Math.round(starts[track_id]);
        let abs_frame_set = Math.round(starts[track_id] + delta);
        return {mob_id, track_id, abs_frame, abs_frame_set};
      });
    if (!data.length)
      return {};
    return cmd_eval(aaf_in, cmd, data);
  }, [aaf_in, cmd_eval]);
  let cmd_mark_in = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval(aaf_in, 'mark_in', {mob_id, abs_frame});
  }, [aaf_in, cmd_eval]);
  let cmd_mark_out = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval(aaf_in, 'mark_out', {mob_id, abs_frame});
  }, [aaf_in, cmd_eval]);
  let cmd_mark_clip = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval(aaf_in, 'mark_clip', {mob_id, abs_frame});
  }, [aaf_in, cmd_eval]);
  let cmd_clear_both_marks = useCallback(mob_id=>eserf(function*
  _cmd_clear_both_marks(){
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    let res = yield cmd_eval(aaf_in, 'clear_mark_in', {mob_id});
    if (res.err)
      return {err: res.err};
    return yield cmd_eval(res.aaf_in, 'clear_mark_out', {mob_id});
  }), [aaf_in, cmd_eval]);
  let cmd_cut = useCallback((mob_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    let data = editing_tracks
      .filter(track=>{
        let track_cuts = editor.cuts_get([track]);
        return !track_cuts.includes(abs_frame) && track.id != cmt_track_id;
      })
      .map(track=>({mob_id, track_id: track.id, abs_frame}));
    if (!data.length)
      return {};
    return cmd_eval(aaf_in, 'cut', data);
  }, [aaf_in, editing_tracks, cmd_eval]);
  let cmd_lift = useCallback((mob_id, abs_frame_in, abs_frame_out)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame_in === undefined)
      assert(0, 'no abs_frame_in');
    if (abs_frame_out === undefined)
      assert(0, 'no abs_frame_out');
    let data = editing_tracks
      .filter(track=>track.id != cmt_track_id)
      .map(track=>{
        return {mob_id, track_id: track.id, abs_frame_in, abs_frame_out};
      });
    if (!data.length)
      return {};
    return cmd_eval(aaf_in, 'lift', data);
  }, [editing_tracks, cmd_eval, aaf_in]);
  let cmd_extract = useCallback((mob_id, abs_frame_in, abs_frame_out)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (abs_frame_in === undefined)
      assert(0, 'no abs_frame_in');
    if (abs_frame_out === undefined)
      assert(0, 'no abs_frame_out');
    let data = editing_tracks.map(track=>{
      return {mob_id, track_id: track.id, abs_frame_in, abs_frame_out};
    });
    return cmd_eval(aaf_in, 'extract', data);
  }, [editing_tracks, cmd_eval, aaf_in]);
  let cmd_selector_set = useCallback((mob_id, track_id, selector_idx,
    abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (selector_idx === undefined)
      assert(0, 'no selector_idx');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval(aaf_in, 'selector_set', {mob_id, track_id, selector_idx,
      abs_frame});
  }, [aaf_in, cmd_eval]);
  let cmd_power = useCallback((mob_id, track_id, is_power)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (is_power === undefined)
      assert(0, 'no is_power');
    return cmd_eval(aaf_in, 'power', {mob_id, track_id, is_power});
  }, [aaf_in, cmd_eval]);
  let cmd_solo = useCallback((mob_id, track_ids, is_solo)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_ids === undefined)
      assert(0, 'no track_ids');
    if (is_solo === undefined)
      assert(0, 'no is_solo');
    let data = track_ids.map(track_id=>{
      return {mob_id, track_id, is_solo};
    });
    return cmd_eval(aaf_in, 'solo', data);
  }, [aaf_in, cmd_eval]);
  let cmd_mute = useCallback((mob_id, track_ids, is_mute)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_ids === undefined)
      assert(0, 'no track_ids');
    if (is_mute === undefined)
      assert(0, 'no is_mute');
    let data = track_ids.map(track_id=>{
      return {mob_id, track_id, is_mute};
    });
    return cmd_eval(aaf_in, 'mute', data);
  }, [aaf_in, cmd_eval]);
  let cmd_synclock = useCallback((mob_id, track_id, is_synclock)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (is_synclock === undefined)
      assert(0, 'no is_synclock');
    return cmd_eval(aaf_in, 'synclock', {mob_id, track_id, is_synclock});
  }, [aaf_in, cmd_eval]);
  let cmd_lock = useCallback((mob_id, track_id, is_lock)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (is_lock === undefined)
      assert(0, 'no is_lock');
    return cmd_eval(aaf_in, 'lock', {mob_id, track_id, is_lock});
  }, [aaf_in, cmd_eval]);
  let cmd_marker_add = useCallback((mob_id, track_id, abs_frame, color, cmt)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    if (color === undefined)
      assert(0, 'no color');
    if (cmt === undefined)
      assert(0, 'no cmt');
    return cmd_eval(aaf_in, 'marker_add', {mob_id, track_id, abs_frame, color,
      comment: cmt});
  }, [aaf_in, cmd_eval]);
  let cmd_marker_remove = useCallback((mob_id, track_id, abs_frame)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (abs_frame === undefined)
      assert(0, 'no abs_frame');
    return cmd_eval(aaf_in, 'marker_remove', {mob_id, track_id, abs_frame});
  }, [aaf_in, cmd_eval]);
  let cmd_track_add = useCallback((mob_id, data_type, track_id)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (!['video', 'audio'].includes(data_type))
      assert(0, 'unexpected data_type: ' + data_type);
    if (track_id === undefined)
      assert(0, 'no track_id');
    return cmd_eval(aaf_in, 'track_add', {mob_id, data_type, track_id});
  }, [aaf_in, cmd_eval]);
  let cmd_track_remove = useCallback((mob_id, track_id)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    return cmd_eval(aaf_in, 'track_remove', {mob_id, track_id});
  }, [aaf_in, cmd_eval]);
  let cmd_insert = useCallback((mob_id, track_id, abs_frame_in,
    abs_frame_out)=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    if (track_id === undefined)
      assert(0, 'no track_id');
    if (abs_frame_in === undefined)
      assert(0, 'no abs_frame_in');
    if (abs_frame_out === undefined)
      assert(0, 'no abs_frame_out');
    return cmd_eval(aaf_in, 'insert', {mob_id, track_id, abs_frame_in,
      abs_frame_out});
  }, [aaf_in, cmd_eval]);
  let cmd_rec_monitor_load_clip = useCallback(mob_id=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    return cmd_eval(aaf_in, 'rec_monitor_load_clip', {mob_id});
  }, [aaf_in, cmd_eval]);
  let cmd_src_monitor_load_clip = useCallback(mob_id=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    return cmd_eval(aaf_in, 'src_monitor_load_clip', {mob_id});
  }, [aaf_in, cmd_eval]);
  let cmd_clip_duplicate = useCallback(mob_id=>{
    if (mob_id === undefined)
      assert(0, 'no mob_id');
    return cmd_eval(aaf_in, 'clip_duplicate', {mob_id});
  }, [aaf_in, cmd_eval]);
  let trim_mode_disable = useCallback(()=>{
    trim_mode_set(null);
    if (Object.values(trim_mode.deltas).every(delta=>delta == 0))
      return;
    let cmd = 'trim';
    if (trim_mode.is_side_clicked)
      cmd = trim_mode.side == 'rec' ? 'trim_post' : 'trim_pre';
    let mob_id;
    if (timeline_mode == 'src')
      mob_id = lbin.src_monitor.mob_id;
    else if (timeline_mode == 'rec')
      mob_id = lbin.rec_monitor_in.mob_id;
    else
      assert(0, 'unexpected timeline_mode: ' + timeline_mode);
    cmd_trim(mob_id, trim_mode.start_pos, trim_mode.deltas, cmd);
  }, [cmd_trim, trim_mode_set, trim_mode, lbin, timeline_mode]);
  let rec_frame_handle = useCallback((_rec_frame, ignore_clamp, scroll,
    keep_max_playing_frame)=>{
    if (lbin?.rec_monitor_in.start === undefined
      || lbin?.rec_monitor_in?.len === undefined)
    {
      return;
    }
    _rec_frame = Math.floor(_rec_frame);
    if (!ignore_clamp)
    {
      let min_frame = lbin.rec_monitor_in.start;
      let max_frame = lbin.rec_monitor_in.len;
      _rec_frame = xutil.clamp(_rec_frame, min_frame, max_frame);
    }
    rec_frame_set(_rec_frame);
    cmt_svg_set(null);
    if (!keep_max_playing_frame)
      max_playing_frame_set(undefined);
    if (trim_mode)
      trim_mode_disable();
    if (!scroll)
      return;
    let frames_visible = player.px2f(tracks_wrapper_width, f2px_k, zoom);
    if (_rec_frame < scroll_x || _rec_frame > scroll_x + frames_visible)
      scroll_x_handle(_rec_frame - frames_visible / 2);
  }, [f2px_k, lbin?.rec_monitor_in?.len, lbin?.rec_monitor_in?.start, scroll_x,
    scroll_x_handle, tracks_wrapper_width, trim_mode, trim_mode_disable, zoom]);
  let rec_move_frame = useCallback(frame_shift=>{
    let _rec_frame = rec_frame + frame_shift;
    rec_frame_handle(_rec_frame, false, true);
    rec_playback_rate_set(0);
  }, [rec_frame, rec_frame_handle]);
  let trim_mode_enable = useCallback(()=>{
    if (!editing_tracks.length)
      return;
    let active_cuts = editor.cuts_get(editing_tracks);
    if (!active_cuts.length)
      return;
    let nearest_cut = editor.nearest_cut_get(active_cuts, rec_frame);
    let _rec_editing_track_ids = [];
    let start_pos = {};
    let deltas = {};
    let left_segs = {};
    let right_segs = {};
    let trimming_segs_num = 0;
    for (let track of editing_tracks)
    {
      let tracks_cuts = editor.cuts_get([track]);
      if (!tracks_cuts.includes(nearest_cut))
        continue;
      _rec_editing_track_ids.push(track.id);
      start_pos[track.id] = nearest_cut;
      deltas[track.id] = 0;
      left_segs[track.id] = [];
      right_segs[track.id] = [];
      let abs_starts_map = player.abs_starts_get(track);
      for (let [seg] of abs_starts_map.entries())
      {
        if (!seg.trim)
          continue;
        if (seg.abs_start == nearest_cut)
        {
          left_segs[track.id].push(seg);
          trimming_segs_num += 1;
        }
        if (seg.abs_start + seg.len == nearest_cut)
        {
          right_segs[track.id].push(seg);
          trimming_segs_num += 1;
        }
      }
    }
    if (!trimming_segs_num)
      return;
    trim_mode_set({left_segs, right_segs, start_pos, deltas,
      init_pos: nearest_cut, side: 'rec', is_side_clicked: false});
    rec_editing_track_ids_set(_rec_editing_track_ids);
    rec_frame_handle(nearest_cut, false, true);
  }, [editing_tracks, rec_editing_track_ids_set, rec_frame, rec_frame_handle,
    trim_mode_set]);
  let trim_drag_start_handle = useCallback(()=>{
    if (!trim_mode)
      return;
    trim_mode_set({...trim_mode, is_dragging: true});
  }, [trim_mode, trim_mode_set]);
  let trim_shift = useCallback(delta=>{
    if (!trim_mode)
      return;
    let min_delta = -Infinity;
    let max_delta = Infinity;
    for (let track_id in trim_mode.deltas)
    {
      let left_segs = trim_mode.left_segs[track_id];
      let right_segs = trim_mode.right_segs[track_id];
      let track_delta = trim_mode.deltas[track_id];
      for (let seg of left_segs)
      {
        if (!seg.trim)
          continue;
        if (seg.trim.start_post - track_delta < max_delta)
          max_delta = seg.trim.start_post - track_delta;
        if (-seg.trim.start_pre - track_delta > min_delta)
          min_delta = -seg.trim.start_pre - track_delta;
      }
      for (let seg of right_segs)
      {
        if (!seg.trim)
          continue;
        if (seg.trim.end_post - track_delta < max_delta)
          max_delta = seg.trim.end_post - track_delta;
        if (-seg.trim.end_pre - track_delta > min_delta)
          min_delta = -seg.trim.start_pre - track_delta;
      }
    }
    delta = xutil.clamp(delta, min_delta, max_delta);
    let _deltas = {...trim_mode.deltas};
    for (let track_id in _deltas)
      _deltas[track_id] += delta;
    trim_mode_set({...trim_mode, deltas: _deltas});
    rec_frame_set(rec_frame + delta);
  }, [rec_frame, trim_mode, trim_mode_set]);
  let trim_drag_handle = useCallback(e=>{
    let container = e.target.closest('#tracks-container');
    if (!container)
      return;
    let trim_cur_pos = player.px2f(e.clientX - coords_get(container).left,
      f2px_k, zoom);
    trim_cur_pos = Math.round(trim_cur_pos);
    let delta = trim_cur_pos - rec_frame;
    trim_shift(delta);
  }, [f2px_k, rec_frame, trim_shift, zoom]);
  let trim_drag_stop_handle = useCallback(()=>{
    if (!trim_mode)
      return;
    trim_mode_set({...trim_mode, is_dragging: false});
  }, [trim_mode, trim_mode_set]);
  let trim_toggle = useCallback(()=>{
    if (trim_mode)
      trim_mode_disable();
    else
      trim_mode_enable();
  }, [trim_mode, trim_mode_disable, trim_mode_enable]);
  useEffect(()=>{
    if (!trim_mode?.is_dragging)
      return;
    document.addEventListener('mousemove', trim_drag_handle);
    document.addEventListener('mouseup', trim_drag_stop_handle);
    return ()=>{
      document.removeEventListener('mousemove', trim_drag_handle);
      document.removeEventListener('mouseup', trim_drag_stop_handle);
    };
  }, [trim_drag_handle, trim_drag_stop_handle, trim_mode?.is_dragging]);
  useEffect(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return;
    let prev_rec_editing_track_ids =
      prev_props_ref.current.rec_editing_track_ids || [];
    let are_equal = rec_editing_track_ids
      && JSON.stringify(prev_rec_editing_track_ids.sort())
      == JSON.stringify(rec_editing_track_ids.sort());
    if (are_equal || !trim_mode)
      return;
    let _start_pos = {...trim_mode.start_pos};
    let _deltas = {...trim_mode.deltas};
    let _left_segs = {...trim_mode.left_segs};
    let _right_segs = {...trim_mode.right_segs};
    for (let track of lbin.rec_monitor_in.tracks)
    {
      if (track.type != 'timeline_track')
        continue;
      let abs_starts_map = player.abs_starts_get([track]);
      let track_cuts = editor.cuts_get(track);
      let nearest_cut = editor.nearest_cut_get(track_cuts, rec_frame);
      if (prev_rec_editing_track_ids.includes(track.id)
        && !rec_editing_track_ids.includes(track.id))
      {
        delete _start_pos[track.id];
        delete _deltas[track.id];
        delete _left_segs[track.id];
        delete _right_segs[track.id];
      }
      if (!prev_rec_editing_track_ids.includes(track.id)
        && rec_editing_track_ids.includes(track.id))
      {
        _start_pos[track.id] = nearest_cut;
        _deltas[track.id] = 0;
        _left_segs[track.id] = [];
        _right_segs[track.id] = [];
        for (let [seg] of abs_starts_map.entries())
        {
          if (seg.abs_start == nearest_cut)
            _left_segs[track.id].push(seg);
          if (seg.abs_start + seg.len == nearest_cut)
            _right_segs[track.id].push(seg);
        }
      }
    }
    trim_mode_set({...trim_mode, left_segs: _left_segs, right_segs: _right_segs,
      start_pos: _start_pos, deltas: _deltas});
    prev_props_ref.current.rec_editing_track_ids = rec_editing_track_ids;
  }, [rec_editing_track_ids, lbin?.rec_monitor_in?.tracks, rec_frame, trim_mode,
    trim_mode_set]);
  let go_to_prev_cut = useCallback(()=>{
    if (!cuts.length)
      return;
    let _rec_frame = cuts.find((cut, index)=>{
      return cut < rec_frame && rec_frame <= cuts[index + 1];
    });
    if (!_rec_frame)
      _rec_frame = cuts.at(0);
    rec_frame_handle(_rec_frame, false, true);
    rec_playback_rate_set(0);
  }, [cuts, rec_frame_handle, rec_frame]);
  let go_to_next_cut = useCallback(()=>{
    if (!cuts.length)
      return;
    let _rec_frame = cuts.find(cut=>rec_frame < cut);
    if (!_rec_frame)
      _rec_frame = cuts.at(-1);
    rec_frame_handle(_rec_frame, false, true);
    rec_playback_rate_set(0);
  }, [cuts, rec_frame, rec_frame_handle]);
  let go_to_mark_in = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_in === undefined)
      return;
    rec_frame_handle(lbin.rec_monitor_in.mark_in, false, true);
  }, [lbin?.rec_monitor_in?.mark_in, rec_frame_handle]);
  let go_to_mark_out = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_out === undefined)
      return;
    rec_frame_handle(lbin.rec_monitor_in.mark_out, false, true);
  }, [lbin?.rec_monitor_in?.mark_out, rec_frame_handle]);
  let src_playing_ref = useRef(null);
  useEffect(()=>{
    if (!src_playback_rate || !lbin.src_monitor)
    {
      src_playing_ref.current = null;
      return;
    }
    if (!src_playing_ref.current)
    {
      src_playing_ref.current = {};
      src_playing_ref.current.start_tc = performance.now();
      src_playing_ref.current.start_frame = src_frame;
      src_playing_ref.current.playback_rate = src_playback_rate;
    }
    if (src_playback_rate != src_playing_ref.current.playback_rate)
    {
      src_playing_ref.current.start_tc = performance.now();
      src_playing_ref.current.start_frame = src_frame;
      src_playing_ref.current.playback_rate = src_playback_rate;
    }
    let cancelled = false;
    let step = ()=>{
      if (!src_playing_ref.current)
        return;
      let ms = (performance.now() - src_playing_ref.current.start_tc)
        * src_playback_rate;
      let sec = ms / xdate.MS_SEC;
      let _src_frame = src_playing_ref.current.start_frame +
        Math.floor(sec * fps);
      src_frame_handle(_src_frame);
      let len = lbin.src_monitor.tracks[0].len;
      if (_src_frame < 0 || _src_frame == len - 1)
        return src_playback_rate_set(0);
      if (!cancelled)
        requestAnimationFrame(step);
    };
    requestAnimationFrame(step);
    return ()=>cancelled = true;
  }, [fps, src_frame, src_frame_handle, src_playback_rate, lbin]);
  let rec_playing_ref = useRef(null);
  useEffect(()=>{
    if (!lbin?.rec_monitor_in?.len || !rec_playback_rate)
    {
      rec_playing_ref.current = null;
      return;
    }
    if (!rec_playing_ref.current)
    {
      rec_playing_ref.current = {};
      rec_playing_ref.current.start_tc = performance.now();
      rec_playing_ref.current.start_frame = rec_frame;
      rec_playing_ref.current.playback_rate = rec_playback_rate;
    }
    if (rec_playback_rate != rec_playing_ref.current.playback_rate)
    {
      rec_playing_ref.current.start_tc = performance.now();
      rec_playing_ref.current.start_frame = rec_frame;
      rec_playing_ref.current.playback_rate = rec_playback_rate;
    }
    let cancelled = false;
    let step = ()=>{
      if (!rec_playing_ref.current)
        return;
      let ms = (performance.now() - rec_playing_ref.current.start_tc)
        * rec_playback_rate;
      let sec = ms / xdate.MS_SEC;
      let _rec_frame = rec_playing_ref.current.start_frame +
        Math.floor(sec * fps);
      if (_rec_frame >= max_playing_frame)
      {
        rec_playback_rate_set(0);
        max_playing_frame_set(undefined);
        return;
      }
      rec_frame_handle(_rec_frame, false, true, true);
      if (_rec_frame < 0 || _rec_frame == lbin.rec_monitor_in.len - 1)
        return rec_playback_rate_set(0);
      if (!cancelled)
        requestAnimationFrame(step);
    };
    requestAnimationFrame(step);
    return ()=>cancelled = true;
  }, [rec_frame, rec_frame_handle, fps, rec_playback_rate, max_playing_frame,
    lbin?.rec_monitor_in?.len, scroll_x, scroll_x_handle, tracks_wrapper_width,
    f2px_k]);
  let src_playing_toggle = useCallback(()=>{
    src_playback_rate_handle(src_playback_rate ? 0 : 1);
  }, [src_playback_rate, src_playback_rate_handle]);
  let rec_playing_toggle = useCallback(()=>{
    rec_playback_rate_handle(rec_playback_rate ? 0 : 1);
  }, [rec_playback_rate, rec_playback_rate_handle]);
  let focused_monitor_toggle = useCallback(()=>{
    focused_monitor_set(focused_monitor == 'src' ? 'rec' : 'src');
  }, [focused_monitor, focused_monitor_set]);
  let tc_input_reset = useCallback(()=>{
    tc_input_set(null);
  }, []);
  let tc_input_backspace = useCallback(()=>{
    if (!tc_input || !tc_input.n.length)
      return;
    let _tc_input = {...tc_input};
    _tc_input.n = _tc_input.n.slice(0, -1);
    tc_input_set(_tc_input);
  }, [tc_input]);
  let tc_input_reset_focused_monitor_toggle = useCallback(()=>{
    if (tc_input)
      return tc_input_reset();
    focused_monitor_toggle();
  }, [focused_monitor_toggle, tc_input, tc_input_reset]);
  let marker_remove = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return;
    let tc_track = lbin.rec_monitor_in.tracks.find(track=>{
      return track.type == 'tc_track';
    });
    let video_tracks = lbin.rec_monitor_in.tracks.filter(track=>{
      return track.type == 'timeline_track' && track.lbl.startsWith('V');
    });
    let audio_tracks = lbin.rec_monitor_in.tracks.filter(track=>{
      return track.type == 'timeline_track' && track.lbl.startsWith('A');
    });
    let tracks_order = [];
    if (tc_track)
      tracks_order.push(tc_track);
    tracks_order.push(...video_tracks.reverse());
    tracks_order.push(...audio_tracks);
    for (let track of tracks_order)
    {
      let _markers = editor.markers_get(track);
      for (let marker of _markers)
      {
        if (marker.abs_start == rec_frame)
        {
          let mob_id;
          if (timeline_mode == 'src')
            mob_id = lbin.src_monitor.mob_id;
          else if (timeline_mode == 'rec')
            mob_id = lbin.rec_monitor_in.mob_id;
          else
            assert(0, 'unexpected timeline_mode: ' + timeline_mode);
          return cmd_marker_remove(mob_id, track.id, marker.abs_start);
        }
      }
    }
  }, [cmd_marker_remove, lbin, rec_frame, timeline_mode]);
  let tc_input_backspace_marker_remove = useCallback(()=>{
    if (tc_input)
      return tc_input_backspace();
    marker_remove();
  }, [marker_remove, tc_input, tc_input_backspace]);
  let tc_input_apply = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.editrate
        || lbin?.rec_monitor_in?.start_tc === undefined || !tc_input
        || !tc_input.n)
    {
      return;
    }
    let cur_tc_o = tc.str2tc_o(tc.frame2tc(rec_frame,
      lbin.rec_monitor_in.editrate));
    let input_tc_o = tc.str2tc_o(tc_input.n.padStart(8, 0).match(/(..?)/gu)
      .join(':'));
    let start_tc_o = tc.str2tc_o(tc.frame2tc(lbin.rec_monitor_in.start_tc,
      lbin.rec_monitor_in.editrate));
    input_tc_o = tc.set_tc_o(start_tc_o, input_tc_o,
      lbin.rec_monitor_in.editrate);
    input_tc_o.h -= start_tc_o.h;
    let _tc_o;
    if (tc_input.action == 'add')
      _tc_o = tc.add_tc_o(cur_tc_o, input_tc_o, lbin.rec_monitor_in.editrate);
    if (tc_input.action == 'sub')
      _tc_o = tc.sub_tc_o(cur_tc_o, input_tc_o, lbin.rec_monitor_in.editrate);
    if (tc_input.action == 'set')
      _tc_o = tc.set_tc_o(cur_tc_o, input_tc_o, lbin.rec_monitor_in.editrate);
    let _rec_frame = tc.tc_o2frame(_tc_o, lbin.rec_monitor_in.editrate);
    rec_frame_handle(_rec_frame, false, true);
    tc_input_set(null);
  }, [lbin?.rec_monitor_in?.editrate, lbin?.rec_monitor_in?.start_tc, rec_frame,
    rec_frame_handle, tc_input]);
  let tc_input_handle = useCallback(key=>{
    let _tc = tc_input ? {...tc_input} : {action: 'set', n: ''};
    if (key == '+')
      _tc.action = 'add';
    else if (key == '-')
      _tc.action = 'sub';
    else if (_tc.n.length < 8)
      _tc.n += key;
    tc_input_set(_tc);
  }, [tc_input]);
  let toggle_selection_tool = useCallback(()=>{
    if (!drag_mode)
    {
      return drag_mode_set({type: 'select', segs: new Map(),
        track_ids: new Map()});
    }
    if (drag_mode.type == 'insert')
      return drag_mode_set({...drag_mode, type: 'select'});
    drag_mode_set(null);
  }, [drag_mode, drag_mode_set]);
  let toggle_insertion_tool = useCallback(()=>{
    if (!drag_mode)
    {
      return drag_mode_set({type: 'insert', segs: new Map(),
        track_ids: new Map()});
    }
    if (drag_mode.type == 'select')
      return drag_mode_set({...drag_mode, type: 'insert'});
    drag_mode_set(null);
  }, [drag_mode, drag_mode_set]);
  let toggle_fullscreen = useCallback(()=>{
    let el = editor_ref.current;
    if (!el)
      return;
    if (!document.fullscreenElement)
      el.requestFullscreen();
    else if (document.exitFullscreen)
      document.exitFullscreen();
  }, []);
  let marker_modal_open = useCallback((init_color=marker_colors.magenta)=>eserf(
    function* _marker_modal_open(){
      if (user_full.setting?.editor?.is_disable_marker_modal && aaf_in)
      {
        let mob_id;
        let abs_frame;
        if (timeline_mode == 'src')
        {
          mob_id = lbin.src_monitor.mob_id;
          abs_frame = src_frame;
        }
        else if (timeline_mode == 'rec')
        {
          mob_id = lbin.rec_monitor_in.mob_id;
          abs_frame = rec_frame;
        }
        else
          assert(0, 'unexpected timeline_mode: ' + timeline_mode);
        let res = yield cmd_marker_add(mob_id, editing_tracks[0].id, abs_frame,
          init_color, '');
        if (res.err)
        {
          message_api.error(t('Something went wrong'));
          metric.error('back_app.user_set_editor_setting', res.err);
        }
        return;
      }
      let marker_track_id;
      let marker_on_frame;
      for (let track of lbin.rec_monitor_in.tracks)
      {
        let markers = editor.markers_get(track);
        marker_on_frame = markers.find(marker=>marker.abs_start == rec_frame);
        marker_track_id = track.id;
        if (marker_on_frame)
          break;
      }
      if (marker_on_frame)
      {
        marker_modal_name_set(marker_on_frame.name);
        marker_modal_color_set(marker_on_frame.color);
        marker_modal_msg_set(marker_on_frame.comment);
        marker_modal_track_set(marker_track_id);
      }
      else
      {
        marker_modal_name_set('');
        marker_modal_color_set(init_color);
        marker_modal_msg_set('');
        marker_modal_track_set(editing_tracks[0].id);
      }
      is_marker_modal_open_set(true);
    }), [lbin, rec_frame, editing_tracks, src_frame, timeline_mode,
    cmd_marker_add, message_api, t, user_full, aaf_in]);
  let info_modal_close = useCallback(()=>{
    is_info_modal_open_set(false);
  }, []);
  let shortcuts_modal_open = useCallback(()=>{
    is_shortcuts_modal_open_set(true);
  }, []);
  let shortcuts_modal_close = useCallback(()=>{
    is_shortcuts_modal_open_set(false);
  }, []);
  let marker_modal_close = useCallback(()=>{
    is_marker_modal_open_set(false);
  }, []);
  let track_add_modal_open = useCallback(()=>{
    is_track_add_modal_open_set(true);
  }, []);
  let track_add_modal_close = useCallback(()=>{
    is_track_add_modal_open_set(false);
  }, []);
  let select_to_the_left = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return;
    let selected_segs = new Map();
    let track_ids = new Map();
    for (let track of lbin.rec_monitor_in.tracks)
    {
      let segs_map = player.abs_starts_get(track);
      for (let [seg] of segs_map.entries())
      {
        if (!allow_select_fillers && seg.type == 'filler')
          continue;
        if (seg.abs_start > rec_frame || seg.depth != 2)
          continue;
        selected_segs.set(seg, 0);
        track_ids.set(seg, track.id);
      }
    }
    drag_mode_set({type: 'select', segs: selected_segs, track_ids});
  }, [lbin?.rec_monitor_in?.tracks, rec_frame, drag_mode_set]);
  let select_to_the_right = useCallback(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return;
    let selected_segs = new Map();
    let track_ids = new Map();
    for (let track of lbin.rec_monitor_in.tracks)
    {
      let segs_map = player.abs_starts_get(track);
      for (let [seg] of segs_map.entries())
      {
        if (!allow_select_fillers && seg.type == 'filler')
          continue;
        let abs_end = seg.abs_start + seg.len;
        if (abs_end < rec_frame || seg.depth != 2)
          continue;
        selected_segs.set(seg, 0);
        track_ids.set(seg, track.id);
      }
    }
    drag_mode_set({type: 'select', segs: selected_segs, track_ids});
  }, [lbin?.rec_monitor_in?.tracks, rec_frame, drag_mode_set]);
  let select_in_out = useCallback(()=>{
    if (lbin?.rec_monitor_in?.mark_in === undefined
      || lbin?.rec_monitor_in?.mark_out === undefined
      || !lbin?.rec_monitor_in?.tracks)
    {
      return;
    }
    let mark_in = lbin.rec_monitor_in.mark_in;
    let mark_out = lbin.rec_monitor_in.mark_out;
    let selected_segs = new Map();
    let track_ids = new Map();
    for (let track of lbin.rec_monitor_in.tracks)
    {
      let segs_map = player.abs_starts_get(track);
      for (let [seg] of segs_map.entries())
      {
        if (!allow_select_fillers && seg.type == 'filler')
          continue;
        if (seg.depth != 2)
          continue;
        let abs_start = seg.abs_start;
        let abs_end = abs_start + seg.len;
        if (abs_start < mark_in && abs_end < mark_in)
          continue;
        if (abs_start > mark_out && abs_end > mark_out)
          continue;
        selected_segs.set(seg, 0);
        track_ids.set(seg, track.id);
      }
    }
    drag_mode_set({type: 'select', segs: selected_segs, track_ids});
  }, [lbin?.rec_monitor_in?.mark_in, lbin?.rec_monitor_in?.mark_out,
    lbin?.rec_monitor_in?.tracks, drag_mode_set]);
  let undo = useCallback(()=>{
    let lbin_change = lbin_changes[cur_lbin_change_idx];
    if (!lbin_change)
      return;
    let _lbin = _.cloneDeep(lbin);
    lbin_change.diffs.forEach(diff=>{
      deep_diff.revertChange(_lbin, _lbin, diff);
    });
    lbin_set(_lbin);
    aaf_in_set(lbin_change.aaf_in);
    etag_set(lbin_change.etag);
    cur_lbin_change_idx_set(cur_lbin_change_idx - 1);
  }, [cur_lbin_change_idx, lbin, lbin_changes]);
  let redo = useCallback(()=>{
    let lbin_change = lbin_changes[cur_lbin_change_idx + 1];
    if (!lbin_change)
      return;
    let _lbin = _.cloneDeep(lbin);
    lbin_change.diffs.forEach(diff=>{
      deep_diff.applyChange(_lbin, _lbin, diff);
    });
    lbin_set(_lbin);
    aaf_in_set(lbin_change.aaf_in);
    etag_set(lbin_change.etag);
    cur_lbin_change_idx_set(cur_lbin_change_idx + 1);
  }, [cur_lbin_change_idx, lbin, lbin_changes]);
  let action2func = useMemo(()=>({
    tc_numbers: e=>tc_input_handle(e.key),
    tc_add: ()=>tc_input_handle('+'),
    tc_sub: ()=>tc_input_handle('-'),
    tc_cancel_focused_monitor_toggle: tc_input_reset_focused_monitor_toggle,
    tc_apply: tc_input_apply,
    tc_backspace_marker_remove: tc_input_backspace_marker_remove,
    toggle_selection_tool,
    toggle_insertion_tool,
    info_modal_open: ()=>is_info_modal_open_set(!is_info_modal_open),
    undo,
    redo,
  }), [tc_input_reset_focused_monitor_toggle, tc_input_apply,
    tc_input_backspace_marker_remove, toggle_selection_tool, undo, redo,
    tc_input_handle, is_info_modal_open, toggle_insertion_tool]);
  useEffect(()=>{
    let unsubscribe = tinykeys(window, key_binding_map_get(action2func));
    return ()=>unsubscribe();
  }, [action2func]);
  let cmts_get = useCallback(()=>eserf(function* _cmts_get(){
    if (!token || !lbin?.rec_monitor_in?.tracks)
      return;
    let _lbin = _.cloneDeep(lbin);
    let comment_track = _lbin.rec_monitor_in.tracks.find(track=>{
      return track.type == 'cmts_track';
    });
    if (comment_track)
    {
      _lbin.rec_monitor_in.tracks = _lbin.rec_monitor_in.tracks.filter(track=>{
        return track != comment_track;
      });
    }
    let res = yield back_app.cmt.get_list(token, lbin.rec_monitor_in.mob_id);
    if (res.err)
      return false;
    let _comment_track = {type: 'cmts_track', zidx: 10, lbl: cmt_track_id,
      id: cmt_track_id, height: 'small', is_edit: false, start: 0, len: 26193,
      is_synclock: true, ctrl: {has_synclock: true},
      editrate: {...lbin.rec_monitor_in.editrate},
      arr: [{type: 'segment', is_hide: true, start: lbin.rec_monitor_in.start,
        abs_start: 0, len: lbin.rec_monitor_in.len, arr: res.cmts.map(cmt=>({
          type: 'cmt', start: cmt.frame - lbin.rec_monitor_in.start_tc,
          is_no_arr: true, ...cmt}))}], abs_start: 0};
    _lbin.rec_monitor_in.tracks.push(_comment_track);
    lbin_set(_lbin);
    cmts_set(res.cmts);
    return true;
  }), [lbin, token]);
  use_effect_eserf(()=>eserf(function* _use_effect_initial_fetch(){
    if (is_cmts_loaded)
      return;
    let is_succeed = yield cmts_get();
    if (!is_succeed)
      return;
    is_cmts_loaded_set(true);
  }), [cmts_get, is_cmts_loaded, lbin, token]);
  useEffect(()=>{
    set_is_rate(org && user_full && user_full.rate==undefined && org.credit
      && org.credit.v<-50);
  }, [org, user_full]);
  let lbin_change_handle = useCallback(_lbin=>{
    lbin_set(_lbin);
    let _rec_editing_track_ids;
    let _rec_selected_monitor;
    if (_lbin.rec_monitor_in)
    {
      _rec_editing_track_ids = _lbin.rec_monitor_in.tracks
        .filter(track=>track.is_edit)
        .map(track=>track.id);
      let rec_selected_monitor_track = _lbin.rec_monitor_in.tracks
        .find(track=>track.is_monitor_selected);
      _rec_selected_monitor = {track_id: rec_selected_monitor_track.id};
    }
    else
    {
      _rec_editing_track_ids = [];
      _rec_selected_monitor = null;
    }
    rec_editing_track_ids_set(_rec_editing_track_ids);
    rec_selected_monitor_set(_rec_selected_monitor);
    lbin_changes_set([]);
    cur_lbin_change_idx_set(-1);
    nested_track_paths_set({});
    is_cmts_loaded_set(false);
    cmts_set([]);
  }, [rec_editing_track_ids_set, rec_selected_monitor_set]);
  use_effect_eserf(()=>eserf(function* use_effect_load_from_src(){
    if (!initial_src?.file || !initial_src?.etag || !initial_src?.task_id)
      return;
    if (!token || lbin)
      return;
    is_loading_set(true);
    let res = yield back_app.editor_aaf_upload(token, user.email,
      {aaf_in: initial_src.file, etag: initial_src.etag,
        task_id: initial_src.task_id});
    if (res.err)
    {
      metric.error('use_effect_load_from_src', str.j2s(res));
      void message_api.error(`file upload failed ${res.err}`);
      is_loading_set(false);
      return;
    }
    let filename = qs_o.src_filename;
    if (filename)
      lbin_name_set(filename.replace('.aaf', ''));
    lbin_change_handle(res.lbin);
    aaf_in_set(res.file);
    aaf_in_ref.current = res.file;
    task_id_set(res.task_id);
    task_id_ref.current = res.task_id;
    etag_set(res.etag);
    is_loading_set(false);
  }), [initial_src?.file, initial_src?.etag, initial_src?.task_id, message_api,
    token, user.email, lbin, lbin_change_handle]);
  let track_ids = useMemo(()=>{
    if (!lbin?.rec_monitor_in?.tracks)
      return [];
    return lbin.rec_monitor_in.tracks.map(track=>track.id);
  }, [lbin?.rec_monitor_in?.tracks]);
  let aaf_in_change_handle = useCallback(_aaf_in=>{
    aaf_in_set(_aaf_in);
    aaf_in_ref.current = _aaf_in;
  }, []);
  // XXX colin: wait till token exists before allowing usage
  // XXX colin: change to use ereq and send to metric on err
  let props = {
    name: 'file',
    listType: 'picture',
    action: xurl.url(prefix+'/private/editor/aaf/upload.json',
      {email: user.email, ver: config_ext.ver, dbg_py: qs_o.dbg_py}),
    onChange(info){
      if (info.file.status != 'uploading')
        console.log(info.file, info.fileList);
      if (info.file.status == 'done')
      {
        let resp = info.file.response;
        if (resp.err)
          return metric.error('error on done', resp.err);
        message_api.success(`${info.file.name} file uploaded successfully`);
        let file = resp.file;
        lbin_change_handle(resp.lbin);
        etag_set(resp.etag);
        lbin_name_set(info.file.name.replace('.aaf', ''));
        info.file.url = xurl.url(prefix+'/private/aaf/get.aaf',
          {email: user.email, file, token, ver: config_ext.ver});
        let file_prev = info.file.name;
        info.file.name =
          <>
            <span>
              {str.trun(file_prev, 50)} {'->'} {str.trun(file, 20)}
            </span>
            <Button icon={<DownloadOutlined/>}/>
          </>;
        aaf_in_set(file);
        aaf_in_ref.current = file;
        task_id_set(resp.task_id);
        task_id_ref.current = resp.task_id;
        je.set_inc('auth.update_n'); // update org to get list of tasks
      }
      else if (info.file.status == 'error')
      {
        let resp = info.file.response;
        let err_id2str = {
          is_not_aaf: 'Is not an AAF format, look at how you exported it',
          invalid_file_type: 'Invalid file type',
          org_no_credit:
            <>
              <Space direction="vertical">
                <div>
                  Contact your Toolium representative to buy more credits
                </div>
                <div>See your credits in profile</div>
                <Contact is_no_txt={true}/>
              </Space>
            </>,
          org_is_disable:
            <>
              <div>Contact your Toolium representative to activate your
                account
              </div>
              <Contact is_no_txt={true}/>
            </>,
          proc_type_unknown: 'Process type unknown',
          no_clips_found_on_sequence: 'No clips found on sequence',
          no_clips_for_writing_sequence: 'No clips for writing sequence',
          no_sequence_found_in_aaf_file: 'No sequence found in AAF file',
          sequence_has_length_of_0: 'Sequence_has_length of 0',
          group_clip_found_on_sequence: 'Group clip found on sequence',
          group_clip_found_on_sequence_2: 'Group clip found on sequence 2',
          unknown_selector_type_found_on_sequence:
            'Unknown selector type found in sequence',
          clip_framerate_does_not_match_sequence_framerate:
            'Clip framerate does not match sequence framerate',
          subclips_with_motion_effects_are_not_supported:
            'Subclips with motion effects are not supported',
          in_greater_equal_out: 'Some of your timecodes are invalid',
        };
        let err_s = err_id2str[resp.err]||resp.err;
        if (err_s==resp.err)
          metric.error('editor_missing_err_id', err_s);
        else
          metric.error('editor_upload_err_'+resp.err);
        info.file.error.message = err_s;
        modal.error({title: `${info.file.name} file upload failed`,
          content: err_s});
        return void message_api.error(`${info.file.name} file upload failed `
          +`${resp.err}`);
      }
    },
  };
  let _on_change = rate=>{
    // XXX colin: ask for feedback and why?
    back_app.user_set_rate(token, user.email, rate);
    if (rate<4)
      return void set_is_rate(false);
    // XXX colin: change to be toolium.org
    window.location.href = 'https://www.trustpilot.com/review/www.toolium.org';
  };
  if (token)
    props.headers = ereq.auth_hdr(token);
  if (is_rate)
  {
    modal.confirm({
      title: t('Rate the app to help us improve'),
      content: <Clickable>
        <Rate onChange={_on_change} allowHalf defaultValue={3.5} />
      </Clickable>,
      okButtonProps: {disabled: true, style: {display: 'none'}},
      cancelText: t('I don\'t wanna'),
      onOk(){},
      onCancel(){},
    });
  }
  else
    Modal.destroyAll();
  let cursor = useMemo(()=>{
    if (!drag_mode)
      return 'auto';
    if (drag_mode.type == 'select')
      return `url(${selection_cursor}), auto`;
    if (drag_mode.type == 'insert')
      return `url(${insertion_cursor}), auto`;
  }, [drag_mode]);
  if (!token)
    return <Loading/>;
  return (
    <>
      {message_ctx_holder}
      {modal_ctx_holder}
      {!qs_o.dbg && <Desktop_required_modal
        title={t('Desktop is required for editor')} />}
      <Modal
        title={t('Want to edit in your browser using our Avid '
          +'Media Composer compatible editor?')}
        okText={t('Yes, join pre launch')}
        cancelText={t('No I don\'t want')}
        open={!is_early_access_editor}
        onOk={()=>eserf(function* on_ok(){
          set_modal_load(true);
          yield back_app.user_early_access_editor(token, user.email);
          set_modal_load(false);
          je.set_inc('editor.update_n');
        })}
        confirmLoading={modal_load}
        onCancel={()=>navigate(xurl.url('/', qs_o))}
      />
      {is_early_access_editor && !initial_src && <Row justify="center">
        <Col>
          <Space direction="vertical" size="large" align="center">
            <Row><Title>EDITOR</Title></Row>
            <Row>
              <Upload {...props}>
                <Clickable>
                  <Button type="primary" icon={<UploadOutlined />}
                    style={{height: '10vw', width: '60vw', fontSize: '3vw'}}>
                    {t('Click to Upload AAF')}
                  </Button>
                </Clickable>
              </Upload>
            </Row>
          </Space>
        </Col>
      </Row>}
      {is_early_access_editor && !initial_src && <Divider/>}
      <ConfigProvider
        theme={{
          token: {fontSize: 11, color: gray[9]},
          components: {
            Table: {headerBg: '#00000000', colorBgContainer: '#00000000',
              borderColor: gray[6], headerSplitColor: gray[6], borderRadius: 0,
              headerBorderRadius: 0, colorText: gray[9], headerColor: gray[9],
              cellFontSize: 11, cellFontSizeMD: 11, cellFontSizeSM: 11,
              cellPaddingBlock: 4, cellPaddingBlockSM: 4,
              cellPaddingBlockMD: 4},
            Select: {borderRadius: 0, borderRadiusLG: 0, borderRadiusSM: 0,
              borderRadiusXS: 0, fontSize: 11, fontSizeLG: 11, fontSizeSM: 11,
              optionFontSize: 11, colorBgContainer: gray[4],
              colorBgElevated: gray[4], optionSelectedBg: gray[5],
              controlHeight: 24, optionHeight: 24, colorText: gray[9]},
            Input: {borderRadius: 0, borderRadiusLG: 0, borderRadiusSM: 0,
              fontSize: 11, colorBgContainer: gray[4], controlHeight: 24,
              colorText: gray[9], colorTextPlaceholder: gray[6]},
            InputNumber: {borderRadius: 0, borderRadiusLG: 0, borderRadiusSM: 0,
              fontSize: 11, fontSizeLG: 11, colorBgContainer: gray[4],
              controlHeight: 24, colorText: gray[9]},
            Modal: {contentBg: gray[5], headerBg: gray[5], borderRadiusLG: 0,
              borderRadiusSM: 0, colorText: gray[9], titleColor: gray[9],
              fontSize: 11},
            Button: {borderRadius: 0, borderRadiusLG: 0, borderRadiusSM: 0,
              fontSize: 11, defaultBg: gray[4], defaultHoverBg: gray[3],
              defaultActiveBg: gray[5], colorText: gray[9],
              defaultHoverBorderColor: 'transparent', primaryShadow: 'none',
              defaultActiveBorderColor: 'transparent', controlHeight: 24,
              defaultBorderColor: 'transparent', defaultShadow: 'none',
              dangerShadow: 'none', borderColorDisabled: 'transparent'},
            Pagination: {borderRadius: 0, borderRadiusLG: 0, borderRadiusSM: 0,
              fontSize: 11, itemBg: gray[4], defaultHoverBg: gray[3],
              itemActiveBg: gray[5], colorText: gray[9],
              colorBorder: gray[6], colorPrimaryBorder: gray[6],
              controlHeight: 24},
            Checkbox: {fontSize: 11, colorText: gray[9], borderRadiusSM: 0,
              colorBgContainer: gray[4], colorBorder: gray[6]},
            Tabs: {cardGutter: 0, borderRadius: 0, borderRadiusLG: 0,
              cardPadding: '2px 16px', cardPaddingSM: '2px 16px',
              cardPaddingLG: '2px 16px', colorText: gray[9],
              colorTextSelected: gray[9], colorTextSelectedDisabled: gray[9],
              colorTextDisabled: gray[6], colorBgContainer: gray[5],
              itemSelectedColor: gray[9], itemHoverColor: gray[9],
              itemActiveColor: gray[9], margin: 0, marginSM: 0, marginXS: 0,
              marginXXS: 0},
            Tree: {marginXS: 0, marginXXS: 0, paddingXS: 0, colorText: gray[9],
              nodeSelectedBg: 'transparent'},
          },
        }}
      >
        {is_loading && <editor.Loading_overlay />}
        <Info_modal lbin={lbin} is_open={is_info_modal_open} frame={rec_frame}
          on_close={info_modal_close} />
        <Shortcuts_modal is_open={is_shortcuts_modal_open}
          on_close={shortcuts_modal_close} />
        <Edit_marker_modal lbin={lbin} is_open={is_marker_modal_open}
          on_close={marker_modal_close} cmd_marker_add={cmd_marker_add}
          init_name={marker_modal_name} init_color={marker_modal_color}
          frame={rec_frame} init_track={marker_modal_track}
          init_cmt={marker_modal_msg} token={token} user={user}
          user_full={user_full} />
        <Track_add_modal lbin={lbin} is_open={is_track_add_modal_open}
          on_close={track_add_modal_close} cmd_track_add={cmd_track_add}
          track_ids={track_ids} />
        <Quick_transition_modal is_open={false} />
        <div ref={editor_ref} style={{width: '100%', minWidth: '1146px',
          minHeight: '90vh', height: '1px', position: 'relative', cursor}}>
          <Row style={{height: '50%'}}>
            <Col span={4} style={{height: '100%'}}>
              <Bin_panel token={token} user={user} user_full={user_full}
                org={org} onClick={()=>focused_panel_set('bin')}
                on_aaf_in_change={aaf_in_change_handle}
                on_lbin_change={lbin_change_handle}
                etag={etag} etag_set={etag_set} lbin={lbin} task_id={task_id}
                cmd_rec_monitor_load_clip={cmd_rec_monitor_load_clip}
                cmd_src_monitor_load_clip={cmd_src_monitor_load_clip}
                cmd_clip_duplicate={cmd_clip_duplicate} aaf_in={aaf_in}
                lbin_name={lbin_name} on_lbin_name_change={lbin_name_set} />
            </Col>
            <Col span={20}>
              <Composer_panel lbin={lbin} fps={fps} tc_input={tc_input}
                src_playing_toggle={src_playing_toggle} token={token}
                rec_playing_toggle={rec_playing_toggle}
                src_step_backward_1_frame={()=>src_move_frame(-1)}
                src_step_forward_1_frame={()=>src_move_frame(1)}
                src_step_backward_10_frames={()=>src_move_frame(-10)}
                src_step_forward_10_frames={()=>src_move_frame(10)}
                rec_step_backward_1_frame={()=>rec_move_frame(-1)}
                rec_step_forward_1_frame={()=>rec_move_frame(1)}
                rec_step_backward_10_frames={()=>rec_move_frame(-10)}
                rec_step_forward_10_frames={()=>rec_move_frame(10)}
                go_to_prev_cut={go_to_prev_cut} go_to_next_cut={go_to_next_cut}
                is_drawing={is_drawing} cmd_selector_set={cmd_selector_set}
                drawing_view_box_set={drawing_view_box_set}
                drawing_objs={drawing_objs} drawing_objs_set={drawing_objs_set}
                drawing_tool={drawing_tool} drawing_color={drawing_color}
                cmt_svg={cmt_svg} onClick={()=>focused_panel_set('composer')}
                cmd_cut={cmd_cut} cmd_mark_in={cmd_mark_in}
                cmd_mark_out={cmd_mark_out} cmd_mark_clip={cmd_mark_clip}
                cmd_lift={cmd_lift} cmd_extract={cmd_extract}
                go_to_mark_in={go_to_mark_in} go_to_mark_out={go_to_mark_out}
                src_playback_rate={src_playback_rate}
                src_playback_rate_set={src_playback_rate_handle}
                src_frame={src_frame} on_src_frame_change={src_frame_handle}
                rec_playback_rate={rec_playback_rate}
                rec_playback_rate_set={rec_playback_rate_handle}
                rec_frame={rec_frame} lbin_set={lbin_set}
                on_rec_frame_change={rec_frame_handle}
                drawing_view_box={drawing_view_box} />
            </Col>
          </Row>
          <Row style={{height: '50%'}}>
            <Col span={24}>
              <Timeline_panel lbin={lbin} zoom={zoom} token={token}
                on_zoom_change={zoom_set} scroll_x={scroll_x} user={user}
                on_scroll_x_change={scroll_x_set} aaf_in={aaf_in}
                tracks_wrapper_width={tracks_wrapper_width}
                on_tracks_wrapper_width_change={tracks_wrapper_width_set}
                rec_frame={rec_frame} on_rec_frame_change={rec_frame_handle}
                rec_playback_rate={rec_playback_rate}
                on_rec_playback_rate_change={rec_playback_rate_handle}
                on_src_frame_change={src_frame_handle}
                cmd_track_add={cmd_track_add}
                on_cmts_panel_toggle={cmts_panel_open_set}
                nested_track_paths={nested_track_paths}
                on_nested_track_paths_change={nested_track_paths_set}
                toggle_fullscreen={toggle_fullscreen}
                onClick={()=>focused_panel_set('timeline')}
                cmd_mark_in={cmd_mark_in} cmd_mark_out={cmd_mark_out}
                cmd_cut={cmd_cut} cmd_mark_clip={cmd_mark_clip}
                cmd_clear_both_marks={cmd_clear_both_marks} cmd_lift={cmd_lift}
                cmd_extract={cmd_extract} cmd_selector_set={cmd_selector_set}
                cmd_power={cmd_power} cmd_solo={cmd_solo} cmd_mute={cmd_mute}
                cmd_synclock={cmd_synclock} cmd_lock={cmd_lock}
                marker_modal_open={marker_modal_open}
                select_to_the_left={select_to_the_left}
                select_to_the_right={select_to_the_right}
                select_in_out={select_in_out} play_in_to_out={play_in_to_out}
                track_add_modal_open={track_add_modal_open}
                cmd_track_remove={cmd_track_remove}
                trim_toggle={trim_toggle} cmd_clip_move={cmd_clip_move}
                cmd_clip_move_insert={cmd_clip_move_insert}
                trim_drag_start_handle={trim_drag_start_handle}
                trim_shift={trim_shift} src_frame={src_frame}
                on_src_playback_rate_change={src_playback_rate_handle}
                cmd_match_frame={cmd_match_frame}
                cmd_insert={cmd_insert}
                toggle_selection_tool={toggle_selection_tool}
                toggle_insertion_tool={toggle_insertion_tool}
                src_playback_rate={src_playback_rate}
                src_playback_rate_set={src_playback_rate_set}
                shortcuts_modal_open={shortcuts_modal_open} />
            </Col>
          </Row>
          {cmts_panel_open && <Cmts_panel
            editrate={lbin.rec_monitor_in.editrate} rec_frame={rec_frame}
            fps={fps} start_tc={lbin.rec_monitor_in.start_tc}
            lbin={lbin} cmts_panel_open_set={cmts_panel_open_set} cmts={cmts}
            rec_frame_set={rec_frame_handle} cmts_get={cmts_get}
            is_drawing={is_drawing} is_drawing_set={is_drawing_set}
            drawing_objs={drawing_objs} drawing_objs_set={drawing_objs_set}
            drawing_tool={drawing_tool} drawing_tool_set={drawing_tool_set}
            drawing_color={drawing_color} drawing_color_set={drawing_color_set}
            drawing_view_box={drawing_view_box} cmt_svg_set={cmt_svg_set}
            token={token} rec_playback_rate_set={rec_playback_rate_set}
            user_full={user_full} />}
        </div>
      </ConfigProvider>
    </>
  );
};

export default auth.with_auth_req(E);
