// LICENSE_CODE MIT
import assert from 'node:assert';
import fs from 'node:fs'; // XXX colin: use fs.js
import process from 'node:process';
import xpath from 'node:path';
import os from 'node:os';
import readline from 'node:readline';
import url from 'url';
import child_process from 'node:child_process';
//import {spawnSync, spawn} from 'child_process';
import str from './str.js';
import xdate from './date.js';
import eserf from './eserf.js';
import argparse from './argparse.js';

// XXX: done to support vite remove once add polyfill to vite
let spawnSync = child_process?.spawnSync, spawn = child_process?.spawn;
let E = {};
export default E;

E.is_node = process&&process.title!='browser';
E.is_bun = !!process?.versions?.bun;
E.is_test = process&&process.env.IS_TEST;
E.is_win = os.platform()=='win32';
E.is_mac = os.platform()=='darwin';
E.is_arm = process.arch=='arm' || process.arch=='arm64';
E.is_linux = !E.is_win && !E.is_mac;
let _is_docker;
E.is_docker = ()=>{
  if (_is_docker===undefined)
    _is_docker = fs.existsSync('/.dockerenv');
  return _is_docker;
};

// XXX: merge with ./fs.js path_win2posix
//E.path_win2posix = _path=>_path.replaceAll(xpath.win32.sep, xpath.posix.sep);
E.path_win2posix = _path=>_path.replaceAll('\\', '/');

E.exec_path = ()=>E.path_win2posix(process.execPath);
// execPath when single exe
// execPath when bun or node
E.__dirname = import_meta_url=>{
  let is_bun_single_exe = E.is_bun_single_exe();
  if (is_bun_single_exe)
    return E.path_win2posix(xpath.dirname(process.execPath));
  return xpath.dirname(url.fileURLToPath(import_meta_url));
};

// import_meta_main is true only when is from the main js
E.is_bun_single_exe = ()=>{
  // when running js with bun single exe then argv==['bun', 'B:/~BUN/root/']
  // when running js with bun then argv==['c:/..../bun.exe', 'c:/somefile.js']
  return process.versions.bun && process.argv[0]=='bun';
};

E.cwd = ()=>E.path_win2posix(process.cwd());
E.tmpdir = ()=>{
  if (E.is_mac || E.is_linux)
    return '/tmp';
  return E.path_win2posix(os.tmpdir());
};

E.is_main = (import_meta_url, opt)=>{
  if (!E.is_node)
    return;
  // support bun single exe build entrypoint
  if (import_meta_url.includes(E.is_win ? '~BUN' : '$bunfs'))
    return opt?.import_meta_main;
  let is_mjs_parent = opt?.is_mjs_parent;
  let curr_path = url.fileURLToPath(import_meta_url);
  let [bin_path, mod_path] = process.argv;
  if (is_mjs_parent && mod_path.endsWith('.mjs'))
    mod_path = mod_path.slice(0, mod_path.length-4);
  return curr_path == mod_path || curr_path == mod_path+'.js'
    || curr_path == mod_path+'.cjs' || curr_path == mod_path+'.mjs';
};
E.cur_pid = ()=>process.pid;
E.spawn = (argv, cwd, is_print, shell, _env, no_console, detached,
  max_buffer=1024*1024)=>{
  return E._spawn(argv, cwd, is_print, shell, false, _env, no_console,
    detached, max_buffer);
};

// XXX colin: add eserf support
E._spawn = (argv, cwd, is_print, shell, is_no_escape, _env, no_console,
  detached, max_buffer=1024*1024)=>{
  let env = process.env;
  if (_env)
    env = Object.assign({}, process.env, _env);
  cwd = cwd||process.cwd();
  if (typeof argv == 'string')
    argv = argv.split(' ');
  let argv_escape = argv.map(arg=>E.escape(arg));
  if (shell && !is_no_escape)
    argv = argv_escape;
  if (!no_console)
  {
    let cmd_out = 'cd '+cwd+' &&';
    if (_env)
    {
      for (let k in _env)
        cmd_out += ` ${k}="${_env[k]}"`;
    }
    cmd_out += ' '+argv_escape.join(' ');
    console.log(cmd_out);
  }
  let stdio = is_print ? 'inherit' : undefined;
  // XXX colin: add escaping if shell
  return spawnSync(argv.shift(), argv,
    {encoding: 'utf-8', stdio, cwd, shell, env, detached,
      maxBuffer: max_buffer});
};

E._spawn_e = (argv, cwd, is_print, shell, is_no_escape, env, no_console)=>{
  if (typeof argv == 'string')
    argv = argv.split(' ');
  let res = E._spawn(argv, cwd, is_print, shell, is_no_escape, env,
    no_console);
  if (res.status || res.errno || res.error)
    throw new Error(`${argv.join(' ')} failed: ${str.j2s(res)}`);
  return res;
};

E.spawn_e = (argv, cwd, is_print, shell, env, no_console)=>{
  return E._spawn_e(argv, cwd, is_print, shell, false, env, no_console);
};

let memory_limit = 1288490189;
let memory_limit_diff = 214748365;
let memory_limit_alert_level = 0;

E.mem_usage = memory_limit_cb=>{
  let usage = process.memoryUsage().heapUsed;
  let next_alert_limit = memory_limit +
    memory_limit_diff * memory_limit_alert_level;
  if (usage >= next_alert_limit)
  {
    if (memory_limit_cb)
      memory_limit_cb(usage, next_alert_limit, memory_limit_alert_level);
    memory_limit_alert_level++;
    let mb = (usage/(1024*1024)).toFixed(2);
    console.log(`memory check ${mb} mb`);
  }
  return usage;
};
E.print_memory_usage = (opt = {})=>{
  let output = opt.output || console.log;
  let usage = E.mem_usage();
  let mb = (usage/(1024*1024)).toFixed(2);
  let log = `memory usage ${mb} mb`;
  if (opt.tag)
    log = `${opt.tag} ${log}`;
  output(log);
};
const escape2single = s=>{
  s = ''+s;
  if (!s)
    return '""';
  // eslint-disable-next-line no-useless-escape
  if ((/^[a-z0-9_\-.\/:]+$/ui).test(s))
    return s;
  return '"'+s.replace(/([\\"`$])/ug, '\\$1')+'"';
};

E.escape = (...args)=>{
  let _a = args[0];
  if (args.length==1 && !Array.isArray(_a))
    return escape2single(_a);
  let s = '', a = Array.isArray(_a) ? _a : args;
  for (let i=0; i<a.length; i++)
    s += (i ? ' ' : '')+escape2single(a[i]);
  return s;
};

// XXX: return same string once moved to escape in proc.espawn
E.escape2 = (...args)=>{
  if (args.length==1)
    return args[0];
  assert(0, 'invalid escape2 args');
};

E.espawn = (argv, opt, on_stdout, on_stderr)=>eserf(function* espawn(){
  let cancelled = true;
  opt = opt || {};
  let {cwd, is_print, shell, env, no_console, detached,
    is_ignore, is_no_print_err, is_int, is_no_escape} = opt;
  env = env||process.env;
  cwd = cwd||process.cwd();
  if (typeof argv == 'string')
    argv = argv.split(' ');
  let argv_escape = argv.map(arg=>E.escape(arg));
  if (!no_console)
    console.log('cd '+cwd+' &&', argv_escape.join(' '));
  let stdio = is_print ? 'inherit' : is_ignore ? 'ignore' : undefined;
  let pinfo;
  let p;
  if (shell && !is_no_escape)
    argv = argv_escape;
  if (E.is_win && !shell)
  {
    try {
      p = spawn(argv.shift(), argv,
        {encoding: 'utf-8', stdio, cwd, shell, env, detached});
    } catch(e) {
      let s = e.toString();
      if (s.includes('Executable not found in $PATH:'))
        return {code: 'ENOENT', stderr: 'ENOENT', err: 'not_found'};
      throw e;
    }
  }
  else
  {
    p = spawn(argv.shift(), argv,
      {encoding: 'utf-8', stdio, cwd, shell, env, detached});
  }
  this.finally(()=>{
    if (!p || !cancelled || is_int)
      return;
    let pid = p.pid;
    // XXX: send to child p.pid is the parent if using shell
    if (E.is_win && shell && pinfo)
      pid = +pinfo.pid;
    console.log(`SIGINT to pid ${pid}`);
    process.kill(pid, 'SIGINT');
  });
  let _stdout = '';
  let _stderr = '';
  p.on('close', code=>{
    if (code!=0 && !is_no_print_err && !no_console)
      console.log(`process exited with code ${code}`);
    this.continue({code: code, stdout: _stdout, stderr: _stderr});
  });
  if (!stdio)
  {
    p.stdout.on('data', data=>{
      if (on_stdout)
        return on_stdout('notice', data);
      _stdout += data;
    });
    p.stderr.on('data', data=>{
      if (is_no_print_err)
        return;
      if (on_stderr)
        return on_stderr('error', data);
      if (!no_console)
        console.error(`stderr: ${data}`);
      _stderr += data;
    });
  }
  // cmd.exe in windows doesnt propagate SIGINT to child so we need to kill
  // child directly using pid
  if (0 && E.is_win && shell && !is_int)
  {
    this.spawn(eserf(function* _espawn_ps_int(){
      while (1)
      {
        let pinfos = yield E.ps({ppid: p.pid});
        if (pinfos.err)
        {
          yield eserf.sleep(100);
          continue;
        }
        pinfo = pinfos[0];
        break;
      }
    }));
  }
  let res = yield this.wait();
  cancelled = false;
  return {...p, code: res.code, stdout: res.stdout, stderr: res.stderr};
});

E.espawn_e = (argv, opt)=>eserf(function*(){
  if (typeof argv == 'string')
    argv = argv.split(' ');
  let p = yield E.espawn(argv, opt);
  if (p.exitCode)
    throw new Error(`${argv.join(' ')} failed: ${str.j2s(p)}`);
  return p;
});

E.readline = txt=>eserf(function* _proc_readline(){
  let rl = readline.createInterface({input: process.stdin,
    output: process.stdout});
  rl.question(txt, prompt=>{
    rl.close();
    this.continue(prompt);
  });
  let prompt = yield this.wait();
  return prompt.trim();
});

E.init = (uninit_before_exit, uninit_exit)=>{
  let is_dbg_log = 0&&E.is_bun_single_exe();
  if (is_dbg_log)
  {
    let pid = E.cur_pid(), pinfo;
    eserf(function* _proc_init(){
      let pinfos = yield E.ps();
      pinfo = pinfos.find(_pinfo=>+_pinfo.pid==pid);
    });
    console.log = (...args)=>{
      let ppid = E.is_win && pinfo?.ppid ? pinfo?.ppid : process.ppid;
      let file = E.is_win ? './tlm.log' : '/tmp/tlm.log';
      let s = args.map(e=>e.toString()).join(' ');
      fs.appendFileSync(file,
        `${xdate.fmt_iso(new Date())} ${ppid}->${E.cur_pid()}: ${s}\n`);
    };
  }
  process.on('uncaughtException', (err, origin)=>{
    fs.writeSync(process.stderr.fd, `caught exception ${err}\n`
      +`origin ${origin}`);
  });
  process.on('unhandledPromiseRejection', (err, origin)=>{
    fs.writeSync(process.stderr.fd, `caught exception ${err}\n`
      +`origin ${origin}`);
  });
  process.on('exit', (...args)=>{
    console.log('exit', ...args);
    if (uninit_exit)
      uninit_exit();
  });
  let uninited;
  process.on('SIGINT', (...args)=>eserf(function* proc_sigint(){
    if (uninited)
      console.error('already uninited');
    uninited = true;
    console.log('SIGINT', ...args);
    if (uninit_before_exit)
      yield uninit_before_exit();
    process.exit();
  }));
  process.on('SIGTERM', (...args)=>eserf(function* proc_sigterm(){
    if (uninited)
      console.error('already uninited');
    uninited = true;
    console.log('SIGTERM', ...args);
    if (uninit_before_exit)
      yield uninit_before_exit();
    process.exit();
  }));
};
E.uninit = ()=>{
  if (0)
    console.log('proc.uninit eserf ps', eserf.ps());
};

let csv_s2j = s=>{
  let ret = [];
  let rows = s.split('\n');
  let headers = [];
  for (let row of rows)
  {
    let cols = str.trim(row).split(',');
    if (cols.length<=2 && cols[0]=='')
      continue;
    if (!headers.length)
    {
      headers = cols;
      continue;
    }
    let _row = {};
    for (let i=0; i<cols.length; i++)
      _row[headers[i]] = cols[i];
    ret.push(_row);
  }
  return ret;
};

export let ps = E.ps = opt=>eserf(function* proc_ps(){
  if (!E.is_win)
    assert(0, 'unsupported ps');
  let ppid = opt?.ppid;
  let pid = opt?.pid;
  let cwd = E.cwd();
  // XXX: cant pass param to make faster
  // E.escape('processid,parentprocessid,executablepath')];
  let cmd_prefix = ['wmic', 'process'];
  let cmd_suffix = ['/format:csv'];
  let cmd = ['get'];
  // with shell: true cant add ( or E.escape
  if (ppid!==undefined)
    cmd = ['where', `ParentProcessId=${ppid}`, 'get'];
  if (pid!==undefined)
    cmd = ['where', `ProcessId=${pid}`, 'get'];
  let p = yield E.espawn([...cmd_prefix, ...cmd, ...cmd_suffix],
    {cwd, is_ignore: false, is_print: false, shell: false, is_int: true,
      no_console: false});
  if (p.code)
  {
    if (p.stderr.includes('Invalid query'))
      return {err: 'invalid_query'};
    if (p.stderr.includes('ERROR'))
      return {err: p.stderr};
    return {err: p.stderr, p};
  }
  if (p.stderr.includes('No Instance(s) Available'))
    return {err: 'not_found'};
  let ret = csv_s2j(p.stdout);
  for (let row of ret)
  {
    row.ppid = row.ParentProcessId;
    row.pid = row.ProcessId;
    row.cmd_line = row.CommandLine;
    row.exe_path = row.ExecutablePath||undefined;
  }
  return ret;
});

// XXX: wmic must have 1 instance only
export let drive_list = E.drive_list = ()=>eserf(function* proc_drive_list(){
  if (!E.is_win)
    return ['/'];
  let cwd = E.cwd();
  let cmd = ['wmic', 'logicaldisk', 'get', 'name', '/format:csv'];
  let p = yield E.espawn(cmd, {cwd, is_ignore: false, is_print: false,
    shell: false});
  if (p.code)
  {
    if (p.stderr.includes('Invalid query'))
      return {err: 'invalid_query'};
    if (p.stderr.includes('ERROR'))
      return {err: p.stderr};
    return {err: p.stderr, p};
  }
  if (p.stderr.includes('No Instance(s) Available'))
    return {err: 'not_found'};
  let ret = csv_s2j(p.stdout);
  return ret.map(dinfo=>dinfo.Name+'/');
});

if (E.is_main(import.meta.url, {import_meta_main: import.meta.main}))
{
  argparse.init(()=>{process.exit(1);});
  argparse.get_flag(['--is_verbose', '-v']);
  argparse.get_v_str(['--cmd', '-c']);
  argparse.get_v_str(['--file', '-f']);
  argparse.get_flag('--filter');
  argparse.get_v('--ppid');
  argparse.get_v('--pid');
  argparse.get_flag('--filter_tcoder');
  let argo = argparse.argo;
  eserf(function* _fs_main(){
    if (argo.cmd=='ps')
    {
      let res = yield E.ps({ppid: argo.ppid, pid: argo.pid});
      if (argo.filter)
      {
        res = res.filter(pinfo=>pinfo.CreationClassName!='Win32_Process'
	  &&pinfo.Caption!='chrome.exe'&&pinfo.Caption!='msedgewebview2.exe'
	  &&pinfo.Caption!='Creative Cloud UI Helper.exe');
      }
      if (argo.filter_tcoder)
      {
        res = res.filter(pinfo=>pinfo.CommandLine.includes('tcoder')
	  ||pinfo.CommandLine.includes('cmd.exe'));
      }
      console.log(res);
    }
    else if (argo.cmd=='espawn')
    {
      this.alarm(3000, ()=>this.continue());
      let res = yield E.espawn(['timeout.exe', '/t', '20', '/nobreak'],
        {is_print: true, shell: true});
      yield eserf.sleep(10000);
      console.log(res);
    }
    else if (argo.cmd=='no_shell_win')
    {
      let res = yield E.espawn(['wmic', 'process', 'get', '/format:csv'],
        {is_print: false, shell: false});
      console.log(res);
    }
    else if (argo.cmd=='drive_list')
    {
      let res = yield E.drive_list();
      console.log(res);
    }
    else
      assert(0, 'missing cmd');
  });
}

